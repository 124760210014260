import { post } from "./service";
import { API_SERVER } from "../Utils";

export const getCommonData = (payload, onDone) => {
    post(`${API_SERVER}api/AutoComplete/GetAutoComplete`, payload, (res) => {
        onDone(res);
    });
};

export const getSearchingColumn = (payload, onDone) => {
    post(`${API_SERVER}api/AutoComplete/GetSearchingColumn`, payload, (res) => {
        onDone(res);
    });
};

export const getMasterData = (payload, onDone) => {
    post(`${API_SERVER}api/AutoComplete/GetMasterData`, payload, (res) => {
        onDone(res);
    });
};