import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import ExcelJS from 'exceljs';


//export const API_SERVER = 'http://localhost:5102/';
export const API_SERVER = 'https://app.nihaologistics.com/';
//export const API_SERVER = window.location.protocol + '//' + window.location.host;


export const initializeFilterData = {
    "SearchText": "",
    "StartValue": "2",
    "SearchFieldValue": "",
    "PageSize": "10",
    "AdvanceFilter": []
}

export const AutoCompleteList = (Type, Token, preFix, ContextKey, ContextKey2, cb) => {
    fetch(`${API_SERVER}api/AutoComplete/GetAutoComplete`, {
        method: 'POST',
        body: JSON.stringify({ ...Token, Type, preFix, ContextKey, ContextKey2 }),
        headers: {
            "Content-Type": "application/json"
        }
    }).then((res) => res.json())
        .then((res) => {
            cb({ res })
        })
        .catch((err) => (err))
}

export const handleDownloadExcel = async (handleApiUrl, requestData, FileName, enableAlternateColor = false, enableHeaderColor = true, enableFilters = false) => {
    const apiUrl = handleApiUrl;
    const requestBody = requestData;
    const requestHeaders = {
        'Content-Type': 'application/json',
    };
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: requestHeaders,
        });
        const res = await response.json();
        if (res.Status === "SUCCESS") {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sheet 1', {
                views: [{ state: 'frozen', ySplit: 1 }],
            });

            // Example: Set header row to bold and background color
            const headerRow = worksheet.addRow(res?.HeadersValue?.length > 0
                ? res.HeadersValue.filter((key) => {
                    return res?.HideColumns?.length > 0 ? !res?.HideColumns?.includes(key) : key
                })
                : Array.from(
                    new Set(
                        res?.Data?.flatMap((data) => Object.keys(data))
                            .filter((columnName) => !res?.HideColumns?.includes(columnName))
                    )
                ))


            const headerKey = (res?.HeadersValue?.length > 0 && res?.HeadersKey?.length > 0)
                ? res.HeadersKey.filter((key) => {
                    return res?.HideColumns?.length > 0 ? !res?.HideColumns?.includes(key) : key
                })
                : Array.from(
                    new Set(
                        res.Data?.flatMap((data) => Object.keys(data))
                            .filter((columnName) => !res?.HideColumns?.includes(columnName))
                    )
                );


            headerRow.font = { bold: true };
            if (enableHeaderColor) {
                headerRow.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'AAAAAA' },
                };
            }

            // Example: Enable filters
            if (enableFilters) {
                worksheet.autoFilter = {
                    from: { row: 1, column: 1 },
                    to: { row: 1, column: headerKey.length },
                };
            }

            // Example: Set alternate row background color
            for (let i = 2; i <= res.Data.length + 1; i++) {
                const row = worksheet.getRow(i);

                if (enableAlternateColor && i % 2 === 0) {
                    row.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'DDDDDD' },
                    };
                }

                row.values = res.HeadersKey?.length > 0 ?
                    res.HeadersKey?.map((key) => res.Data[i - 2][key])
                    : headerKey?.map((key) => res.Data[i - 2][key]);
            }



            // Set column widths to fit content
            worksheet.columns.forEach((column) => {
                let maxLength = 0;
                column.eachCell({ includeEmpty: true }, (cell) => {
                    const length = cell.value ? String(cell.value).length : 0;
                    maxLength = Math.max(maxLength, length);
                });
                column.width = maxLength < 12 ? 12 : maxLength; // Set a minimum width to avoid extremely narrow columns
            });



            const buffer = await workbook.xlsx.writeBuffer();
            const data = new Blob([buffer], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${FileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } else if (res.Status === 'ERROR') {
            toast.error(res.Message)
        }
        else if (res.Status === 'UNAUTHORIZED') {
            toast.error(res.Message)
        }
    } catch (err) {
        toast.error(err.message, {
            position: 'top-center',
        });
    }
};

//PDF Download function
export const downloadPDF = (pdf, name) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = name;
    downloadLink.click();
}

//Image Download Function
export const downloadImage = (image, name) => {
    const linkSource = `data:image/png;base64,${image}`;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = name;
    downloadLink.click();
}

/* <span style={{ fontSize: "20px", cursor: 'pointer' }} className="text-warning ml-5"
                                                    onClick={() => downloadPDF(recipe.UploadInvoice, recipe.ImageName)}><i className="fa fa-download text-default"></i> </span>*/