import React from "react"
import { useState } from "react";
import { useRef } from "react";
import { Link } from 'react-router-dom';
import nihaologo from '../../assests/images/nihaologo.png'
import user_image from '../../assests/images/User.jpg'

import { useAuth } from "../../context/auth";
import { InputBox } from "../commoncomponents/InputBox";
import { useNavigate } from 'react-router-dom';

const Header = ({ Handletoggle, HandleheaderToogle }) => {
    const inputrefs = useRef([]);
    const { user, logout } = useAuth()
    const navigate = useNavigate();
    const [recipe, setRecipe] = useState({
     AwbNo:'',
        OrderNo: "",
       
    })

    const GetAWBQuery = () => {
        navigate(`/AwbQuery?OrderNo=${recipe.OrderNo}`);       
    }
   
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }
  
    return (
        <>
            <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-light main-header p-0 d-flex align-items-center justify-content-between">
                <div className="container-fluid align-items-center justify-content-between" style={{ flexWrap: "nowrap" }}>

                    <Link to='/Dashboard'>
                        <img src={nihaologo} className="header-logo" alt='Nihao logo'  style={{ padding:'4px' }} />
                    </Link>

                    <i onClick={() => {
                        HandleheaderToogle()
                        Handletoggle()
                    } } className="fa-solid fa-bars mx-3"></i>

                    <div className='col-md-1 col-lg-1' style={{ marginLeft: "auto" }}>
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    GetAWBQuery()
                                }
                            }}

                            id='txtOrderNo'
                            maxLength='50'
                            autoComplete='off'
                            ignore_common={true}
                            placeholder="Awb No"
                            onBlur={() => {

                                setRecipe({ ...recipe, OrderNo: '' })
                            }}
                            value={recipe.OrderNo}
                            onChange={(e) => {
                                HandleChange('OrderNo', e.target.value)

                            }}
                           
                        />
                    </div>
                    <ul className="navbar-nav ms-auto d-flex flex-row nav-side-icon align-items-center">


                        {/*<li key='abc' className='nav-item'>
                            <span className="nav-link mt-1 me-2 m-lg-1" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa-solid fa-bell position-relative">
                                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill btn-danger" style={{ fontSize: '10px' }}>
                                        5
                                    </span>
                                </i>
                            </span>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                    <div className="dropdown-item">Item 1</div>
                                </li>
                                <li>
                                    <div className="dropdown-item">Item 2</div>
                                </li>

                            </ul>
                        </li>*/}

                        <li key='an' className="nav-item dropdown">
                            <div className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={user_image} alt='user' style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                            </div>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li className='px-3 pb-2 border-bottom'>
                                    <p className='m-0' style={{ fontSize: '13px' }}><span className='green_dot'></span>{user?user.userName:'lorem data'}</p>
                                </li>
                                <li key='am'>
                                    <Link className="dropdown-item" style={{ fontSize: '13px' }} to='ChangePassword'>Change Password</Link>
                                </li>
                                {/* <li className='mx-3'>
                                    <select className="branch-select form-select w-100" id="selectOption" >
                                        <option>Item 1</option>
                                        <option>Item 2</option>
                                        <option>Item 3</option>
                                        <option>Item 4</option>
                                    </select>
                                </li>*/}
                                <li key='al'>
                                    <button className="dropdown-item" style={{ fontSize: '13px' }} onClick={logout}>Logout</button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    )
}
export default Header;