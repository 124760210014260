import { Cookies } from 'react-cookie';
import { API_SERVER } from "../../Utils";
import { useState } from 'react';
import { useEffect } from 'react';

const cookies = new Cookies();

export function convertToNumber(value) {
    return value.replace(/[^0-9]/g, '');
}
export function convertTwoDecimalsOnValue(value) {
    const regex = /([0-9]*[\.]{0,1}[0-9]{0,2})/s;
    return value.match(regex)[0];
}
export function convertThreeDecimalsOnValue(value) {
    const regex = /([0-9]*[\.]{0,1}[0-9]{0,3})/s;
    return value.match(regex)[0];
}
export function convertThreeDecimalsWithoutZero(value) {
    const regex = /^(?!0\d*\.?)\d*(?:[.,]\d{0,3})?/;
    const match = value.match(regex);
    return match ? match[0] : "";
}


export const DropDownData = (Type, ContextKey = '', ContextKey2 = '') => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const user = cookies.get('user')
    //token Data
    const Data = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }
    useEffect(() => {
        const abortController = new AbortController();
        fetch(`${API_SERVER}api/AutoComplete/GetMasterData`, {
            method: 'POST',
            body: JSON.stringify({ ...Data, Type, ContextKey, ContextKey2 }),
            headers: {
                "Content-Type": "application/json"
            },
            signal: abortController.signal
        }).then((res) => res.json())
            .then((res) => {
                setData(res)
                setLoading(false)
            })
            .catch((err) => {
                setError(err)
                setLoading(false)
            })
            .finally(() => {
                abortController.abort();
            });
    }, [Type, ContextKey, ContextKey2])
    return { data, error, loading };
};


export const FetchAutoCompleteData = async (Data, preFix, type, contextKey = '', contextKey2 = '', contextKey3 = '', contextKey4 = '', contextKey5 = '') => {
    let { AuthToken, LoginToken, UserCode, CompanyCode, ...rest } = Data;
    const user = cookies.get('user')
    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }
    let _Data = {
        ...rest,
        ...TokenData
    }
    let dataList = [];
    try {
        if (preFix !== undefined && preFix !== '') {
            const abortController = new AbortController();
            const response = await fetch(`${API_SERVER}api/AutoComplete/GetAutoComplete`, {
                method: 'POST',
                body: JSON.stringify({ ..._Data, Type: type, preFix, ContextKey: contextKey, ContextKey2: contextKey2, ContextKey3: contextKey3, ContextKey4: contextKey4, ContextKey5: contextKey5 }),
                headers: {
                    "Content-Type": "application/json"
                },
                signal: abortController.signal
            });

            const res = await response.json();

            let _datalist = res.dataList;
            for (let i = 0; i < _datalist?.length; i++) {
                let _value = (_datalist[i]?.values);
                dataList?.push(_value);
            }
            return dataList;
        }
        else {
            return [];
        }
    } catch (error) {
        console.error('Error fetching suggestions:', error);
        return [];
    }
};