import { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '../../context/auth';

//loder
import BlurLoader from "../Loder/BlurLoader";

//services
import { post } from '../../apiservices/service';
import { API_SERVER } from '../../Utils';

const SideNav = ({ open, isopenheader, handleOpen, handleClose }) => {
    const { user } = useAuth();
    let location = useLocation();
    const navigate = useNavigate();

    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }

    //initial States
    const [serchingText, setSearchingText] = useState('')
    const [loading, setLoading] = useState(false)
    const [serchingData, setSeachingData] = useState([]);
    const [menu, setMenu] = useState([]);

    useEffect(() => {
        //-----------------------------------------get Menu All Data---------------------------------------------------//
        const getData = async (user) => {
            setLoading(true)
            await post(`${API_SERVER}api/UserLogin/GetMenu`, { ...TokenData, SearchText: '' }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        setLoading(false)
                    } else {
                        let data = res.getMenu.map(item => {
                            return {
                                ...item,
                                isSubmenu: item.getSubMenu.length > 0 ? true : false
                            }
                        })
                        setMenu(data)
                        setLoading(false)
                    }
                }
            });
        };
        getData()
    }, []);

    //--------------------------------------------get Searching data from All Menu------------------------------------------//
    const handleSearch = async (searchValue) => {
        await post(`${API_SERVER}api/UserLogin/GetMenuTextSearch`, { ...TokenData, SearchText: searchValue }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setSeachingData([])
                } else {
                    let data = res.getSearchText
                    setSeachingData(data)
                }
            }
        });
    }

    useEffect(() => {
        if (!open && isopenheader) {
            function handleresize() {
                if (window.innerWidth > 1200) {
                    handleClose()
                } else {
                    handleOpen()
                }
            };
            handleresize();
            window.addEventListener("resize", handleresize);
            return () => window.addEventListener("resize", handleresize);
        }

    }, []);


    return (
        <>

            <aside className={`sidebar ${open ? 'close' : ''}`} >

                <div className="search-bar">
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <input type="search" className="form-control" spellCheck="false"
                        value={serchingText}
                        maxLength='50'
                        id='search'
                        onChange={(e) => {
                            handleSearch(e.target.value)
                            setSearchingText(e.target.value)
                        }}
                        autoComplete='off'
                        placeholder="Search" />
                </div>

                {serchingData !== null && serchingData?.length > 0 ?
                    <ul className='sub-menu-search' id="sidebar-nav">
                        {serchingData.map((item, index) =>
                            <li key={`megaMenu${index}`}>
                                <NavLink
                                    className='sub-menu-link'
                                    to={`/${item.pageUrl}`}
                                    onClick={() => {
                                        setSearchingText('')
                                        setSeachingData([])
                                    }}
                                >{item.menuText}</NavLink>
                            </li>
                        )}
                    </ul> : <></>
                }
                <ul className="nav-links" id="sidebar-nav" style={{ marginTop: serchingData?.length > 0 ? '5px' : '60px' }}>
                    {menu?.map((item, index) => ( 
                        item.getSubMenu.length !== 0 ? (
                            <li className='menu_item' key={`menu${index}`}  >
                                <div className={`nav-link collapsed`}
                                    onClick={() => {
                                        navigate(!item.isSubmenu && `/${item.getSubMenu[0].pageUrl}`);
                                    }}
                                    data-bs-target={`#${(item.menuText).replaceAll(' ', '')}`} data-bs-toggle="collapse">
                                    <div className={`iocn-link ${item.getSubMenu.filter(d => d.pageUrl === location.pathname.split('/')[1]).length > 0 ? item.getSubMenu.filter(d => d.pageUrl === location.pathname.split('/')[1])[0].pageUrl && 'active-folder' : ''}`}>
                                        <div >
                                            {!item.tabMenu ? <i className="fa-solid fa-chevron-right" /> : <i className="fa-solid fa-chevron-right" />}
                                            <i className={item.cssClass} />
                                            <span className="link_name">{item.menuText}</span>
                                        </div>
                                    </div>
                                </div>
                                <ul className={`sub-menu accordion-collapse collapse ${item.getSubMenu.filter(d => d.pageUrl === location.pathname.split('/')[1]).length > 0 ?item.getSubMenu.filter(d => d.pageUrl === location.pathname.split('/')[1])[0].pageUrl && 'show' : ''}`
                                }
                                    id={`${(item.menuText).replaceAll(' ', '')}`} data-bs-parent="#sidebar-nav">
                                    {
                                        item.tabMenu ? <></> :
                                            item.getSubMenu.map((subItem, index) => (
                                                    <li key={`megaMenu${index}`} >
                                                        <NavLink className='sub-menu-link' to={`/${subItem.pageUrl}`}>{subItem.menuText}</NavLink>
                                                    </li>  
                                            ))
                                    }
                                </ul>

                            </li>
                        ) : (
                            <li key={`Menu${index}`}>
                                <div className='nav-link hj' data-bs-target={`#${(item.menuText).replaceAll(' ', '')}`} data-bs-toggle="collapse" aria-expanded="false">
                                    <div className='iocn-link'>
                                        <NavLink to={`/${item.pageUrl}`}>
                                            <i className="fa-solid fa-folder"></i>
                                            <span className="link_name">{item.menuText}</span>
                                        </NavLink>

                                    </div>
                                </div>
                            </li>
                        )
                    ))}
                </ul>
            </aside>

            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>
    )
}
export default SideNav;