
import { useEffect, useRef, useState } from "react";
import Pagination from '@mui/material/Pagination';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getSearchingColumn } from "../../apiservices/CommonServices";
import { FaFilePdf } from "react-icons/fa6";
import { KycDocTable } from "../Report/KycDocTable";

//Services
import { post } from "../../apiservices/service";
import { API_SERVER } from "../../Utils";


//Blur Loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

export const Table = ({ Token, PageName, tableData, handlePrint, currentPageNo, handleChange1, handleSorting, sortConfig, filterData, setFilterData, handleFilter, Filter = true, AdvancedFilter = true, handleEdit, handleDelete }) => {

    const inputrefs = useRef([]);
    const AdvanceSearchModelcloseButtonRef = useRef(null);
    const AdvanceSearchModelInputRef = useRef([]);
    const [ModalData, setModalData] = useState({});
    const [MainData, setMainData] = useState(tableData?.Data ? tableData?.Data : []);
    const [AdvanceModelCleared, setAdvanceModelCleared] = useState(true);
    const [serchingData, setSerchingData] = useState([]);
    const [advanceSearchingData, setAdvanceSearchingData] = useState([]);
    const [loading, setLoading] = useState(false)

    const ShowEyeRecords = (data, key) => {
        post(`${API_SERVER}api/Report/GetKycDocuments`, { ...Token, AWBNo: data.AWBNo }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setModalData({ ...res })
                } else {
                    setModalData({ ...res })
                }
            }
        });

        //   setModalData(data)
        // setModalTitleKey(key)
        // setModalHaderKey(Object?.keys(data[0]))
    };

    const finalHeaderKeys = tableData?.HeadersValue?.filter(
        (key, index) => {
            return !tableData?.HideColumns.find((column) => key === column)
        }
    );

    const finalHeadersValue = tableData?.HeadersKey?.filter(
        (key) => {
            return !tableData?.HideColumns.find((column) => key === column)
        }
    );

    const [ColoredRow, setColoredRow] = useState('');
    const handleHighlight = (id) => {
        setColoredRow(id)
    };

    const handleResetAdvanceFilter = () => {
        let updatedDataList = advanceSearchingData?.map((item, index) => ({
            ...item,
            Value: ''
        }));
        setAdvanceSearchingData(updatedDataList);
        setFilterData({ ...filterData, AdvanceFilter: [] });
        let iscleared = AdvanceModelCleared ? false : true;
        setAdvanceModelCleared(iscleared)

    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            if (advanceSearchingData?.length > index + 1) {
                AdvanceSearchModelInputRef.current[index + 1].focus();
            } else {
                AdvanceSearchModelInputRef.current['btnAdvSubmit'].focus();
            }
        }
    };

    const serching = async () => {
        if (Filter) {
            getSearchingColumn({ ...Token, PageName }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        setSerchingData([])
                    } else {
                        let data = res
                        setSerchingData(data.dataList)
                        //setAdvanceSearchingData(data.advanceDataList)
                        setFilterData({ ...filterData, SearchFieldValue: data.dataList[0].valuePart })
                    }
                }
            });
        }
    };

    const SendEmail = (item) => {
        setLoading(true)
        post(`${API_SERVER}api/Query/SendMail`, { ...Token, OrderNo: item.OrderNo }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "SUCCESS") {
                    toast.success(res.message)
                    setLoading(false)
                }
                else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message)
                    setLoading(false)
                }
            }
        });
    }
    const handleShowFile = () => {

    }

    const countNo = Math.ceil((parseInt(tableData?.TotalRecord) / parseInt(tableData?.PageSize)));

    useEffect(() => {
        serching();
    }, []);

    useEffect(() => {
        setMainData(tableData?.Data)
    }, [tableData]);

    useEffect(() => {
        if (PageName !== 'ReportQuery') handleFilter();
    }, [AdvanceModelCleared]);


    return (

        <>  {
            tableData ? (
                <div className='main-card card border-0' >
                    <div className='card-header mb-1'>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-md-3 '>
                                <h6 className='m-0' style={{ fontSize: "15px", width: "130px" }}>{`${tableData?.TotalRecord !== undefined ? 'Total Records : ' + tableData?.TotalRecord : 'No Record Found'}`}</h6>
                            </div>
                            {Filter && PageName !== 'ReportQuery' && PageName !== 'KycDetails' && PageName !== 'Dashboard' && PageName !== 'AWBPhoneKyc' ?
                                <div className='col-md-9 col-lg-6 d-flex flex-row filters'>
                                    <select
                                        style={{ width: "auto" }}
                                        value={filterData?.SearchFieldValue}
                                        onChange={(e) => setFilterData({ ...filterData, SearchFieldValue: e.target.value })}
                                        className="form-select me-2"
                                        aria-label="Default select example">
                                        {Array.isArray(serchingData) ? (
                                            serchingData?.map((list, i) => (
                                                <option key={`filterList${i}`} value={list?.valuePart}> {list?.textPart}</option>
                                            ))
                                        ) : (<></>)}
                                    </select>
                                    <select className="form-select me-2" aria-label="Condition"


                                        value={filterData?.StartValue} onChange={(e) => setFilterData({ ...filterData, StartValue: e.target.value })}
                                    >
                                        <option value="1">Exactly</option>
                                        <option value="2">Contains</option>
                                        <option value="3">Start With</option>
                                    </select>
                                    <input type="text"
                                        className="form-control search"
                                        placeholder="Search"
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleFilter()
                                            };
                                        }}
                                        value={filterData.SearchText}
                                        onChange={(e) => setFilterData({ ...filterData, SearchText: e.target.value })}
                                    />
                                    <button onClick={handleFilter} className="btn search-btn me-2"><i className="fa-solid fa-magnifying-glass"></i></button>

                                    {
                                        advanceSearchingData?.length !== 0 && (<button className='btn advance-search-btn' data-bs-toggle="modal" data-bs-target="#AdvanceSearchModal" >
                                            <i className="fa-solid fa-filter"></i>
                                        </button>)
                                    }

                                    {
                                        filterData?.PageSize ?
                                            <select className="form-select me-2" aria-label="PageSize" style={{ width: "110px" }}
                                                value={filterData?.PageSize ? filterData?.PageSize : "10"} onChange={(e) => {
                                                    setFilterData({ ...filterData, PageSize: e.target.value })
                                                    let iscleared = AdvanceModelCleared ? false : true;
                                                    setAdvanceModelCleared(iscleared)
                                                }}
                                            >
                                                <option value="10">10</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="200">200</option>
                                                <option value="500">500</option>
                                            </select> : <></>
                                    }
                                </div>
                                : <></>}
                        </div>
                    </div>

                    <div className='card-body'>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr className='light-bg'>
                                        {tableData?.Actions && PageName !== 'Dashboard' && PageName !== 'UserLogReport' ? (
                                            <th style={{ width: '10px' }} key="thActions">Action</th>
                                        ) : (
                                            <></>
                                        )}
                                        {finalHeaderKeys?.map((th, headIndex) => (
                                            <th key={`th${headIndex || 0}`} style={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                    handleSorting ?
                                                        handleSorting(finalHeadersValue[headIndex]) : ''}
                                            >
                                                {th}
                                                {
                                                    (sortConfig?.SortColumn !== null
                                                        && sortConfig?.SortOrder !== null
                                                        && sortConfig?.SortColumn === finalHeadersValue[headIndex]) ?
                                                        (
                                                            PageName !== 'Dashboard' && PageName !== 'AWBPhoneKyc' && PageName !== 'UserLogReport' && <>
                                                                <i className="fa fa-arrow-up ms-2" style={{ color: sortConfig?.SortOrder !== 'DESC' ? '#d4d4d4' : 'red' }}></i>
                                                                <i className="fa fa-arrow-down" style={{ color: sortConfig?.SortOrder !== 'ASC' ? '#d4d4d4' : 'red' }}  ></i>
                                                            </>
                                                        )
                                                        : (
                                                            PageName !== 'Dashboard' && PageName !== 'AWBPhoneKyc' && <>
                                                                <i className="fa fa-arrow-up ms-2" style={{ color: '#d4d4d4' }}></i>
                                                                <i className="fa fa-arrow-down" style={{ color: '#d4d4d4' }}  ></i>
                                                            </>
                                                        )
                                                }

                                            </th> || <></>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {MainData?.map((item, bodyIndex) => (
                                        <tr className="TableTrhover" key={`row${bodyIndex}`} onClick={() => handleHighlight(item.Edit)} style={{ background: item.Edit === ColoredRow && PageName !== 'ReportQuery' && PageName !== 'KycDetails' && PageName !== 'Dashboard' && PageName !== 'AWBPhoneKyc' ? '#fee77f' : '' }} >
                                            {tableData?.Actions && PageName !== 'Dashboard' && PageName !== 'UserLogReport' ? (
                                                <td key={`tdAction${bodyIndex}`}>
                                                    {tableData.Actions?.map((btn, index) => (
                                                        <span key={index}>
                                                            {btn === 'SendMail' ? <i className="fa-regular fa-envelope text-success me-2" key={`row${bodyIndex}edit${index}`} onClick={() => SendEmail(item)} /> : (<></>)}
                                                            {btn === 'Edit' ? <i className="fa-regular fa-pen-to-square text-success me-2" key={`row${bodyIndex}edit${index}`} onClick={() => handleEdit(item.Edit)} /> : (<></>)}
                                                            {btn === 'Del' ? <i className="fa-regular fa-trash-can text-danger me-2" key={`row${bodyIndex}del${index}`} onClick={() => handleDelete(item.Del)} /> : (<></>)}
                                                            {btn === 'View' ? <span className='text-center'><i className="fa fa-eye text-info me-2" style={{ cursor: 'pointer', color: '#5a8dee' }} data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                key={`row${bodyIndex}del${index}`} onClick={() => ShowEyeRecords(item, bodyIndex)} /></span> : (<></>)}
                                                            {btn === 'Print' ? <i className="fa fa-print text-primary" onClick={() => handlePrint(item.Print, bodyIndex)} key={`row${bodyIndex}print${index}`} /> : (<></>)}
                                                        </span>
                                                    ))}
                                                </td>
                                            ) : (
                                                <></>
                                            )}
                                            {finalHeadersValue?.map((key, tdIndex) => (
                                                (typeof (item[key]) === 'object') ? (
                                                    Array.isArray(item[key]) ?
                                                        (item[key].length > 0 ?
                                                            <td className='text-center' key={`key${tdIndex}`}>
                                                                <i className="fa fa-eye"
                                                                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                    style={{ cursor: 'pointer', color: '#5a8dee' }}
                                                                    onClick={() => ShowEyeRecords(item[key], key)}
                                                                />
                                                            </td> : <td key={`key${tdIndex}`}></td>) : (
                                                            typeof (item[key]) === 'object' && item[key]?.FileType === 'pdf' ?
                                                                <td key={`key${tdIndex}`} className='text-center' onClick={() => { handleShowFile(item[key]) }}>
                                                                    <FaFilePdf style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '16px' }} />
                                                                </td> : typeof (item[key]) === 'object' && item[key]?.FileType === '' ? (<td key={`key${tdIndex}`}></td>) :
                                                                    (<td key={`key${tdIndex}`} className='text-center' onClick={() => { handleShowFile(item[key]) }}>
                                                                        <i className="fa fa-image"

                                                                            style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }}

                                                                        />
                                                                    </td>))
                                                )
                                                    :
                                                    <td key={`key${tdIndex}`}>{item[key]}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {countNo > 1 && PageName !== 'Dashboard' ?
                            < Pagination className='pt-2' count={countNo} variant="outlined" page={currentPageNo} onChange={handleChange1} shape="rounded" />
                            : ''}

                    </div>

                    {AdvancedFilter ?
                        <>
                            <div className="modal fade" id="AdvanceSearchModal" tabIndex="-1" role="dialog" aria-labelledby="AdvanceSearchModalLabel" aria-hidden="true">
                                <div className='modal-dialog modal-lg modal-dialog-centered' style={{ width: '500px' }}>
                                    <div className='modal-content main-card'>
                                        <div className="modal-header">
                                            <h5 className="modal-title">Advance Search</h5>
                                            <button type="button" ref={AdvanceSearchModelcloseButtonRef} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className='row'>

                                                {Array.isArray(advanceSearchingData) ? (
                                                    advanceSearchingData?.map((list, index) => (

                                                        <div key={`advancedFilterList${index}`} className='col-md-12 mb-2 d-flex justify-content-between align-items-center'>

                                                            <label className="col-md-4" style={{ textAlign: 'right' }} value={list?.valuePart}>{list?.textPart}</label>
                                                            <input type="text"
                                                                className="ps-1"
                                                                style={{ width: "20px", height: "20px" }}
                                                                placeholder={list?.operator}
                                                                value={list?.operator}
                                                                disabled />

                                                            <div className='col-md-7'>
                                                                {list.dataType === 'string' ?
                                                                    <input type="text"
                                                                        className="form-control"
                                                                        ref={ref => (AdvanceSearchModelInputRef.current[index] = ref)}
                                                                        onKeyDown={(e) => handleKeyDown(index, e)}
                                                                        placeholder={list?.textPart}
                                                                        value={list?.Value ? list?.Value : ''}
                                                                        onChange={(e) => {

                                                                            const value = e.target.value;
                                                                            const columnName = list?.valuePart;
                                                                            const operator = list?.operator;

                                                                            advanceSearchingData[index] = { ...advanceSearchingData[index], Value: value };
                                                                            setAdvanceSearchingData(advanceSearchingData)

                                                                            const existingIndex = filterData?.AdvanceFilter?.findIndex(item => item.ColumnName === columnName && item.Operator === operator);

                                                                            if (value.trim() === '') {
                                                                                if (existingIndex !== -1) {
                                                                                    const updatedAdvList = [...filterData.AdvanceFilter];
                                                                                    updatedAdvList.splice(existingIndex, 1);
                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedAdvList });
                                                                                }
                                                                            } else {
                                                                                if (existingIndex !== -1) {
                                                                                    const updatedAdvList = [...filterData.AdvanceFilter];
                                                                                    updatedAdvList[existingIndex] = { ...updatedAdvList[existingIndex], ColumnValue: value };
                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedAdvList });
                                                                                } else {
                                                                                    const newAdvList = [...filterData.AdvanceFilter, { ColumnName: columnName, Operator: operator, ColumnValue: value }];
                                                                                    setFilterData({ ...filterData, AdvanceFilter: newAdvList });
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                    : <></>}
                                                                {list.dataType === 'bool' ?
                                                                    <input
                                                                        className=""
                                                                        type="checkbox"
                                                                        checked={list?.Value ? list?.Value : ''}
                                                                        onChange={(e) => {
                                                                            const value = e.target.checked;
                                                                            const columnName = list?.valuePart;
                                                                            const operator = list?.operator;
                                                                            advanceSearchingData[index] = { ...advanceSearchingData[index], Value: value };
                                                                            setAdvanceSearchingData(advanceSearchingData)

                                                                            const existingIndex = filterData?.AdvanceFilter?.findIndex(item => item.ColumnName === columnName && item.Operator === operator);

                                                                            if (value === false) {
                                                                                if (existingIndex !== -1) {
                                                                                    const updatedAdvList = [...filterData.AdvanceFilter];
                                                                                    updatedAdvList.splice(existingIndex, 1);
                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedAdvList });
                                                                                }
                                                                            } else {
                                                                                if (existingIndex !== -1) {
                                                                                    const updatedAdvList = [...filterData.AdvanceFilter];
                                                                                    updatedAdvList[existingIndex] = { ...updatedAdvList[existingIndex], ColumnValue: value };
                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedAdvList });
                                                                                } else {
                                                                                    const newAdvList = [...filterData.AdvanceFilter, { ColumnName: columnName, Operator: operator, ColumnValue: value }];
                                                                                    setFilterData({ ...filterData, AdvanceFilter: newAdvList });
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                    : <></>}
                                                                {list.dataType === 'datetime' ?
                                                                    <DatePicker
                                                                        //  selected={new Date()}
                                                                        selected={list.Value ? new Date(list.Value) : ''}
                                                                        className='form-control input-fields'
                                                                        dateFormat="dd-MMM-yyyy"
                                                                        placeholderText={list.textPart}
                                                                        inputrefs={inputrefs}
                                                                        onChange={(e) => {
                                                                            let dateArr = e?.toString()?.split(' ')
                                                                            dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`

                                                                            const columnName = list?.valuePart;

                                                                            const operator = list?.operator;

                                                                            advanceSearchingData[index] = { ...advanceSearchingData[index], Value: dateArr };
                                                                            /* console.log(advanceSearchingData[index],"jgs")*/
                                                                            setAdvanceSearchingData(advanceSearchingData)

                                                                            const existingIndex = filterData?.AdvanceFilter?.findIndex(item => item.ColumnName === columnName && item.Operator === operator);

                                                                            if (dateArr === '') {
                                                                                if (existingIndex !== -1) {
                                                                                    const updatedAdvList = [...filterData.AdvanceFilter];
                                                                                    updatedAdvList.splice(existingIndex, 1);
                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedAdvList });
                                                                                }
                                                                            } else {
                                                                                if (existingIndex !== -1) {
                                                                                    const updatedAdvList = [...filterData.AdvanceFilter];
                                                                                    updatedAdvList[existingIndex] = { ...updatedAdvList[existingIndex], ColumnValue: dateArr };
                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedAdvList });
                                                                                } else {
                                                                                    const newAdvList = [...filterData.AdvanceFilter, { ColumnName: columnName, Operator: operator, ColumnValue: dateArr }];
                                                                                    setFilterData({ ...filterData, AdvanceFilter: newAdvList });
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                    : <></>}
                                                            </div>
                                                        </div>
                                                    ))

                                                ) : (<></>)}
                                            </div>
                                        </div>
                                        <div className="modal-footer p-0">
                                            <button type="button"
                                                ref={ref => (AdvanceSearchModelInputRef.current['btnAdvSubmit'] = ref)}
                                                className="btn btn-rounded btn-outline-success" onClick={(e) => {
                                                    if (AdvanceSearchModelcloseButtonRef.current) {
                                                        AdvanceSearchModelcloseButtonRef.current.click();
                                                    }
                                                    handleFilter()
                                                }}><span className=" text-success me-2"><i className="fa-solid fa-hand-pointer"></i>
                                                </span>Submit</button>
                                            <button type="button" className="btn btn-rounded btn-outline-danger" onClick={() => handleResetAdvanceFilter}><span className=" text-danger me-2"><i className="fa-solid fa-refresh"></i>
                                            </span>Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        : <></>}

                    <div className="modal fade" id="exampleModal"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabIndex="-1"
                        aria-labelledby="aboutUsLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog">
                            <div className="modal-content" style={{ background: "mintcream" }}>
                                <div className="modal-header" style={{ padding: '7px 15px' }}>
                                    <h5 className="modal-title" id="exampleModalLabel">KYC Details</h5>
                                    {/*<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
                                </div>
                                <div className="modal-body table-responsive" style={{ maxHeight: '210px', padding: '8px' }}>
                                    {/* Table Component start*/}
                                    <KycDocTable
                                        PageName='KycDOC'
                                        handleEdit={handleEdit}
                                        handleDelete={handleDelete}
                                        handleFilter={() => {

                                        }}
                                        tableData={ModalData}
                                        //  loading={loading}
                                        filterData={filterData}
                                        setFilterData={setFilterData}
                                        currentPageNo={currentPageNo}
                                        handleChange1={handleChange1}
                                        handleSorting={handleSorting}
                                        sortConfig={sortConfig}
                                    />
                                    {/* Table Component End*/}
                                </div>
                                <div className="modal-footer" style={{ padding: '3px' }}>
                                    <button type="button" className="btn btn-secondary" style={{ margin: '5px', minWidth: '30px' }} data-bs-dismiss="modal"><i className='fa fa-close' /> Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            ) : (<> </>)
        }
            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>
    )
}