import React, { useEffect, useState, useRef } from 'react'
import { InputBox, AutoCompleteBox, DateBox, SelectInputBox, FormButtonWithPrint } from "../commoncomponents/InputBox";

import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';

//services
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';

function ReportQuery() {

    const inputrefs = useRef([]);
    const { user } = useAuth();

    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }

    //intial states

    const [recipe, setRecipe] = useState({
        OrderNo: "",
        "AwbNo": "",
        "ForwardingNo": "",
        "BookingFromDate": "",
        "BookingToDate": "",
        "PacketStatus": "",
        "PaymentType":"",
        "PacketType": "",
        "Client": "",
        "PhoneNo": "",
        "Consignor": "",
        "Consignee": "",
        "ZipCode":"",
    })
  
    const [tableData, setTableData] = useState({})
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [ConsignorDataArr, setConsignorDataArr] = useState([])
    const [ConsigneeDataArr, setConsigneeDataArr] = useState([])
    const [PaymentTypeArr, setPaymentTypeArr] = useState([])
    const [PacketTypeArr, setPacketTypeArr] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const [PacketAllData, setPacketAllData] = useState([])
    const [packetLoading, setPacketLoading] = useState(false)
    const [paymentLoading, setPaymentLoading] = useState(false)
    const [packetTypeLoading, setPacketTypeLoading] = useState(false)
    const [ClientDataArr, setClientDataArr] = useState([])
    const [IsUpdate, setIsUpdate] = useState(false)
    //----------------Consignor api--------------------------

    const getConsignorData = () => {
        let temp = {
            Type: "GetConsignor",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setDataLoading(false)
                    setConsignorDataArr([])
                } else {
                    let data = res.dataList
                    setDataLoading(false)
                    setConsignorDataArr(data)
                }
            }
        });
    }

    let ConsignorData = []
    for (let i = 0; i < ConsignorDataArr.length; i++) {
        let mydata = ConsignorDataArr[i]?.values
        ConsignorData.push(mydata)
    };


    //---------------consignee api--------------------------
    const getConsigneeData = () => {
        let temp = {
            Type: "GetConsignee",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setConsigneeDataArr([])
                    setDataLoading(false)
                } else {
                    let data = res.dataList
                    setConsigneeDataArr(data)
                    setDataLoading(false)
                }
            }
        });
    }

    let ConsigneeData = []
    for (let i = 0; i < ConsigneeDataArr.length; i++) {
        let mydata = ConsigneeDataArr[i]?.values
        ConsigneeData.push(mydata)
    };


    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            PageIndex: PageIndex.toString(),
            Type: "s"
        }
        post(`${API_SERVER}api/Query/ReportQuery`, { ...TokenData, ...temp, ...sortConfig, ...filterData, ...recipe }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setTableData({ ...res })
                    toast.error(res.Message)
                } else {
                    setLoading(false)
                    setTableData({ ...res })
                    toast.success(res.Message)
                }
            }
        });
        setLoading(false)
    }

    useEffect(() => {
        if (IsUpdate) {
            setIsUpdate(false)
            getFormData()
        }
    }, [IsUpdate])

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,OrderNo:'', AwbNo: '', BookingFromDate: '', BookingToDate: '', ForwardingNo: "", PacketStatus: "", PaymentType: "", PacketType: "",
            Client: "", PhoneNo: "", Consignor: "", Consignee: "", ZipCode:""

        }))
        setIsUpdate(true)
    }

    //------------------------------get Payment Type Data-----------------------------//
    const getPaymentTypeData = () => {
        setPaymentTypeArr([])
        let temp = {
            Type: "PaymentType",
        }
        setPaymentLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setPaymentTypeArr([])
                    setPaymentLoading(false)
                } else {
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setPaymentTypeArr(data)
                    setPaymentLoading(false)
                }
            }
        });
    }

    //---------------------------get Packet Type Data----------------------------//
    const getPacketTypeData = () => {
        setPacketTypeArr([])
        let temp = {
            Type: "PacketType",
        }
        setPacketTypeLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setPacketTypeArr([])
                    setPacketTypeLoading(false)
                } else {
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setPacketTypeArr(data)
                    setPacketTypeLoading(false)
                }
            }
        });
    }

    //------------------------To Get Packet Status Data----------------------//
    const getPacketStatusData = () => {
        let temp = {
            Type: "GetPacketStatusMaster"
        }
        setPacketLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setPacketAllData([])
                    setPacketLoading(false)
                } else {
                    let data = res.dataList
                    setPacketAllData(data)
                    setPacketLoading(false)
                }
            }
        });
    }


    //------------------------To Get Client Data----------------------//
    const getClientData = () => {
        let temp = {
            Type: "GetClient",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setClientDataArr([])
                    setDataLoading(false)
                } else {
                    let data = res.dataList
                    setClientDataArr(data)
                    setDataLoading(false)
                }
            }
        });
    }

    let clientData = []
    for (let i = 0; i < ClientDataArr.length; i++) {
        let mydata = ClientDataArr[i]?.values
        clientData.push(mydata)
    };

    //Start Export functionality
    let ExportRequest = {
        ...recipe,
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }

    useEffect(() => {
        getPacketStatusData()
        getPaymentTypeData()
        getPacketTypeData()
        inputrefs.current["txtOrderNo"].focus()
        document.title = 'Nihao: ReportQuery'  
    }, [])

    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    const handleChange1 = (event, value) => {
        getFormData(value);
    };

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
        getFormData()
    };

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Report Query</h6>
                </div>

                <div className='card-body'>
                    <div className="row">

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtBookingFromDate'].input.focus() };
                            }}
                            label='Awb No'
                            id='txtOrderNo'
                            maxLength='25'
                            placeholder="Awb No "
                            value={recipe.OrderNo}
                            onChange={(e) => HandleChange('OrderNo', e.target.value)}
                        />

                        {/*<InputBox  */}

                        {/*    inputrefs={inputrefs}*/}
                        {/*    onKeyDown={(e) => {*/}
                        {/*        if (e.key === 'Enter') { inputrefs.current['txtBookingFromDate'].input.focus() };*/}
                        {/*    }}*/}
                        {/*    label='Awb No'*/}
                        {/*    id='txtAwbNo'*/}
                        {/*    maxLength='25'*/}
                        {/*    placeholder="Awb No "*/}
                        {/*    value={recipe.AwbNo}*/}
                        {/*    onChange={(e) => HandleChange('AwbNo', e.target.value)}*/}
                        {/*    />*/}

                        <DateBox
                            id='txtBookingFromDate'
                            inputrefs={inputrefs}
                            label='Booking Date'
                            placeholder="Booking From Date"
                            required={false}
                            selected={recipe.BookingFromDate === '' ? '' : new Date(recipe.BookingFromDate)}
                            onChange={(e) => {
                                let dateArr = e?.toString()?.split(' ')
                                dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                setRecipe({ ...recipe, BookingFromDate: dateArr })
                            }}
                            maxDate={new Date()}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtBookingToDate'].input.focus() };
                            }}
                        />

                        <DateBox
                            id='txtBookingToDate'
                            inputrefs={inputrefs}
                            label='Booking To Date'
                            placeholder="Booking To Date"
                            required={false}
                            selected={recipe.BookingToDate === '' ? '' : new Date(recipe.BookingToDate)}
                            onChange={(e) => {
                                let dateArr = e?.toString()?.split(' ')
                                dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                setRecipe({ ...recipe, BookingToDate: dateArr })
                            }}
                            maxDate={new Date()}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtForwardingNo'].focus() };
                            }}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtPacketStatus'].focus() };
                            }}
                            label='Forwarding No'
                            id='txtForwardingNo'
                            maxLength='20'
                            placeholder="Forwording No "
                            value={recipe.ForwardingNo}
                            onChange={(e) => HandleChange('ForwardingNo', e.target.value)}
                        />

                        <SelectInputBox
                            label=' Packet Status'
                            inputrefs={inputrefs}
                            id='txtPacketStatus'
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtPaymentType'].focus() };
                            }}
                          
                            maxLength='50'
                            value={recipe.PacketStatus}
                            onChange={(e) => setRecipe({ ...recipe, PacketStatus: e.target.value })}
                        >
                            <option value="">-Please Select-</option>
                            {packetLoading ? <option value=''>Loading...</option>
                                :
                                PacketAllData?.map((item, index) => {
                                    return (
                                        <option key={index} value={item.name}>{item.name}</option>
                                    )
                                })
                            }
                        </SelectInputBox>

                        <SelectInputBox
                            label=' Payment Type'
                            inputrefs={inputrefs}
                            id='txtPaymentType'
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtPacketType'].focus() };
                            }}
                           
                            maxLength='50'
                            value={recipe.PaymentType}
                            onChange={(e) => setRecipe({ ...recipe, PaymentType: e.target.value })}
                        >
                            <option value="">-Please Select-</option>
                            {paymentLoading ? <option value=''>Loading...</option>
                                :
                                PaymentTypeArr?.map((item, index) => {
                                    return (
                                        <option key={index} value={item.name}>{item.name}</option>
                                    )
                                })
                            }
                        </SelectInputBox>

                        <SelectInputBox
                            label=' Packet Type'
                            inputrefs={inputrefs}
                            id='txtPacketType'
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtClient'].children[0].children[0].focus() };
                            }}
                           
                            maxLength='50'
                            value={recipe.PacketType}
                            onChange={(e) => setRecipe({ ...recipe, PacketType: e.target.value })}
                        >
                            <option value="">-Please Select-</option>
                            {packetTypeLoading ? <option value=''>Loading...</option>
                                :
                                PacketTypeArr?.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    )
                                })
                            }
                        </SelectInputBox>

                        <AutoCompleteBox
                            loading={dataLoading}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtPhoneNo'].focus() };
                            }}
                            label='Client'
                            placeholder="Client"
                            maxLength='50'
                            options={clientData}
                            id='txtClient'
                            required={false}
                            onFocus={() => getClientData()}
                            inputValue={recipe.Client ? recipe.Client : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('Client', newInputValue)
                            }}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtConsignor'].children[0].children[0].focus() };
                            }}
                            label=' Consignee Phone No'
                            id='txtPhoneNo'
                            maxLength='20'
                            placeholder=" Consignee Phone No "
                            value={recipe.PhoneNo}
                            onChange={(e) => HandleChange('PhoneNo', e.target.value.replace(/\D/g, ""))}
                        />
                        
                        <AutoCompleteBox
                            loading={dataLoading}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtConsigneeName'].children[0].children[0].focus() };
                            }}
                            label='Consignor Name'
                            placeholder="Consignor Name"
                            maxLength='50'
                            options={ConsignorData}
                            id='txtConsignor'
                            required={false}
                            onFocus={() => getConsignorData()}
                            inputValue={recipe.Consignor ? recipe.Consignor : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('Consignor', newInputValue)
                               
                            }}
                        />

                        <AutoCompleteBox
                            loading={dataLoading}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtZipCode'].focus() };
                            }}
                            label='Consignee Name'
                            placeholder="Consignee Name"
                            maxLength='50'
                            options={ConsigneeData}
                            id='txtConsigneeName'
                            required={false}
                            onFocus={() => getConsigneeData()}
                            inputValue={recipe.Consignee ? recipe.Consignee : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('Consignee', newInputValue)

                            }}
                        />
                        
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    getFormData()
                                };
                            }}
                            label='Consignee ZipCode'
                            id='txtZipCode'
                            maxLength='6'
                            placeholder="Consignee ZipCode"
                            value={recipe.ZipCode}

                            onChange={(e) => HandleChange('ZipCode', e.target.value.replace(/\D/g, ""))}
                        />

                        <FormButtonWithPrint
                            save={true}
                            Export={true }
                            reset={true}
                            buttonName='Search'
                            handleSubmit={getFormData}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/Query/ReportQuery', ExportRequest, 'Report Query')}
                            handleClear={handleClear}
                        />

                    </div>

                </div>
                </div>
         
            {/* Table Component start*/}
            <Table
                Token={TokenData}
                PageName='ReportQuery'
                handleEdit={()=>alert('Edit')}
                handleDelete={()=>alert('delete')}
                tableData={tableData}
                handleFilter={() => {
                    getFormData();
                }}
                //  loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {/* Table Component End*/}
          

            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}

        </>
    )

}
export default ReportQuery
