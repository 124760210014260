
import { useEffect, useState } from "react";
import Pagination from '@mui/material/Pagination';
import 'react-datepicker/dist/react-datepicker.css';
import { FaFilePdf } from "react-icons/fa6";
import { downloadImage } from "../../Utils";

export const KycDocTable = ({ Token, PageName, tableData, currentPageNo, handleChange1, handleSorting, sortConfig, filterData, setFilterData, handleFilter, Filter = true, AdvancedFilter = true, handleEdit, handleDelete }) => {

    const [MainData, setMainData] = useState(tableData?.Data ? tableData?.Data : []);


 
    const finalHeaderKeys = tableData?.HeadersValue?.filter(
        (key, index) => {
            return !tableData?.HideColumns.find((column) => key === column)
        }
    );

    const finalHeadersValue = tableData?.HeadersKey?.filter(
        (key) => {
            return !tableData?.HideColumns.find((column) => key === column)
        }
    );

    const [ColoredRow, setColoredRow] = useState('');
    const handleHighlight = (id) => {
        setColoredRow(id)
    };

  
    useEffect(() => {
        setMainData(tableData?.Data)
    }, [tableData]);



    const countNo = Math.ceil((parseInt(tableData?.TotalRecord) / parseInt(tableData?.PageSize)));
 
    return (

        <>  {
            tableData ? (
                <div className='main-card card border-0' >
                    <div className='card-header mb-1'>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-md-4'>
                                <h6 className='m-0'>{`${tableData?.TotalRecord !== undefined ? 'Total Records : ' + tableData?.TotalRecord : 'No Record Found'}`}</h6>
                            </div>
                         
                        </div>
                    </div>

                    <div className='card-body'>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr className='light-bg'>
                                        {tableData?.Actions && PageName !== 'MovementEntry' && PageName !== 'ReportQuery' ? (
                                            <th style={{ width: '10px' }} key="thActions">Action</th>
                                        ) : (
                                            <></>
                                        )}
                                        {finalHeaderKeys?.map((th, headIndex) => (
                                            <th key={`th${headIndex || 0}`} style={{ cursor: 'pointer' }}
                                                
                                            >
                                                {th}
                                               

                                            </th> || <></>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {MainData?.map((item, bodyIndex) => (

                                        <tr className="TableTrhover" key={`row${bodyIndex}`} onClick={() => handleHighlight(item.View)} style={{ background: item.View === ColoredRow && PageName !=='KycDOC' ? '#fee77f' : '' }} >
                                            {tableData?.Actions && PageName !== 'MovementEntry' && PageName !== 'ReportQuery' ? (
                                                <td key={`tdAction${bodyIndex}`}>
                                                    {tableData.Actions?.map((btn, index) => {  
                                                        let FileExt= item.FileName.substring(item.FileName.lastIndexOf(".") + 1)
                                                        return <span key={index}>
                                                            {btn === 'View' ? <i className="fa fa-eye text-info me-2" key={`row${bodyIndex}edit${index}`} style={{ cursor: 'pointer', color: '#5a8dee' }} onClick={() => FileExt === 'pdf' ? window.open(`/PdfView/#data:application/pdf;base64,${item.View}`, '_blank') : window.open(`/ImageView/#data:image/png;base64,${item.View}`, '_blank')} /> : (<></>)}
                                                            {btn === 'Download' ? <i className="fa fa-download text-warning me-2" key={`row${bodyIndex}del${index}`} style={{ cursor: 'pointer' }} onClick={() => downloadImage(item.View, item.FileName)} /> : (<></>)}

                                                        </span>
                                                    })}
                                                </td>
                                            ) : (
                                                <></>
                                            )}
                                            {finalHeadersValue?.map((key, tdIndex) => (
                                                (typeof (item[key]) === 'object') ? (
                                                    Array.isArray(item[key]) ?
                                                        (item[key].length > 0 ?
                                                            <td className='text-center' key={`key${tdIndex}`}>
                                                                <i className="fa fa-eye"
                                                                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                    style={{ cursor: 'pointer', color: '#5a8dee' }}
                                                                />
                                                            </td> : <td key={`key${tdIndex}`}></td>) : (
                                                            typeof (item[key]) === 'object' && item[key]?.FileType === 'pdf' ?
                                                                <td key={`key${tdIndex}`} className='text-center'>
                                                                    <FaFilePdf style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '16px' }} />
                                                                </td> : typeof (item[key]) === 'object' && item[key]?.FileType === '' ? (<td key={`key${tdIndex}`}></td>) :
                                                                    (<td key={`key${tdIndex}`} className='text-center'>
                                                                        <i className="fa fa-image"

                                                                            style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }}

                                                                        />
                                                                    </td>))
                                                )
                                                    :
                                                    <td key={`key${tdIndex}`}>{item[key]}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {countNo > 1 ?
                            < Pagination className='pt-2' count={countNo} variant="outlined" page={currentPageNo} onChange={handleChange1} shape="rounded" />
                            : ''}
                    </div>


          
                </div >

            ) : (<> </>)
        }

        </>
    )
}