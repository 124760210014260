import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, AutoCompleteBox, Switch } from "../commoncomponents/InputBox";
import { confirmAlert } from 'react-confirm-alert'

import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';

//services
import { getCommonData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';




function UserMaster() {
    const inputrefs = useRef([]);
    const { user } = useAuth();

    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }

    //initial state
    const [recipe, setRecipe] = useState({ UCode: '', UserID: '0', UserName: '', UserGroup: '', MobileNo: '', Password: '', EmailAddress: '', Address: '', Active: true })
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [groupAllData, setGroupAllData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [dataLoading, setDataLoading] = useState(false)

    const getUserGroup = () => {
        let temp = {
            Type: "GetUserGroupName",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setDataLoading(false)
                    setGroupAllData([])
                } else {
                    setDataLoading(false)
                    let data = res.dataList
                    setGroupAllData(data)
                }
            }
        });
    }

    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/user/ShowUserMaster`, { ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    const handleChange1 = (event, value) => {
        getFormData(value);
    };

    useEffect(() => {
        inputrefs.current['txtUserName'].focus();
        document.title = 'Nihao: User Master'
        getFormData()
    }, [sortConfig])

    useEffect(() => {
        getFormData()
    }, [isUpdate])

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //Submit Handler
    const handleSubmit = () => {
        setLoading(true)
        post(`${API_SERVER}api/user/InsertUpdateUserMaster`, { ...TokenData, ...recipe }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    if (res.focus === 'txtUserGroup') {
                        inputrefs.current[res.focus].children[0].children[0].focus();
                    } else { inputrefs.current[res.focus].focus(); }

                    toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "SUCCESS") {
                    setLoading(false)
                    toast.success(res.message)
                    getFormData()
                    handleClear()
                }
                else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message)
                    setLoading(false)
                }
            }
        });
        setLoading(false)
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/user/EditUserMaster`, { ...TokenData, UserID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "SUCCESS") {
                    setLoading(false)
                    let data = res.data
                    setRecipe(recipe => ({ UCode: data.uCode, UserID: data.userID, UserName: data.userName, UserGroup: data.userGroup, MobileNo: data.mobileNo, Password: data.password, EmailAddress: data.emailAddress, Address: data.address, Active: data.active }))
                }
                else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message)
                    setLoading(false)
                }
            }
        });
        setLoading(false)

    }

    //Handle Delete
    const handleDelete = (Id) => {
        confirmAlert({
            message: 'Do you want to Delete this Record ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setLoading(true)
                        await post(`${API_SERVER}api/user/DeletUserMaster`, { ...TokenData, UserID: Id }, (res) => {
                            if (res && res.status) {
                                if (res.status === "ERROR") {
                                    toast.error(res.message)
                                    setLoading(false)
                                } else if (res.status === "SUCCESS") {
                                    toast.success(res.message)
                                    setLoading(false)
                                    getFormData()
                                    handleClear()
                                }
                                else if (res.status === "UNAUTHORIZED") {
                                    toast.error(res.message)
                                    setLoading(false)
                                }
                            }
                        });
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
        setLoading(false)
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({ ...recipe, UCode: '', UserID: '', UserName: '', UserGroup: '', MobileNo: '', Password: '', EmailAddress: '', Address: '', Active: true }))
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };


    let UserGroupData = []
    for (let i = 0; i < groupAllData.length; i++) {
        let mydata = groupAllData[i]?.values
        UserGroupData.push(mydata)
    };

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">User Master</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtuserName'].focus() };
                            }}
                            label='User Code'
                            id='txtusercode'
                            maxLength='50'
                            placeholder="System Generated"
                            required
                            disabled={true}
                            value={recipe.UCode}
                            onChange={(e) => HandleChange('UCode', e.target.value)}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtUserGroup'].children[0].children[0].focus() };
                            }}
                            label='User Name'
                            id='txtUserName'
                            maxLength='100'
                            autoComplete='off'
                            placeholder="User Name"
                            required
                            value={recipe.UserName}
                            onChange={(e) => HandleChange('UserName', e.target.value)}
                        />

                        <AutoCompleteBox
                            loading={dataLoading}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtMobileNo'].focus() };
                            }}
                            label='User Group'
                            placeholder="User Group"
                            maxLength='50'
                            options={UserGroupData}
                            id='txtUserGroup'
                            onFocus={() => getUserGroup()}
                            required={true}
                            inputValue={recipe.UserGroup ? recipe.UserGroup : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('UserGroup', newInputValue)
                            }}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtPassword'].focus() };
                            }}
                            label='Mobile Number'
                            id='txtMobileNo'
                            maxLength='15'
                            autoComplete='off'
                            placeholder="Mobile Number"
                            required
                            value={recipe.MobileNo}
                           //onChange={(e) => HandleChange('MobileNo', e.target.value.replace(/[^0-9+\s-]/g, ""))}
                            onChange={(e) => HandleChange('MobileNo', e.target.value.replace(/(\+.*\+)/g, "+").replace(/-+/g, "-").replace(/\s+/g, " "))}


                        />

                        {/*<InputBox*/}
                        {/*    inputrefs={inputrefs}*/}
                        {/*    onKeyDown={(e) => {*/}
                        {/*        if (e.key === 'Enter') { inputrefs.current['txtEmailAddress'].focus() };*/}
                        {/*    }}*/}
                        {/*    inputtype='password'*/}
                        {/*    label='Password'*/}
                        {/*    id='txtPassword'*/}
                        {/*    maxLength='50'*/}
                        {/*    placeholder="Password"*/}
                        {/*    required*/}
                        {/*    value={recipe.Password}*/}
                        {/*    onChange={(e) => HandleChange('Password', e.target.value)}*/}
                          
                        {/*/>*/}
                        <div className='col-md-3 mb-1 cntrl-width'>
                            <label className="form-label" htmlFor='txtArea'>Password <span style={{ color: "red" }}> *</span></label>
                            <div className="input-group">
                                <input
                                    ref={ref => (inputrefs.current['txtPassword'] = ref)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            inputrefs.current['txtEmailAddress'].focus();
                                        }
                                    }}
                                    type='password'
                                    label='Password'
                                    id='txtPassword'
                                   
                                    autoComplete='off'
                                    className='form-control'
                                    maxLength='50'
                                    placeholder="Password"
                                    value={recipe.Password}
                                    onChange={(e) => HandleChange('Password', e.target.value)}
                                />
                               
                                    <span className="input-group-text" data-bs-toggle="modal" data-bs-target="#UserModal">
                                        <i className="fa fa-info-circle" ></i>
                                    </span>
                             
                            </div>
                        </div>
                        <div className="modal fade" id="UserModal" data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabIndex="-1"
                            aria-labelledby="aboutUsLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog">
                                <div className="modal-content" style={{ background: "mintcream" }}>

                                    <div className=' col-sm-12  password-policy-border  border border-1 p-0 mx-4 my-sm-2' style={{ height: "fit-content", width:"auto" }}>
                                        <div className=' py-2 password-policy px-3' style={{ backgroundColor: "#F8F9FA" }}>
                                            <h6 className="m-0 font-weight-bold text-black">{'Password Policy'}</h6>
                                        </div>
                                        <div className='p-2'>
                                            <div className="form-group px-2">
                                                <li>White spaces are not allowed.</li>
                                                <li>Password must be between 8 to 15 character(s).</li>
                                                <li>Password must be combination of atleast one numeric and one upper case letter,lower case letter and Special character [@@$#!() ]</li>

                                            </div>
                                        </div>


                                    </div>
                                    <div className="modal-footer" style={{ padding: '3px' }}>
                                       
                                          
                                        <button type="button" className="btn btn-secondary" style={{ margin: '5px', minWidth: '30px' }} data-bs-dismiss="modal"> <i className='fa fa-close' /> Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                       

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtAddress'].focus() };
                            }}
                            label='Email Address'
                            id='txtEmailAddress'
                            maxLength='100'
                            placeholder="Email Address"
                            value={recipe.EmailAddress}
                            onChange={(e) => HandleChange('EmailAddress', e.target.value)}
                            required
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtIsActive'].focus() };
                            }}
                            label='Address'
                            id='txtAddress'
                            maxLength='100'
                            placeholder="Address"
                            required
                            value={recipe.Address}
                            onChange={(e) => HandleChange('Address', e.target.value)}
                        />

                        <Switch
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            label='Active'
                            checked={recipe.Active}
                            id='txtIsActive'
                            nextinputid='txtFranchiseGroup'
                            onChange={(e) => HandleChange('Active', e.target.checked)}
                        />

                        <FormButton
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/user/ShowUserMaster', ExportRequest, 'User Master')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>

            {/* Table Component start*/}
            <Table
                Token={TokenData}
                PageName='UserMaster'
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleFilter={() => {
                    getFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                //  loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {/* Table Component End*/}


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>
    )
}

export default UserMaster