import React from 'react'

function PickUpOrderData({ recipe }) {
    return (
        <>
            {recipe && recipe.bookingDate ?
                <>
                    <div className="table_heading">
                        Shipment Detail
                    </div>
                    <div className="form-box service-content-area ms-3 me-3 " >
                        <div className="row align-items-end details-table" >
                            <table className='mb-0 table table-bordered table-responsive table-hover table-striped' style={{ whiteSpace: 'nowrap' }}>
                                <tbody style={{ fontSize: '13px', padding: '1px' }}>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Forwarding Party </td>
                                        <td> {recipe.forwardingParty} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Tracking No </td>
                                        <td> {recipe.trackingNo} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>AWB No</td>
                                        <td> {recipe.orderNo} </td>
                                    </tr>
                                    {/*<tr>*/}
                                    {/*    <td className='fw-bold HistoryColor'>AWB No</td>*/}
                                    {/*    <td> {recipe.awbNo} </td>*/}
                                    {/*</tr>*/}
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Booking Date </td>
                                        <td> {recipe.bookingDate} </td>
                                    </tr>

                                    <tr>
                                        <td className='fw-bold HistoryColor'>Client </td>
                                        <td> {recipe.client} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Consignor </td>
                                        <td> {recipe.consignor} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Consignor Address1</td>
                                        <td> {recipe.consignorAddress1} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'> Consignor Address2</td>
                                        <td> {recipe.consignorAddress2} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Consignor Address3</td>
                                        <td> {recipe.consignorAddress3} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Consignor City</td>
                                        <td> {recipe.consignorCity} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Consignor State</td>
                                        <td> {recipe.consignorState} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Consignor Country</td>
                                        <td> {recipe.consignorCountry} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Consignor PinCode </td>
                                        <td> {recipe.consignorZipCode} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Consignor Mobile Number</td>
                                        <td> {recipe.consignorPhoneNo} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Consignor Email ID</td>
                                        <td> {recipe.consignorEmailID} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Consignee</td>
                                        <td> {recipe.consignee} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Consignee CPerson </td>
                                        <td> {recipe.consigneeCPerson} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Consignee Address1 </td>
                                        <td> {recipe.consigneeAddress1} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Consignee Address2 </td>
                                        <td> {recipe.consigneeAddress2} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Consignee Address3 </td>
                                        <td> {recipe.consigneeAddress3} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Consignee City </td>
                                        <td> {recipe.consigneeCity} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Consignee State </td>
                                        <td> {recipe.consigneeState} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Consignee Country </td>
                                        <td> {recipe.consigneeCountry} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Consignee PinCode </td>
                                        <td> {recipe.consigneeZipCode} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Consignee Mobile Number </td>
                                        <td> {recipe.consigneePhoneNo} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Consignee Email ID </td>
                                        <td> {recipe.consigneeEmailID} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Packet Type </td>
                                        <td> {recipe.packetType} </td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold HistoryColor'>Payment Type </td>
                                        <td> {recipe.paymentType} </td>
                                    </tr>

                                    <tr>
                                        <td className='fw-bold HistoryColor'>Packet Status </td>
                                        <td> {recipe.packetStatus} </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </>
                : ''}
        </>
    )
}

export default PickUpOrderData