import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';

//login Page
import Login from './components/Login/Login';
import ForgotPasswordSlide1 from './components/Login/ForgotPasswordSlide1';
import ForgotPasswordSlide2 from './components/Login/ForgotPasswordSlide2';

//context Auth Provider
import { AuthProvider } from './context/auth';

//protected Routes
import { ProtectedRoute } from './ProtectedRoutes';

//mainLayout
import Layout from './components/Layout/Layout';

//----------------------------------- all components-----------------------------------------------//

//To View Pdf
import PDFView from './components/commoncomponents/PDFView';
import ImageView from './components/commoncomponents/ImageView';

//----------------------Dashboard Screen start-----------------//
import Dashboard from './components/Dashboard/Dashboard';
//----------------------Dashboard Screen End-------------------//


//----------------Opeartion Sreeen Start------------------------//
import PickupEntry from './components/Operation/PickupEntry';
import LabelPrint from './components/Operation/LabelPrint';
import UpdateForwarding from './components/Operation/UpdateForwarding';
import MovementEntry from './components/Operation/MovementEntry';
import MakeConection from './components/Operation/MakeConnection';
//----------------Opeartion Sreeen End------------------------//

//--------------------------Dummy Route Start----------------------//
import PageNotFound from './components/commoncomponents/PageNotFound';
//--------------------------Dummy Route End----------------------//

//----------------------User Screens Start----------------------------//
import UserGroup from './components/User/UserGroup';
import UserMaster from './components/User/UserMaster';
import ChangePassword from './components/User/ChangePassword';
import ResetPassword from './components/User/ResetPassword';
import UserGroupMenu from './components/User/UserMenuAccess';
//----------------------User Screens Start----------------------------//


//--------------------Master Screen Start-------------------------------//
import PinCodeMaster from './components/Master/PinCodeMaster';
import CountryMaster from './components/Master/CountryMaster';
import StateMaster from './components/Master/StateMaster';
import CityMaster from './components/Master/CityMaster';
import CurrencyMaster from './components/Master/CurrencyMaster';
import ZoneMaster from './components/Master/ZoneMaster';
import BranchMaster from './components/Master/BranchMaster';
import ConsignorConsigneeMaster from './components/Master/ConsignorConsigneeMaster';
import PacketTypeMaster from './components/Master/PacketTypeMaster';
import ForwardingPartyMaster from './components/Master/ForwardingPartyMaster';
import ClientMaster from './components/Master/ClientMaster';
//--------------------Master Screen End-------------------------------//

//--------------------Report Screen Start--------------------------------//
import AwbQuery from './components/QueryReport/AwbQuery';
import ReportQuery from './components/QueryReport/ReportQuery';
import KycDetails from './components/Report/KycDetails';
import UserMenuAccess from './components/User/UserMenuAccess';
import GroupRights from './components/User/GroupRights';
import BulkMovementEntry from './components/Operation/BulkMovementEntry';
import UserLogReport from './components/Report/UserLogReport';
import PODupload from './components/Operation/PODUpload';
import BookingSeaCargo from './components/Operation/BookingSeaCargo';
import SeaCargoReport from './components/Report/SeaCargoReport';
//---------------------Report Screen End--------------------------------//


function App() {
    return (
        <>
            <ToastContainer position={'top-center'} />
            <BrowserRouter>
                <AuthProvider>
                    <Routes>
                        <Route element={<ProtectedRoute><Layout /></ProtectedRoute>}>
                            <Route exact path='Dashboard' element={<Dashboard />} />

                            <Route exact path='UserMaster' element={<UserMaster />} />
                            <Route exact path='UserMenuAccess' element={<UserMenuAccess />} />
                            <Route exact path='UserGroup' element={<UserGroup />} />
                            <Route exact path='ChangePassword' element={<ChangePassword />} />
                            <Route exact path='ResetPassword' element={<ResetPassword />} />
                            <Route exact path='PickupEntry' element={<PickupEntry />} />
                            <Route exact path='BookingSeaCargo' element={<BookingSeaCargo />} />
                            <Route exact path='LabelPrint' element={<LabelPrint />} />
                            <Route exact path='ForwardingParty' element={<UpdateForwarding />} />
                            <Route exact path='MakeConnection' element={<MakeConection />} />
                            <Route exact path='PinCodeMaster' element={<PinCodeMaster />} />
                            <Route exact path='CountryMaster' element={<CountryMaster />} />
                            <Route exact path='ForwardingPartyMaster' element={<ForwardingPartyMaster />} />
                            <Route exact path='ClientMaster' element={<ClientMaster />} />
                            <Route exact path="GroupRights" element={<GroupRights />} />
                            <Route exact path='StateMaster' element={<StateMaster />} />
                            <Route exact path='ZoneMaster' element={<ZoneMaster />} />
                            <Route exact path='BranchMaster' element={<BranchMaster />} />
                            <Route exact path='ConsignorConsigneeMaster' element={<ConsignorConsigneeMaster />} />
                            <Route exact path='CityMaster' element={<CityMaster />} />
                            <Route exact path='CurrencyMaster' element={<CurrencyMaster />} />
                            <Route exact path='PacketTypeMaster' element={<PacketTypeMaster />} />
                            <Route exact path='AwbQuery' element={<AwbQuery />} />
                            <Route exact path='ReportQuery' element={<ReportQuery />} />
                            <Route exact path='SeaCargoReport' element={<SeaCargoReport />} />
                            <Route exact path='MovementEntry' element={<MovementEntry />} />
                            <Route exact path='KycDetails' element={<KycDetails />} />
                            <Route exact path='UserLogReport' element={<UserLogReport/> }/>
                            <Route exact path='BulkMovement' element={<BulkMovementEntry />} />
                            <Route exact path='PODupload' element={<PODupload/> }/>
                        </Route>
                        <Route exact path='/' element={<Login />} />
                        <Route exact path='/PdfView' element={<PDFView />} />
                        <Route exact path='/ImageView' element={<ImageView />} />
                        <Route exact path='/forgotPassword' element={<ForgotPasswordSlide1 />} />
                        <Route path='*' element={<PageNotFound />} />
                        <Route exact path='/login/forgotPassword' element={<ForgotPasswordSlide2 />} />
                    </Routes>
                </AuthProvider>
            </BrowserRouter>
        </>
    );
}

export default App;
