import React, { createContext,useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Cookies } from 'react-cookie';

//react toast 
import { toast } from 'react-toastify';

const cookies = new Cookies();
const authContext = createContext();

export function AuthProvider({ children }) {
    const [user, setUser] = useState(cookies.get('user') || null)
    const navigate = useNavigate();

    const login = (userDetails) => {
        cookies.set('user', userDetails)
        setUser(userDetails);
        navigate("/Dashboard");
    };

    const logout = () => {
        setUser(null);
        cookies.remove('user')
        toast.success('Logout Successfully !')
        navigate("/");
    };

    return (
        <authContext.Provider value={{ user, login, logout }}>
            {children}
        </authContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(authContext)
}