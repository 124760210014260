import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { API_SERVER } from '../../Utils';
import { post } from '../../apiservices/service';
import { useAuth } from '../../context/auth';
import BlurLoader from "../Loder/BlurLoader";
import { AutoCompleteBoxNew, DateBox, FormButtonWithPrint, InputBox, SelectInputBox } from "../commoncomponents/InputBox";
import { DropDownData, convertThreeDecimalsOnValue, convertToNumber, convertTwoDecimalsOnValue } from '../commoncomponents/CustomHook';

const BookingSeaCargo = () => {
    const inputrefs = useRef([]);
    const { user } = useAuth();
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }

    const [formData, setFormData] = useState({
        "isUpdate": false,
        "blmtdNo": "",
        "bookingDate": new Date(),
        "cCode": "",
        "consignorName": "",
        "consignorAddress1": "",
        "consignorAddress2": "",
        "consignorAddress3": "",
        "consignorEmailID": "",
        "consignorZipCode": "",
        "consignorCountryCode": "",
        "consignorStateCode": "",

        "consignorCity": "",
        "consignorPhoneNo": "",
        "consignorGSTIN": "",
        "consignorCPerson": "",
        "consignorPanNo": "",
        "consigneeName": "",
        "consigneeCPerson": "",
        "consigneeAddress1": "",
        "consigneeAddress2": "",
        "consigneeAddress3": "",
        "consigneeCity": "",
        "consigneeStateCode": "",
        "consigneeCountryCode": "",
        "consigneeZipCode": "",
        "consigneePhoneNo": "",
        "consigneeEmailID": "",


        "consigneeGSTIN": "",
        "consigneeIECNo": "",
        "consigneePanNo": "",
        "placeOfAcceptance": "",
        "portOfLandling": "",
        "portOfDischarge": "",
        "placeOfDelivery": "",
        "vessel": "",
        "countryOfOrigin": "",
        "placeOfTransShipment": "",
        "containerNo": "",
        "noOfPackage": "",
        "kindsOfPackage": "",
        "saidToContain": "",
        "freightAmount": '',
        "freightPayableAt": "",
        "pieces": "",
        "totalValue": "",
        "currencyCode": "",
        "actualWeight": "",
        "weightUnit": "KG",
        "grossWeight": "",
        "netWeight": "",
        "marksAndNumbers": "",

    });

    const handleClear = () => {
        setFormData({
            ...formData,
            "isUpdate": false,
            //"blmtdNo": "",
            "bookingDate": new Date(),
            "cCode": "",
            "consignorName": "",
            "consignorAddress1": "",
            "consignorAddress2": "",
            "consignorAddress3": "",
            "consignorEmailID": "",
            "consignorZipCode": "",
            "consignorCountryCode": "",
            "consignorStateCode": "",

            "consignorCity": "",
            "consignorPhoneNo": "",
            "consignorGSTIN": "",
            "consignorCPerson": "",
            "consignorPanNo": "",
            "consigneeName": "",
            "consigneeCPerson": "",
            "consigneeAddress1": "",
            "consigneeAddress2": "",
            "consigneeAddress3": "",
            "consigneeCity": "",
            "consigneeStateCode": "",
            "consigneeCountryCode": "",
            "consigneeZipCode": "",
            "consigneePhoneNo": "",
            "consigneeEmailID": "",


            "consigneeGSTIN": "",
            "consigneeIECNo": "",
            "consigneePanNo": "",
            "placeOfAcceptance": "",
            "portOfLandling": "",
            "portOfDischarge": "",
            "placeOfDelivery": "",
            "vessel": "",
            "countryOfOrigin": "",
            "placeOfTransShipment": "",
            "containerNo": "",
            "noOfPackage": "",
            "kindsOfPackage": "",
            "saidToContain": "",
            "freightAmount": '',
            "freightPayableAt": "",
            "pieces": "",
            "totalValue": "",
            "currencyCode": "",
            "actualWeight": "",
            "weightUnit": "KG",
            "grossWeight": "",
            "netWeight": "",
            "marksAndNumbers": "",
        })
    }

    const [loading, setLoading] = useState(false)
    const [isEdit, setisEdit] = useState(false)
    const [isFormEdit, setisFormEdit] = useState(false)
    let GetCurrencyMaster = DropDownData('GetCurrencyMaster');

    const getConsigneeDataByClient = (cCode, type) => {
        //  setLoading(true)

        post(`${API_SERVER}api/Operation/GetConsigneeDetailsClientWise`, { ...TokenData, client: cCode ? cCode : '', Type: type }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setFormData({
                        ...formData,
                        cCode: cCode,
                        consigneeName: '',
                        consigneeCPerson: '',
                        consigneeAddress1: '',
                        consigneeAddress2: '',
                        consigneeAddress3: '',
                        consigneeEmailID: '',
                        consigneeZipCode: '',
                        consigneeCountryCode: '',
                        consigneeStateCode: '',
                        consigneeCity: '',
                        consigneePhoneNo: '',
                        consigneeGSTIN: ''
                    })
                } else {
                    let data = res.data
                    setFormData({
                        ...formData,
                        cCode: cCode,
                        consigneeName: data.consigneeName,
                        consigneeCPerson: data.consigneeCPerson,
                        consigneeAddress1: data.consigneeAddress1,
                        consigneeAddress2: data.consigneeAddress2,
                        consigneeAddress3: data.consigneeAddress3,
                        consigneeEmailID: data.consigneeEmail,
                        consigneeZipCode: data.consigneePinCode,
                        consigneeCountryCode: data.consigneeCountry,
                        consigneeStateCode: data.consigneeState,
                        consigneeCity: data.consigneeCity,
                        consigneePhoneNo: data.consigneeMobileNumber,
                        consigneeGSTIN: data.consigneeGSTIN,
                        consigneePanNo: data.consigneePANNO,
                        consigneeIECNo: data.consigneeIECNO,
                    })
                    // setLoading(false)
                }
            }
        });
    }



    //---------------------------getConsignorData------------------------------//
    const getConsignorDetailsData = (name, type) => {
        let temp = {
            Type: "Consignor",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        //setLoading(true)
        post(`${API_SERVER}api/Operation/GetConsignorConsigneeDetail`, { ...TokenData, ClientCCName: name, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    //setLoading(false)
                    setFormData({
                        ...formData,
                        consignorName: name,
                        consignorCPerson: '',
                        consignorAddress1: '',
                        consignorAddress2: '',
                        consignorAddress3: '',
                        consignorEmailID: '',
                        consignorZipCode: '',
                        consignorCountryCode: '',
                        consignorStateCode: '',
                        consignorCity: '',
                        consignorPhoneNo: '',
                        consignorGSTIN: ''

                    })
                } else {
                    let data = res.data
                    setFormData({
                        ...formData,
                        consignorName: name,
                        consignorCPerson: data.contactPerson,
                        consignorAddress1: data.address1,
                        consignorAddress2: data.address2,
                        consignorAddress3: data.address3,
                        consignorEmailID: data.email,
                        consignorZipCode: data.zip,
                        consignorCountryCode: data.country,
                        consignorStateCode: data.state,
                        consignorCity: data.city,
                        consignorPhoneNo: data.phone,
                        consignorGSTIN: data.gstin,
                    })
                }
            }
        });
    }

    const getConsigneeDetailsData = (name, type) => {
        let temp = {
            Type: "Consignee",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        //setLoading(true)
        post(`${API_SERVER}api/Operation/GetConsignorConsigneeDetail`, { ...TokenData, ClientCCName: name, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    //setLoading(false)
                    setFormData({
                        ...formData,
                        consigneeName: name,
                        consigneeCPerson: '',
                        consigneeAddress1: '',
                        consigneeAddress2: '',
                        consigneeAddress3: '',
                        consigneeEmailID: '',
                        consigneeZipCode: '',
                        consigneeCountryCode: '',
                        consigneeStateCode: '',
                        consigneeCity: '',
                        consigneePhoneNo: '',
                        consigneeGSTIN: '',
                        consigneePanNo: ''

                    })
                } else {
                    let data = res.data
                    setFormData({
                        ...formData,
                        consigneeName: name,
                        consigneeCPerson: data.contactPerson,
                        consigneeAddress1: data.address1,
                        consigneeAddress2: data.address2,
                        consigneeAddress3: data.address3,
                        consigneeEmailID: data.email,
                        consigneeZipCode: data.zip,
                        consigneeCountryCode: data.country,
                        consigneeStateCode: data.state,
                        consigneeCity: data.city,
                        consigneePhoneNo: data.phone,
                        consigneeGSTIN: data.gstin,

                    })
                    //setLoading(false)
                }
            }
        });
    }

    const getCityStateByPincodeConsignee = (consignorZipCode) => {
        //setLoading(true)
        post(`${API_SERVER}api/mASTER/GetPinCodeData`, { ...TokenData, consignorZipCode }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    //setLoading(false)
                    setFormData({
                        ...formData,
                        consigneeCountryCode: "",
                        consigneeStateCode: "",
                        consigneeCity: "",
                    })
                } else {
                    let data = res.data
                    setFormData({
                        ...formData,
                        consigneeZipCode: data.consignorZipCode,
                        consigneeCountryCode: data.consignorCountryCode,
                        consigneeStateCode: data.consignorStateCode,
                        consigneeCity: data.consignorCity,

                    })
                    //setLoading(false)
                }
            }
        });
    }

    const HandleChange = (prop, value) => {
        setFormData(formData => ({ ...formData, [prop]: value }))
    }

    const handleSubmit = () => {
        let temp = {
            ...formData,
        }
        setLoading(true)
        post(`${API_SERVER}api/Operation/SaveSeaCargoEntry`, { ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                    inputrefs.current[res.focus].focus()
                } else if (res.status === "SUCCESS") {
                    setLoading(false)
                    handleClear()
                    toast.success(res.message)
                    handlePrint(res.blmtdNo)
                }
                else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message)
                    setLoading(false)
                }
            }
        });
        setLoading(false)
    }

    const getPickUpEntryData = (value) => {
        post(`${API_SERVER}api/Operation/GetSeaCargoentryData`, { ...TokenData, BLMTDNo: value, isUpdate: formData.isUpdate }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    if (res.message) toast.error(res.message)
                } else {
                    let record = res.data
                    setFormData((pre) => ({
                        ...pre, ...record, "isUpdate": true,
                    }))
                }
            }
        });
    }

    const handlePrint = () => {
        if (formData.blmtdNo !== '') {
            post(`${API_SERVER}api/Print/PrintSeaCargoEntry`, { ...TokenData, BLMTDNo: formData.blmtdNo }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                    } else {
                        window.open(`/PdfView/#data:application/pdf;base64,${res.base64PDF}`)
                    }
                }
            });
        }
    };

    useEffect(() => {
        //inputrefs.current['txtReferenceNo'].focus();
        document.title = 'Nihao: Booking-Sea Cargo'
    }, [])

    return (

        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">
                        Booking-Sea Cargo | <input type='checkbox' checked={formData.isUpdate} onChange={(e) => {
                            setFormData({ ...formData, isUpdate: e.target.checked })
                            setisFormEdit(!isFormEdit)
                            setisEdit(false)
                        }}
                        /> Update
                    </h6>
                </div>
                <div className='main-card m-1'>
                    <div className='card-body border-1'>
                        <div className='row'>
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtbdate'].input.focus() };
                                }}
                                label='BL/MTD Number'
                                id='txtBLNumber'
                                maxLength='50'
                                onBlur={(e) => {
                                    if (e.target.value !== '')
                                        getPickUpEntryData(e.target.value)
                                }}
                                placeholder="BL/MTD Number"
                                value={formData.blmtdNo}
                                onChange={(e) => {
                                    setFormData({ ...formData, blmtdNo: e.target.value })
                                }}
                            />
                            <DateBox
                                id='txtbdate'
                                inputrefs={inputrefs}
                                label='Booking Date'
                                placeholder="Booking Date"
                                required={true}
                                selected={formData.bookingDate === '' ? '' : new Date(formData.bookingDate)}
                                onChange={(e) => {
                                    let dateArr = e?.toString()?.split(' ')
                                    dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                    setFormData({ ...formData, bookingDate: dateArr })
                                }}
                                maxDate={new Date()}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtClient'].children[0].children[0].focus() };
                                }}
                            />
                            <AutoCompleteBoxNew
                                label='Client'
                                placeholder="Client"
                                maxLength='50'
                                id='txtClient'
                                required={true}
                                inputrefs={inputrefs}
                                value={formData?.cCode || ''}
                                onChange={
                                    async (event, newInputValue) => {
                                        newInputValue = newInputValue || '';
                                        await setFormData({ ...formData, cCode: newInputValue })
                                        await getConsigneeDataByClient(newInputValue)
                                    }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        //inputrefs.current['txtService'].focus()
                                    };
                                }}
                                autocompleteType='GetClient'
                            />

                        </div>
                    </div>
                </div>
                <div className='main-card m-1'>
                    <div className='card-header mb-1'>
                        <h6 className="m-0">Consignor Details</h6>
                    </div>
                    <div className='card-body border-1'>
                        <div className='row'>
                            <AutoCompleteBoxNew
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtAddress1'].focus() };
                                }}
                                label='Consignor Name'
                                placeholder="Consignor Name"
                                maxLength='50'
                                id='txtConsignor'
                                required={true}
                                value={formData?.consignorName || ''}
                                onChange={
                                    async (event, newInputValue) => {
                                        newInputValue = newInputValue || '';
                                        await setFormData({ ...formData, consignorName: newInputValue })
                                        await getConsignorDetailsData(newInputValue)
                                    }}
                                autocompleteType='GetConsignor'
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtAddress2'].focus() };
                                }}
                                label='Address 1'
                                id='txtAddress1'
                                maxLength='100'
                                placeholder="Address 1"
                                value={formData.consignorAddress1}
                                onChange={(e) => HandleChange('consignorAddress1', e.target.value)}
                                required
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtAddress3'].focus() };
                                }}
                                label='Address 2'
                                id='txtAddress2'
                                maxLength='100'
                                placeholder="Address 2"
                                value={formData.consignorAddress2}
                                onChange={(e) => HandleChange('consignorAddress2', e.target.value)}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtPhone'].focus() };
                                }}
                                label='Address 3'
                                id='txtAddress3'
                                maxLength='100'
                                placeholder="Address 3"
                                value={formData.consignorAddress3}
                                onChange={(e) => HandleChange('consignorAddress3', e.target.value)}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        inputrefs.current['txtEmail'].focus()
                                    };
                                }}
                                label='Mobile Number'
                                id='txtPhone'
                                maxLength='25'
                                placeholder="Mobile Number"
                                value={formData.consignorPhoneNo}
                                onChange={(e) => HandleChange('consignorPhoneNo', e.target.value.replace(/[^0-9+\s-]/g, "").replace(/(\+.*\+)/g, "+").replace(/-+/g, "-").replace(/\s+/g, " "))}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtZip'].children[0].children[0].focus() };
                                }}
                                label='Email'
                                id='txtEmail'
                                maxLength='50'
                                placeholder="Email"

                                value={formData.consignorEmailID}
                                onChange={(e) => HandleChange('consignorEmailID', e.target.value)}
                            />
                            <AutoCompleteBoxNew
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsignorCountry'].focus() };
                                }}
                                label='Pin Code'
                                placeholder="Pin Code"
                                maxLength='15'
                                id='txtZip'
                                required={true}
                                //onBlur={() => getCityStateByPincode(formData.consignorZipCode)}
                                value={formData?.consignorZipCode || ''}
                                onChange={
                                    async (event, newInputValue) => {
                                        newInputValue = newInputValue || '';
                                        await setFormData({ ...formData, consignorZipCode: newInputValue })
                                    }}
                                autocompleteType='GetPinCode'
                            />
                            <AutoCompleteBoxNew
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsignorState'].focus() };
                                }}
                                label='Country'
                                placeholder="Country"
                                maxLength='50'
                                id='txtConsignorCountry'
                                required={true}
                                value={formData?.consignorCountryCode || ''}
                                onChange={
                                    async (event, newInputValue) => {
                                        newInputValue = newInputValue || '';
                                        await setFormData({ ...formData, consignorCountryCode: newInputValue })
                                    }}
                                autocompleteType='GetCountry'
                                contextKey={formData.consignorZipCode}
                            />
                            <AutoCompleteBoxNew
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtCity'].focus() };
                                }}
                                label='State'
                                placeholder="State"
                                maxLength='50'
                                id='txtConsignorState'
                                required={false}
                                value={formData?.consignorStateCode || ''}
                                onChange={
                                    async (event, newInputValue) => {
                                        newInputValue = newInputValue || '';
                                        await setFormData({ ...formData, consignorStateCode: newInputValue })
                                    }}
                                autocompleteType='GetCountrywiseState'
                                contextKey={formData.consignorCountryCode}
                            />
                            <AutoCompleteBoxNew
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtGSTIN'].focus() };
                                }}
                                label='City'
                                placeholder="City"
                                maxLength='50'
                                id='txtCity'
                                required={true}
                                value={formData?.consignorCity || ''}
                                onChange={
                                    async (event, newInputValue) => {
                                        newInputValue = newInputValue || '';
                                        await setFormData({ ...formData, consignorCity: newInputValue })
                                    }}
                                autocompleteType='GetCountryStateWiseCity'
                                contextKey={formData.consignorCountryCode}
                                contextKey2={formData.consignorStateCode}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsignorCPerson'].focus() };
                                }}
                                label='GSTIN'
                                id='txtGSTIN'
                                maxLength='15'
                                placeholder="GSTIN"
                                required={false}
                                value={formData.consignorGSTIN}
                                onChange={(e) => HandleChange('consignorGSTIN', e.target.value)}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsignorPanNo'].focus() };
                                }}
                                label='CPerson'
                                id='txtConsignorCPerson'
                                maxLength='50'
                                placeholder="CPerson"
                                value={formData.ConsignorCPerson}
                                onChange={(e) => HandleChange('ConsignorCPerson', e.target.value)}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsignee'].children[0].children[0].focus() };
                                }}
                                label='PAN No.'
                                id='txtConsignorPanNo'
                                maxLength='10'
                                placeholder="PAN No."
                                value={formData.consignorPanNo}
                                onChange={(e) => HandleChange('consignorPanNo', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='main-card m-1'>
                    <div className='card-header mb-1'>
                        <h6 className="m-0">Consignee Details</h6>
                    </div>
                    <div className='card-body border-1'>
                        <div className='row'>
                            <AutoCompleteBoxNew
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtCPerson'].focus() };
                                }}
                                label='Consignee Name'
                                placeholder="Consignee Name"
                                maxLength='50'
                                id='txtConsignee'
                                required={true}
                                value={formData?.consigneeName || ''}
                                onChange={
                                    async (event, newInputValue) => {
                                        newInputValue = newInputValue || '';
                                        await setFormData({ ...formData, consigneeName: newInputValue })
                                        await getConsigneeDetailsData(newInputValue, "consigneeName")
                                    }}
                                autocompleteType='GetConsignee'
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsigneeAddress1'].focus() };
                                }}
                                label='CPerson'
                                id='txtCPerson'
                                maxLength='50'
                                placeholder="CPerson"
                                value={formData.consigneeCPerson}
                                onChange={(e) => HandleChange('consigneeCPerson', e.target.value)}
                            />

                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsigneeAddress2'].focus() };
                                }}
                                label='Address 1'
                                id='txtConsigneeAddress1'
                                maxLength='100'
                                placeholder="Address 1"
                                required
                                value={formData.consigneeAddress1}
                                onChange={(e) => HandleChange('consigneeAddress1', e.target.value)}
                            />

                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsigneeAddress3'].focus() };
                                }}
                                label='Address 2'
                                id='txtConsigneeAddress2'
                                maxLength='100'
                                placeholder="Address 2"
                                value={formData.consigneeAddress2}
                                onChange={(e) => HandleChange('consigneeAddress2', e.target.value)}
                            />

                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsigneePhone'].focus() };
                                }}
                                label='Address 3'
                                id='txtConsigneeAddress3'
                                maxLength='100'
                                placeholder="Address 3"
                                value={formData.consigneeAddress3}
                                onChange={(e) => HandleChange('consigneeAddress3', e.target.value)}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsigneeEmail'].focus() };
                                }}
                                label='Mobile Number'
                                id='txtConsigneePhone'
                                maxLength='25'
                                placeholder="Mobile Number"
                                value={formData.consigneePhoneNo}
                                onChange={(e) => {
                                    HandleChange('consigneePhoneNo', e.target.value.replace(/[^0-9+\s-]/g, "").replace(/(\+.*\+)/g, "+").replace(/-+/g, "-").replace(/\s+/g, " "));

                                }}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsigneeZip'].children[0].children[0].focus() };
                                }}
                                label='Email'
                                id='txtConsigneeEmail'
                                maxLength='50'
                                placeholder="Email"
                                value={formData.consigneeEmailID}
                                onChange={(e) => HandleChange('consigneeEmailID', e.target.value)}
                            />
                            <AutoCompleteBoxNew
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsigneeCountry'].focus() };
                                }}
                                label='Pin Code'
                                placeholder="Pin Code"
                                maxLength='50'
                                id='txtConsigneeZip'
                                required={true}
                                //onBlur={() => getCityStateByPincodeConsignee(formData.ConsigneeZip)}

                                value={formData?.consigneeZipCode || ''}
                                onChange={
                                    async (event, newInputValue) => {
                                        newInputValue = newInputValue || '';
                                        await setFormData({ ...formData, consigneeZipCode: newInputValue })
                                    }}
                                autocompleteType='GetPinCode'
                            />
                            <AutoCompleteBoxNew
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsigneeState'].focus() };
                                }}
                                label='Country'
                                placeholder="Country"
                                maxLength='50'
                                id='txtConsigneeCountry'
                                required={true}
                                value={formData?.consigneeCountryCode || ''}
                                onChange={
                                    async (event, newInputValue) => {
                                        newInputValue = newInputValue || '';
                                        await setFormData({ ...formData, consigneeCountryCode: newInputValue })
                                    }}
                                autocompleteType='GetCountry'
                                contextKey={formData.consigneeZipCode}
                            />
                            <AutoCompleteBoxNew
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsigneeCity'].focus() };
                                }}
                                label='State'
                                placeholder="State"
                                maxLength='50'
                                id='txtConsigneeState'
                                required={false}
                                value={formData?.consigneeStateCode || ''}
                                onChange={
                                    async (event, newInputValue) => {
                                        newInputValue = newInputValue || '';
                                        await setFormData({ ...formData, consigneeStateCode: newInputValue })
                                    }}
                                autocompleteType='GetCountrywiseState'
                                contextKey={formData.consigneeCountryCode}
                            />
                            <AutoCompleteBoxNew
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsigneeGSTIN'].focus() };
                                }}
                                label='City'
                                placeholder="City"
                                maxLength='50'
                                id='txtConsigneeCity'
                                required={true}
                                value={formData?.consigneeCity || ''}
                                onChange={
                                    async (event, newInputValue) => {
                                        newInputValue = newInputValue || '';
                                        await setFormData({ ...formData, consigneeCity: newInputValue })
                                    }}
                                autocompleteType='GetCountryStateWiseCity'
                                contextKey={formData.consigneeCountryCode}
                                contextKey2={formData.consigneeStateCode}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtIECNo'].focus() };
                                }}
                                label='GSTIN'
                                id='txtConsigneeGSTIN'
                                maxLength='15'
                                placeholder="GSTIN"
                                value={formData.consigneeGSTIN}
                                onChange={(e) => HandleChange('consigneeGSTIN', e.target.value)}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['ConsigneePan'].focus() };
                                }}
                                label='IEC No.'
                                id='txtIECNo'
                                maxLength='20'
                                placeholder="IEC No."
                                value={formData.consigneeIECNo}
                                onChange={(e) => HandleChange('consigneeIECNo', e.target.value)}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtPieces'].focus() };
                                }}
                                label='PAN No.'
                                id='ConsigneePan'
                                maxLength='10'
                                placeholder="PAN No."
                                value={formData.consigneePanNo}
                                onChange={(e) => HandleChange('consigneePanNo', e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className='main-card m-1'>
                    <div className='card-header mb-1'>
                        <h6 className="m-0">
                            <span>Other Details
                            </span>
                        </h6>
                    </div>
                    <div className='card-body border-1'>
                        <div className='row'>
                            <InputBox
                                //customclass='number_align'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtPortOfLandling'].focus() };
                                }}
                                label='Place of Acceptance'
                                placeholder='Place of Acceptance'
                                id='txtPlaceofAcceptance'
                                required
                                value={formData.placeOfAcceptance}
                                onChange={(e) =>
                                    setFormData({ ...formData, placeOfAcceptance: e.target.value })
                                }
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        inputrefs.current['txtPortOfDischarge'].focus();
                                    }
                                }}
                                label='Port of Landling'
                                id='txtPortOfLandling'
                                placeholder='Port of Landling'
                                value={formData.portOfLandling}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        portOfLandling: e.target.value
                                    });
                                }}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        inputrefs.current['txtPlaceOfDelivery'].focus();
                                    }
                                }}
                                label='Port of Discharge'
                                id='txtPortOfDischarge'
                                placeholder='Port of Discharge'
                                value={formData.portOfDischarge}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        portOfDischarge: e.target.value
                                    });
                                }}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtVESSEL'].focus() };
                                }}
                                label='Place of Delivery'
                                id='txtPlaceOfDelivery'
                                mendatory={true}
                                placeholder='Place of Delivery'
                                value={formData.placeOfDelivery}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    setFormData((prevRecipe) => ({
                                        ...prevRecipe,
                                        placeOfDelivery: inputValue,
                                    }));
                                }}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        inputrefs.current['txtCountryOfOrigin'].focus();
                                    }
                                }}
                                label='VESSEL'
                                id='txtVESSEL'
                                placeholder='VESSEL'
                                value={formData.vessel}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        vessel: e.target.value
                                    });
                                }}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        inputrefs.current['txtPlaceOfTransshipment'].focus();
                                    }
                                }}
                                label='Point & Country of Origin'
                                id='txtCountryOfOrigin'
                                placeholder='Point & Country of Origin'
                                value={formData.countryOfOrigin}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        countryOfOrigin: e.target.value
                                    });
                                }}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        inputrefs.current['txtContainerNo'].focus();
                                    }
                                }}
                                label='Route/Place of Transshipment'
                                id='txtPlaceOfTransshipment'
                                placeholder='Route/Place of Transshipment'
                                value={formData.placeOfTransShipment}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        placeOfTransShipment: e.target.value
                                    });
                                }}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        inputrefs.current['txtNoOfPackage'].focus();
                                    }
                                }}
                                label='Container No'
                                id='txtContainerNo'
                                placeholder='Container No'
                                value={formData.containerNo}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        containerNo: e.target.value
                                    });
                                }}
                            />
                            <InputBox
                                customclass='number_align'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        inputrefs.current['txtKindsOfPackage'].focus();
                                    }
                                }}
                                label='No of Package'
                                id='txtNoOfPackage'
                                placeholder='No of Package'
                                value={formData.noOfPackage}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        noOfPackage: convertToNumber(e.target.value)
                                    });
                                }}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        inputrefs.current['txtSaidToContain'].focus();
                                    }
                                }}
                                label='Kinds of Package'
                                id='txtKindsOfPackage'
                                placeholder='Kinds of Package'
                                value={formData.kindsOfPackage}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        kindsOfPackage: e.target.value
                                    });
                                }}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        inputrefs.current['txtGrossWeight'].focus();
                                    }
                                }}
                                label='Said to Contain'
                                id='txtSaidToContain'
                                placeholder='Said to Contain'
                                value={formData.saidToContain}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        saidToContain: e.target.value
                                    });
                                }}
                            />

                            <InputBox
                                customclass='number_align'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        inputrefs.current['txtFreightPayableAt'].focus();
                                    }
                                }}
                                label='Freight Amount'
                                id='txtFreightAmount'
                                placeholder='Freight Amount'
                                value={formData.freightAmount}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        freightAmount: convertTwoDecimalsOnValue(e.target.value)
                                    });
                                }}
                            />

                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        inputrefs.current['txtMarksAndNumbers'].focus();
                                    }
                                }}
                                label='Freight Payable At'
                                id='txtFreightPayableAt'
                                placeholder='Freight Payable At'
                                value={formData.freightPayableAt}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        freightPayableAt: e.target.value
                                    });
                                }}
                            /><InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        inputrefs.current['txtPieces'].focus();
                                    }
                                }}
                                label='Marks And Numbers'
                                id='txtMarksAndNumbers'
                                placeholder='Marks And Numbers'
                                value={formData.marksAndNumbers}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        marksAndNumbers: e.target.value
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className='main-card m-1'>
                    <div className='card-header mb-1'>
                        <h6 className="m-0">
                            <span>Weight Details
                            </span>
                        </h6>
                    </div>
                    <div className='card-body border-1'>
                        <div className='row'>
                            <InputBox
                                customclass='number_align'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtTotalValue'].focus() };
                                }}
                                label='Pieces'
                                id='txtPieces'
                                maxLength='5'
                                placeholder="Pieces"
                                required
                                value={formData.pieces}
                                onChange={(e) =>
                                    setFormData({ ...formData, pieces: convertToNumber(e.target.value) })
                                }
                            />
                            <InputBox
                                customclass='number_align'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        inputrefs.current['txtCurrency'].focus();
                                    }
                                }}
                                label='Total Value'
                                id='txtTotalValue'
                                maxLength='8'
                                placeholder="Total Value"
                                value={formData.totalValue}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        totalValue: convertTwoDecimalsOnValue(e.target.value)
                                    });
                                }}
                            />
                            <SelectInputBox
                                label='Currency'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtActualWeight'].focus() };
                                }}
                                id='txtCurrency'
                                nextinputid='txtcurrency'
                                maxLength='50'
                                value={formData.currencyCode}
                                onChange={(e) => setFormData({ ...formData, currencyCode: e.target.value })}
                            >
                                <option value=''>-Please Select-</option>
                                {GetCurrencyMaster?.data?.dataList?.map((item, index) =>
                                    <option key={item.name + index} diviser={item.code} value={item.name}>{item.name}</option>

                                )}
                            </SelectInputBox>
                            <InputBox
                                customclass='number_align'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtWeightUnit'].focus() };
                                }}
                                label='Actual Weight'
                                id='txtActualWeight'
                                maxLength='50'
                                pattern="[0-9\.]*"
                                mendatory={true}
                                placeholder="Actual Weight"
                                value={formData.actualWeight}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    setFormData((prevRecipe) => ({
                                        ...prevRecipe,
                                        actualWeight: convertThreeDecimalsOnValue(inputValue),
                                    }));
                                }}
                            />

                            <InputBox
                                label='Weight Unit'
                                inputrefs={inputrefs}
                                id='txtWeightUnit'
                                disabled={true}
                                maxLength='50'
                                value={formData.weightUnit}
                            />

                            <InputBox
                                customclass='number_align'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        inputrefs.current['txtNetWeight'].focus();
                                    }
                                }}
                                label='Gross Weight'
                                id='txtGrossWeight'
                                placeholder='Gross Weight'
                                value={formData.grossWeight}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        grossWeight: convertThreeDecimalsOnValue(e.target.value)
                                    });
                                }}
                            />
                            <InputBox
                                customclass='number_align'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        //inputrefs.current['txtFreightAmount'].focus();
                                    }
                                }}
                                label='Net Weight'
                                id='txtNetWeight'
                                placeholder='Net Weight'
                                value={formData.netWeight}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        netWeight: convertThreeDecimalsOnValue(e.target.value)
                                    });
                                }}
                            />

                        </div>
                    </div>
                </div>
                <div className='m-3'>
                    <FormButtonWithPrint
                        save={true}
                        print={true}
                        reset={true}
                        handleSubmit={handleSubmit}
                        handlePrint={handlePrint}
                    // handleClear={handleClear}
                    />
                </div>
            </div>
            {loading && < BlurLoader />}
        </>
    )
}
export default BookingSeaCargo;