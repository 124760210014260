import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, SelectInputBox } from "../commoncomponents/InputBox";
import { confirmAlert } from 'react-confirm-alert';

import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';

//services

import { getCommonData } from '../../apiservices/CommonServices';

import { post } from '../../apiservices/service';

function BranchMaster() {
    const inputrefs = useRef([]);
    const { user } = useAuth();

    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }


    //initial state
    const [recipe, setRecipe] = useState({
        BranchCode: '', BranchName: '', ContactPerson: '', Address1: '',
        Address2:'',Country:'',State:'',City:'',Email:'',Phone:'',Fax:'',PinCode:'',BranchType:'',Active: true
    })
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [countryAllData, setCountryAllData] = useState([])
    const [stateAllData, setStateAllData] = useState([])
    const [cityAllData, setCityAllData] = useState([])
    const [pinCodeAllData, setPinCodeAllData] = useState([])
    const [branchDropdown, setBranchdropdown] = useState([])
    const [countryPre, setCountryPre] = useState('')
    const [statePre, setStatePre] = useState('')
    const [isUpdate, setIsUpdate] = useState(false)
    const [dataLoading, setDataLoading] = useState(false)

    const CountryData = () => {
        let temp = {
            Type: "GetCountry",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setDataLoading(false)
                    setCountryAllData([])
                } else {
                    setDataLoading(false)
                    let data = res.dataList
                    setCountryAllData(data)
                }
            }
        });
    }

    const StateAllData = () => {
     
        let temp = {
            Type: "GetCountrywiseState",
            Prefix: '',
            ContextKey: countryPre.split(':')[0].trim(),
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setDataLoading(false)
                    setStateAllData([])
                } else {
                    setDataLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setStateAllData(data)
                }
            }
        });
    }
    const CityAllData = () => {

        let temp = {
            Type: "GetCountryStateWiseCity",
            Prefix: '',
            ContextKey: countryPre.split(':')[0].trim(),
            ContextKey2: statePre.split(':')[0].trim()
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setDataLoading(false)
                    setCityAllData([])
                } else {
                    setDataLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setCityAllData(data)
                }
            }
        });
    }
    const PinCodeData = () => {

        let temp = {
            Type: "GetPinCode",
            Prefix: "",
            ContextKey: "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setDataLoading(false)
                    setPinCodeAllData([])
                } else {
                    setDataLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setPinCodeAllData(data)
                }
            }
        });
    }

    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/MASTER/ShowBranchMaster`, { ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    const handleChange1 = (event, value) => {
        getFormData(value);
    };

    useEffect(() => {
        inputrefs.current['txtBranchName'].focus()
        document.title = 'Nihao: Branch Master'
        getFormData()  
    }, [sortConfig, isUpdate ])

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //Submit Handler
    const handleSubmit = () => {
            setLoading(true)
            post(`${API_SERVER}api/MASTER/InsertUpdateBranchMaster`, { ...TokenData, ...recipe }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        if (res.focus === 'txtPinCode' || res.focus === 'txtCity' || res.focus === 'txtCountry' || res.focus === 'txtState') {
                            inputrefs.current[res.focus].children[0].children[0].focus()
                        } else {
                            inputrefs.current[res.focus].focus()
                        }
                        setLoading(false)
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        getFormData()
                        handleClear()
                    }
                }
            });
        setLoading(false)
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/MASTER/EditBranchMaster`, { ...TokenData, branchId: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else {
                    setLoading(false)
                    let data = res.data
                    setCountryPre(data.country)
                    setStatePre(data.state)
                    PinCodeData()
                    BranchdropdownlIST()
                    setRecipe({
                        ...data, BranchCode: data.branchCode, BranchName: data.branchName, ContactPerson: data.contactPerson, Address1: data.address1,
                        Address2: data.address2, Country: data.country, State: data.state, City: data.city, PinCode: data.pincode, Email: data.email,
                        Phone: data.phone, Fax: data.fax, BranchType: data.branchType, Active: data.active
                    })
                }
            }
        });
    }

    //Handle Delete
    const handleDelete = (Id) => {
        confirmAlert({
            message: 'Do you want to Delete this Record ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setLoading(true)
                        await post(`${API_SERVER}api/MASTER/DeletBranchMaster`, { ...TokenData, branchId: Id }, (res) => {
                            if (res && res.status) {
                                if (res.status === "ERROR") {
                                    toast.error(res.message)
                                    setLoading(false)
                                } else {
                                    toast.success(res.message)
                                    setLoading(false)
                                    getFormData()
                                    handleClear()
                                }
                            }
                        });
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
        
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe, branchId:'', BranchCode: '', BranchName: '', ContactPerson: '', Address1: '',
            Address2: '', Country: '', State: '', City: '', Email: '', Phone: '', Fax: '', PinCode: '', BranchType:'', Active: true
            }))
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };


    let countryData = []
    for (let i = 0; i < countryAllData.length; i++) {
        let mydata = countryAllData[i]?.values
        countryData.push(mydata)
    };
    let stateData = []
    for (let i = 0; i < stateAllData.length; i++) {
        let mydata = stateAllData[i]?.values
        stateData.push(mydata)
    };
    let cityData = []
    for (let i = 0; i < cityAllData.length; i++) {
        let mydata = cityAllData[i]?.values
        cityData.push(mydata)
    }
    let PinCode = []
    for (let i = 0; i < pinCodeAllData.length; i++) {
        let mydata = pinCodeAllData[i]?.values
        PinCode.push(mydata)
    }

    const BranchdropdownlIST = () => {
        setBranchdropdown([])
        let temp = {
            Type: "BranchTypeMaster",
        }
        setDataLoading(true)
        post(`${API_SERVER}api/AutoComplete/GetMasterData`, { ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setDataLoading(false)
                    setBranchdropdown([])
                } else {
                    setDataLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setBranchdropdown(data)
                }
            }
        });
    }

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    return (

        <>

            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Branch Master</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtBranchName'].focus() };
                            }}
                            label='Branch Code'
                            id='txtBranchCode'
                            maxLength='10'
                            placeholder="System Generated"
                            value={recipe.BranchCode}
                            disabled={true }
                            onChange={(e) => HandleChange('BranchCode', e.target.value)}
                            required
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtContactPerson'].focus()
                                }
                            }}
                            label='Branch Name'
                            id='txtBranchName'
                            maxLength='50'
                            placeholder="Branch Name"
                            value={recipe.BranchName}
                            onChange={(e) => HandleChange('BranchName', e.target.value)}
                            required
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtAddress1'].focus()
                                }
                            }}
                            label='Contact Person'
                            id='txtContactPerson'
                            maxLength='50'
                            placeholder="Contact Person"
                            value={recipe.ContactPerson}
                            onChange={(e) => HandleChange('ContactPerson', e.target.value)}
                            required
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtAddress2'].focus()
                                }
                            }}
                            label='Address 1'
                            id='txtAddress1'
                            maxLength='50'
                            placeholder="Address1"
                            value={recipe.Address1}
                            onChange={(e) => HandleChange('Address1', e.target.value)}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtCountry'].children[0].children[0].focus()
                                }
                            }}
                            label='Address 2'
                            id='txtAddress2'
                            maxLength='50'
                            placeholder="Address 2"
                            value={recipe.Address2}
                            onChange={(e) => HandleChange('Address2', e.target.value)}

                        />
                      
                        <AutoCompleteBox
                            loading={dataLoading}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtState'].children[0].children[0].focus() };
                            }}
                            label='Country Name'
                            placeholder="Country Name"
                            maxLength='50'
                            options={countryData}
                            id='txtCountry'
                            required={true}
                            onFocus={() => CountryData()}
                            inputValue={recipe.Country ? recipe.Country : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('Country', newInputValue)
                                setRecipe(recipe => ({ ...recipe, State: '', City: '' }))
                                setCountryPre(newInputValue)
                            }}
                        />

                        <AutoCompleteBox
                            loading={dataLoading}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtCity'].children[0].children[0].focus() };
                            }}
                            label='State Name'
                            placeholder="State Name"
                            maxLength='50'
                            options={stateData}
                            id='txtState'
                            onFocus={() => StateAllData()}
                            inputValue={recipe.State ? recipe.State : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('State', newInputValue)
                                setRecipe(recipe => ({ ...recipe, City: '' }))
                                setStatePre(newInputValue)
                            }}
                        />

                        <AutoCompleteBox
                            loading={dataLoading}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtPinCode'].children[0].children[0].focus() };
                            }}
                            label='City Name'
                            placeholder="City Name"
                            maxLength='50'
                            options={cityData}
                            id='txtCity'
                            onFocus={() => CityAllData()}
                            inputValue={recipe.City ? recipe.City : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('City', newInputValue)
                            }}
                        />

                        <AutoCompleteBox
                            loading={dataLoading}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtEmail'].focus() };
                            }}
                            label='Pin Code'
                            placeholder="Pin Code"
                            maxLength='6'
                            options={PinCode}
                            id='txtPinCode'
                            onFocus={() => PinCodeData() }
                            inputValue={recipe.PinCode ? recipe.PinCode : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('PinCode', newInputValue)
                               
                            }}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtPhone'].focus()
                                }
                            }}
                            label='Email'
                            id='txtEmail'
                            maxLength='50'
                            placeholder="Email"
                            value={recipe.Email}
                            onChange={(e) => HandleChange('Email', e.target.value)}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtFax'].focus()
                                }
                            }}
                            label='Mobile Number'
                            id='txtPhone'
                            maxLength='13'
                            placeholder="Mobile Number"
                            value={recipe.Phone}
                            onChange={(e) => HandleChange('Phone', e.target.value.replace(/\D/g, ""))}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtBranchType'].focus()
                                }
                            }}
                            label='Fax'
                            id='txtFax'
                            maxLength='50'
                            placeholder="Fax"
                            value={recipe.Fax}
                            onChange={(e) => HandleChange('Fax', e.target.value)}
                        />

                        <SelectInputBox
                            label='Branch Type'
                            inputrefs={inputrefs}
                            id='txtBranchType'
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtIsActive'].focus()
                                }
                            }}
                            required={true}
                            onFocus={() => BranchdropdownlIST() }
                            maxLength='50'
                            value={recipe.BranchType}
                            onChange={(e) => setRecipe({ ...recipe, BranchType: e.target.value })}
                        >
                            <option selected>-Please Select-</option>
                            {dataLoading ? <option> loading... </option>
                                : 
                                branchDropdown?.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    )
                                })
                            }
                        </SelectInputBox>

                        <Switch
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSubmit()
                                }
                            }}
                            label='Active'
                            checked={recipe.Active}
                            id='txtIsActive'
                            nextinputid='txtFranchiseGroup'
                            onChange={(e) => HandleChange('Active', e.target.checked)}
                        />

                        <FormButton
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/MASTER/ShowBranchMaster', ExportRequest, 'Branch Master')}
                            handleClear={handleClear}
                        />

                    </div>
                </div>
            </div>

            {/* Table Component start*/}
            <Table
                Token={TokenData}
                PageName='BranchMaster'
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleFilter={() => {
                    getFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                //  loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {/* Table Component End*/}


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}

        </>
    )
}

export default BranchMaster