import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox } from "../commoncomponents/InputBox";
import { confirmAlert } from 'react-confirm-alert'

import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';

//services
import { getCommonData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';

function StateMaster() {
    const inputrefs = useRef([]);
    const { user } = useAuth();

    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }

    //initial state
    const [recipe, setRecipe] = useState({ CountryCode: '', StateName: '', StateCode: '', Active: true })
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [countryAllData, setCountryAllData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [dataLoading, setDataLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    const CountryData = () => {
        let temp = {
            Type: "GetCountry",
            Prefix: ".",
            ContextKey: "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setDataLoading(false)
                } else {
                    setDataLoading(false)
                    let data = res.dataList
                    setCountryAllData(data)
                }
            }
        });
    }

    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/MASTER/ShowStateMaster`,{ ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    const handleChange1 = (event, value) => {
        getFormData(value);
    };

    useEffect(() => {
        inputrefs.current['txtStateCode'].focus()
        document.title = 'Nihao: State Master'
        getFormData()
    }, [sortConfig])

    useEffect(() => {
        getFormData()
    }, [isUpdate])

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //Submit Handler
    const handleSubmit = () => {
            setLoading(true)
            post(`${API_SERVER}api/MASTER/InsertUpdateStateMaster`,{ ...TokenData, ...recipe }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        if (res.focus === 'txtCountryCode') {
                            inputrefs.current[res.focus].children[0].children[0].focus()
                        } else {
                            inputrefs.current[res.focus].focus()
                        }
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.status === "SUCCESS") {
                        setLoading(false)
                        toast.success(res.message)
                        getFormData()
                        handleClear()
                    }
                    else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message)
                        setLoading(false)
                    }
                }
            });
        setLoading(false)
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/MASTER/EditStateMaster`,{ ...TokenData, StateID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    if (res.focus === 'txtCountryCode') {
                        inputrefs.current[res.focus].children[0].children[0].focus()
                    } else {
                        inputrefs.current[res.focus].focus()
                    }
                    setIsEdit(false)
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "SUCCESS") {
                    setLoading(false)
                    setIsEdit(true)
                    CountryData()
                    let data = res.data
                    setRecipe({ ...data, CountryCode: data.countryCode, StateName: data.stateName, StateCode: data.stateCode, Active: data.active })
                }
                else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message)
                    setLoading(false)
                }
            }
        });
        setLoading(false)

    }

    //Handle Delete
    const handleDelete = (Id) => {
        confirmAlert({
            message: 'Do you want to Delete this Record ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setLoading(true)
                        await post(`${API_SERVER}api/MASTER/DeleteStateMaster`, { ...TokenData, StateID: Id }, (res) => {
                            if (res && res.status) {
                                if (res.status === "ERROR") {
                                    toast.error(res.message)
                                    setLoading(false)
                                } else if (res.status === "SUCCESS") {
                                    toast.success(res.message)
                                    setLoading(false)
                                    getFormData()
                                    handleClear()
                                }
                                else if (res.status === "UNAUTHORIZED") {
                                    toast.error(res.message)
                                    setLoading(false)
                                }
                            }
                        });
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
        setLoading(false)
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({ ...recipe, StateID:'', CountryCode: '', StateName: '', StateCode: '', Active: true }))
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        setIsEdit(false)
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };


    let countryData = []
    for (let i = 0; i < countryAllData.length; i++) {
        let mydata = countryAllData[i]?.values
        countryData.push(mydata)
    };


    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    return (

        <>

            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">State Master</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtStateName'].focus()
                                }
                            }}
                            label='State Code'
                            id='txtStateCode'
                            maxLength='2'
                            disabled={isEdit}
                            placeholder="State Code"
                            value={recipe.StateCode}
                            error={errors.StateCode}
                            onChange={(e) => HandleChange('StateCode', e.target.value)}
                            required
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtCountryCode'].children[0].children[0].focus() };
                            }}
                            label='State Name'
                            id='txtStateName'
                            maxLength='50'
                            placeholder="State Name"
                            value={recipe.StateName}
                            error={errors.StateName}
                            onChange={(e) => HandleChange('StateName', e.target.value)}
                            required
                        />

                        <AutoCompleteBox
                            loading={dataLoading}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtIsActive'].focus() };
                            }}
                            label='Country Name'
                            placeholder="Country Name"
                            maxLength='3'
                            options={countryData}
                            onFocus={() => CountryData()}
                            id='txtCountryCode'
                            required={true}
                            error={errors.CountryCode}
                            inputValue={recipe.CountryCode ? recipe.CountryCode : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('CountryCode', newInputValue)
                            }}
                        />

                        <Switch
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit() };
                            }}
                            label='Active'
                            checked={recipe.Active}
                            id='txtIsActive'
                            nextinputid='txtFranchiseGroup'
                            onChange={(e) => HandleChange('Active', e.target.checked)}
                        />

                        <FormButton
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/MASTER/ShowStateMaster', ExportRequest, 'State Master')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>

            {/* Table Component start*/}
            <Table
                Token={TokenData}
                PageName='StateMaster'
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleFilter={() => {
                    getFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                //  loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {/* Table Component End*/}


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}

        </>
    )
}

export default StateMaster