import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Cookies } from 'react-cookie';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FileUploader } from "react-drag-drop-files";
import { useRef, useState } from 'react';
import { FetchAutoCompleteData } from './CustomHook';

const cookies = new Cookies();
export const FormButton = (props) => {


    let { handleSubmit, handleExport, handleClear, id = null, inputrefs, children, save = true, Export = true, update = false } = props
    return (

        <div className='d-block mt-3 d-md-flex' >
            <div className="">
                {save ?
                    <button type="button" name={id}
                        ref={ref => (inputrefs ? inputrefs.current[id] = ref : null)}
                        onClick={handleSubmit}
                        className="btn btn-rounded btn-primary">
                        <span className=" text-white me-2">
                            <i className="fa">&#xf0c7;</i>

                        </span>Save</button>
                    : <></>}
                {Export ?
                    <button type="button" className="btn btn-rounded btn-warning" name={id}
                        onClick={handleExport}
                    ><span className=" text-warning me-2"><i className="fa fa-download text-white color-success"></i>
                        </span>Export</button>
                    : <></>}
                {update ?
                    <button type="button" name={id}
                        onClick={handleSubmit}
                        className="btn btn-rounded btn-primary">
                        <span className=" text-white me-2">
                            <i className="fa fa-upload color-warning">
                            </i>
                        </span>Update</button>
                    : <></>}
                <button type="button" name={id} onClick={handleClear} className="btn btn-rounded btn-danger"><span className="text-white me-2"><i className="fa-solid fa-arrow-rotate-left"></i>
                </span>Reset</button>
                {children}
            </div>

        </div>

    )
}

export const FormButtonWithPrint = (props) => {

    let { handleSubmit, handlePrint, handleExport, handleClear, id, inputrefs, children, save, print, reset, buttonName, Export, btn_icon } = props

    return (

        <div className='d-block mt-3 d-md-flex' >
            <div className="">
                {save ?
                    <button type="button" name={id}
                        id='txtSubmitbtn'
                        // ref={ref => (inputrefs ? inputrefs.current[id] = ref : null)}
                        onClick={handleSubmit}
                        className="btn btn-rounded btn-primary">
                        <span className=" text-white me-2">
                            {buttonName ? <i className={btn_icon ? btn_icon : "fa fa-search"}>
                            </i> : <i className="fa">&#xf0c7;</i>}

                        </span>{buttonName ? buttonName : 'Save'}</button>
                    : <></>}

                {print ?
                    <button type="button" name={id}
                        onClick={handlePrint}
                        className="btn btn-rounded btn-secondary">
                        <span className=" text-white me-2">
                            <i className="fa fa-print color-warning">
                            </i>
                        </span>Print</button>
                    : <></>}
                {Export ?
                    <button type="button" name={id}
                        onClick={handleExport}
                        className="btn btn-rounded btn-warning">
                        <span className=" text-warning me-2">
                            <i className="fa fa-download text-white color-success">
                            </i>
                        </span>Export</button>
                    : <></>}
                {reset ?
                    <button type="button"
                        name={id}
                        onClick={handleClear}
                        className="btn btn-rounded btn-danger">
                        <span className="text-white me-2">
                            <i className="fa-solid fa-arrow-rotate-left"></i>
                        </span>Reset</button>
                    : <></>}
                {children}
            </div>

        </div>

    )
}


export const ImportButton = ({ BulkSubmit, handleBulk, downloadFormat, children, Status = [], ErrorData }) => {
    return (
        <div className='border border-2 p-2 w-100' style={{ 'borderRadius': '8px' }}>
            <label className="form-label" htmlFor={'import'}>{'Bulk Upload'}
                <span style={{ color: "red" }}> *</span>
            </label>
            <FileUploader handleChange={BulkSubmit} name="file" types={["xlsx"]} />
            <div className='mt-2'>
                <button type="button" className="btn btn-rounded btn-primary me-3 mb-0" style={{ minWidth: '8px' }} onClick={handleBulk}>
                    <i className="fa fa-upload text-white color-success"></i> Upload
                </button>
                <span type="button" style={{ minWidth: '8px', textDecoration: 'underline', color: 'blue', fontSize: '13px' }} onClick={downloadFormat}>
                    Download Format <i className="fa fa-download color-success"></i>
                </span>
            </div>
            {/*<textarea className='form-control' readOnly>{HeaderFormat}</textarea>*/}
            <div className='d-flex mb-1'>
                {Status?.length !== 0 ?
                    <>
                        <div className='text-success p-0 pt-2 m-0 fw-bold' style={{ lineHeight: '14px' }}>Success : {Status?.SuccessCount} &nbsp; &nbsp;
                            <span className='text-danger'>Error : {Status?.ErrorCount}
                                {Status?.length !== 0 && Status?.ErrorData?.length !== 0 ?
                                    <>&nbsp;<i className="fa fa-download  color-success" style={{ cursor: 'pointer' }} onClick={ErrorData}></i></>
                                    : ""}
                            </span></div>
                    </>
                    : ""}
            </div>

            {children}
        </div>
    )
}



export const InputBox = (props) => {


    let { divclassname = 'col-md-3 mb-1 cntrl-width ', key = '', customclass, onFocus, onBlur, onKeyPress, pattern, mendatory, onKeyDown = null, inputClassName = '', type = 'text', inputtype, divstyle = null,
        inputStyle = null, inputrefs, id, label = '', value, onChange, placeholder, required, error, confirm_error, ignore_common } = props

    return (

        <div
            className={`${ignore_common ? '' : divclassname}`}
            style={divstyle}
            key={key}
        >
            {label !== '' ? <label className="form-label" htmlFor={id}>{label}
                {required || mendatory ? <span style={{ color: "red" }}> *</span> : <></>}
            </label> : <></>}
            <input
                id={id}
                style={inputStyle}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyPress={onKeyPress}
                pattern={pattern}
                ref={ref => (inputrefs.current[id] = ref)}
                type={inputtype ? inputtype : type}
                className={`form-control ${inputClassName + ' ' + customclass}`}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                autoComplete="new-password"

                {...props}
            />
            {error && <div className="error-msg">{error}</div>}
            {confirm_error && <div className="error-msg">{confirm_error}</div>}
        </div>

    )
}

export const InputBoxWithLabel = (props) => {


    let { Labelclass = '', onKeyDown = null, inputClassName = '', type = 'text', divClass = 'col-md-3 col-sm-6 mb-3', divstyle = null, inputStyle = null, inputrefs, id, nextinputid, label, value, onChange, placeholder, required } = props
    return (

        <div
            className={divClass}
            style={divstyle}

        >
            <label className={`form-label ${Labelclass}`} htmlFor={id}>{label || 'Branch'}
                {required ? <span style={{ color: "red" }}> *</span> : <></>}
            </label>
            <input
                id={id}
                style={inputStyle}
                onKeyDown={onKeyDown}
                ref={ref => (inputrefs.current[id] = ref)}
                type={type}
                className={`form-control ${inputClassName}`}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                autoComplete="off"
                {...props}
            />
        </div>

    )
}

export const AutoCompleteBoxWithLabel = (props) => {

    const { className = "col-md-3 col-sm-6 mb-3", onKeyPress, onKeyDown, style, inputStyle, inputrefs, maxLength, id, label, options, inputValue, onInputChange, placeholder, required } = props
    return (
        <div
            className={className}
            style={style}
        >
            <label className="form-label" htmlFor={id}>{label || 'Branch'}
                {required ? <span style={{ color: "red" }}> *</span> : <></>}
            </label>
            <Autocomplete
                inputValue={inputValue}
                onInputChange={onInputChange}
                freeSolo
                autoComplete
                options={options}
                renderInput={(params) => <TextField
                    {...params}
                    onInput={(e) => {
                        const { value } = e.target;

                        if (value.length > maxLength) {
                            e.target.value = value.slice(0, maxLength);
                        }
                    }}
                    sx={inputStyle}
                    placeholder={placeholder}
                    ref={ref => (inputrefs.current[id] = ref)}
                    onKeyPress={onKeyPress}
                    onKeyDown={onKeyDown}

                />}
                {...props}
            />
        </div>
    )
}


export const AutoCompleteBoxNew = (props) => {
    const { divclassname = 'col-md-3 mb-1 cntrl-width', onKeyDown, style, divstyle, inputstyle, inputrefs, maxLength, label = ''
        , placeholder, required, value, autocompleteType = '', contextKey = '', contextKey2 = '', contextKey3 = '', contextKey4 = '', contextKey5 = '', renderinput = null, ...restProps } = props;

    const { id, multiple = false } = restProps

    const autoCompleteTimeoutRef = useRef(null);
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);

    const user = cookies.get('user');
    //token Data
    const Data = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }
    const handleAutoCompleteChange = (event, prefix) => {
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        autoCompleteTimeoutRef.current = setTimeout(async () => {
            const autoOptions = await FetchAutoCompleteData(Data, prefix, autocompleteType, contextKey, contextKey2, contextKey3, contextKey4, contextKey5);
            setAutoCompleteOptions(autoOptions ? autoOptions : []);
        }, 300);
    };

    return (
        <div
            className={`${divclassname}`}
            style={style || divstyle}
        >
            {label === '' ? '' :
                <label className="form-label" htmlFor={id}>{label || ''}
                    {required ? <span style={{ color: "red" }}> *</span> : <></>}
                </label>
            }
            <Autocomplete
                value={value}
                freeSolo={!multiple}
                autoComplete
                autoSelect
                autoHighlight={true}
                selectOnFocus={true}
                includeInputInList
                filterOptions={(x) => x} // for accepting every input like .
                options={autoCompleteOptions}
                onInputChange={
                    (event, newInputValue, reason) => {
                        if (reason && newInputValue && reason === 'input') {
                            handleAutoCompleteChange(event, newInputValue);
                        }
                        else {
                            if (autoCompleteOptions) {
                                setAutoCompleteOptions([]);
                            }
                        }
                    }}
                onClose={(event, reason) => {
                    if (autoCompleteOptions) {
                        setAutoCompleteOptions([]);

                    }
                }}
                title={required ? 'Please fill this field.' : ''}
                renderInput={(params) => <TextField
                    {...params}
                    onInput={(e) => {
                        const { value } = e.target;
                        if (value.length > maxLength) {
                            e.target.value = value.slice(0, maxLength);
                        }
                    }}
                    inputRef={ref => (inputrefs.current[id] = ref)}
                    sx={inputstyle}
                    placeholder={placeholder}
                    onKeyDown={onKeyDown}
                    autoComplete="off"
                />}
                {...restProps}
            />
            {renderinput}
        </div>
    )
}

export const AutoCompleteBox = (props) => {

    const { divclassname = 'col-md-3 mb-1 cntrl-width', onKeyPress, onKeyDown, style, inputStyle, inputrefs, maxLength, id, label, options, inputValue, onInputChange,
        placeholder, required, error, loading, ignore_common } = props
    return (
        <div
            className={`${ignore_common ? '' : divclassname}`}
            style={style}
        >
            <label className="form-label" htmlFor={id}>{label || 'Branch'}
                {required ? <span style={{ color: "red" }}> *</span> : <></>}
            </label>
            <Autocomplete
                loading={loading}
                inputValue={inputValue}
                onInputChange={onInputChange}
                freeSolo
                autoComplete
                options={options}
                renderInput={(params) => <TextField
                    {...params}
                    onInput={(e) => {
                        const { value } = e.target;

                        if (value.length > maxLength) {
                            e.target.value = value.slice(0, maxLength);
                        }
                    }}
                    sx={inputStyle}
                    placeholder={placeholder}
                    ref={ref => (inputrefs.current[id] = ref)}
                // onKeyPress={onKeyPress}
                //onKeyDown={onKeyDown}

                />}
                {...props}
            />
            {error && <div className="error-msg">{error}</div>}
        </div>
    )
}

export const DateBox = (props) => {
    const { divclassname = 'col-md-3 mb-1 cntrl-width', style, label, onKeyDown, maxDate, selected, placeholder, id, inputrefs, onChange, required, ignore_common } = props
    return (
        <div
            className={` ${ignore_common ? '' : divclassname}`}
            style={style}
        >
            <label className="form-label">{label || 'Branch'}
                {required ? <span style={{ color: "red" }}> *</span> : <></>}

            </label>
            <DatePicker
                className='form-control input-fields'
                dateFormat="dd-MMM-yyyy"
                placeholderText={placeholder}
                selected={selected}
                maxDate={maxDate}
                ref={ref => (inputrefs.current[id] = ref)}
                onKeyDown={onKeyDown}
                onChange={onChange}
                {...props}
            />

        </div>
    )
}

export const SelectInputBox = (props) => {

    const { divclassname = 'col-md-3 mb-1 cntrl-width', inputClassName, onFocus, onKeyDown, divstyle, error, inputStyle, inputrefs, id, label, value, onChange, children, required, ignore_common } = props
    return (
        <div
            className={` ${ignore_common ? '' : divclassname}`}
            style={divstyle}
        >
            <label className="form-label" htmlFor={id}>{label || 'Branch'}
                {required ? <span style={{ color: "red" }}> *</span> : <></>}
            </label>
            <select
                id={id}
                className={`form-select ${inputClassName}`}
                style={inputStyle}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                ref={ref => (inputrefs.current[id] = ref)}
                value={value}
                onChange={onChange}
                {...props}
            >
                {children}
            </select>
            {error && <div className="error-msg">{error}</div>}
        </div>
    )
}


export const Switch = (props) => {

    const { id, checked = false, inputrefs, onKeyDown, label, extraclassname = 'form-check form-switch ps-4 ps-md-5 mt-4', onChange, divclassname = 'col-md-3 mb-1  cntrl-width' } = props

    return (
        <div className={` ${divclassname}`} key={`checkin${id}`}>
            <div className={`${extraclassname}`} >
                <input
                    className="form-check-input"
                    type="checkbox"
                    id={id}
                    checked={checked}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    ref={ref => (inputrefs.current[id] = ref)}
                    {...props}
                />
                <label className="form-check-label ms-2" htmlFor={id}>{label}</label>
            </div>
        </div>
    )
}

export const CheckBoxInput = (props) => {
    const { id, checked = false, onKeyDown, label, onChange } = props
    return (
        <div className='col-md-4 col-lg-3 col-6 mb-1 cntrl-width' key={`checkin${id}`}>
            <div className="form-check form-switch ps-4 ps-md-5 mt-4">
                <input
                    className=""
                    type="checkbox"
                    id={id}
                    checked={checked}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    {...props}
                />
                <label className="form-check-label ms-2" htmlFor={id}>{label}</label>
            </div>
        </div>
    )
}
