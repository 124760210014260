import { FileUploader } from "react-drag-drop-files";
import { DownloadFileAtURL, ERRORDATALIST, HandleBulkInsertData, readFile } from "./ImportData";
import { useState } from "react";
import { toast } from 'react-toastify';
import { API_SERVER, initializeFilterData } from "../../Utils";
import { Table } from "../commoncomponents/Table";
import { useAuth } from "../../context/auth";
import BlurLoader from "../Loder/BlurLoader";
import { post } from "../../apiservices/service";




function BulkMovementEntry() {
    const [ImportData, setImportData] = useState([]);
    const [Name, setName] = useState('');
    const [currentPageNo, setCurrentPageNo] = useState(1);
    let [result, setResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const [Status, setStatus] = useState([]);
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const { user } = useAuth();
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }
    const BulkSubmit = async (e) => {
        let data = await readFile(e)
        setImportData(data.exceljson)
        setName(data.FileName)
        let a = document.querySelectorAll('.jRuVVs')
        if (a.length > 0) {
            a[0].innerHTML = data.FileName
        }
    };
    const DownloadFormat = async () => {
        await DownloadFileAtURL('MovementEntry.xlsx')
    };
    const ErrorData = async () => {
        await ERRORDATALIST(Status, 'PayModeError')
    };
    const handleBulk = async () => {
        if (ImportData.length !== 0) {
            if (true) {
              //  setSubLoading(true)
                let Data = await HandleBulkInsertData(`${API_SERVER}api/operation/SaveMovementEntry`, ImportData,'' , '')
                setStatus(Data)
               // setSubLoading(false)
                toast.info(`Success : ${Data.SuccessCount} Error : ${Data.ErrorCount}`)
            } else {
                toast.error('Header Format is not valid !', {
                    position: 'top-center'
                })
            }
        } else {
            toast.error('Please Choose File With Valid Data.!')
        }
    };
    function handleClear() {
        let a = document.querySelectorAll('.jRuVVs');
        if (a.length > 0) {
            a[0].innerHTML = 'Upload  or drop a file right here'
            a[0].innerHTML = a[0].innerHTML.replace('Upload', '<span className="text-decoration-underline">Upload</span>');
        }
    }
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };
    const getFormData = () => {
        setLoading(true)
        setResult({})

        post(`${API_SERVER}api/Operation/GetMovementEntryDetail`, { ...TokenData,  }, (res) => {
            if (res && res.Status) {
                console.log(res, "ad")
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({})
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
        setLoading(false)
    }

    const handleChange1 = (event, value) => {
        getFormData(value);
    };
    return (
        <>
            <div className="container">
                <div className='card-header mb-1 '>
                    <h6 className="m-0" style={{ marginTop: "10px" }}> BulkMovement Entry</h6>
                </div>
                <div className="row">
                    <div className='col-md-12 border border-2 p-2 mt-4 ' style={{ 'borderRadius': '8px', backgroundColor: "white" }}>
                    <label className="form-label" htmlFor={'import'}>{'Bulk Upload'}
                        <span style={{ color: "red" }}> *</span>
                    </label>
                    <div className="d-flex gap-4">
                        <FileUploader handleChange={BulkSubmit} name="file" types={["xlsx"]} />
                        <span type="button" style={{ minWidth: '8px', textDecoration: 'underline', color: 'blue', fontSize: '13px' }} onClick={DownloadFormat}>
                            Download Format <i className="fa fa-download color-success"></i>
                        </span>
                    </div>

                        <div className='mt-2'>
                            <button type="button" className="btn btn-rounded btn-primary me-3 mb-0" style={{ minWidth: '6px', }} onClick={handleBulk}>
                            <i className="fa fa-upload text-white color-success"></i> Upload
                        </button>
                            <button type="button"  onClick={handleClear} className="btn btn-rounded btn-danger"><span className="text-white me-2"><i className="fa-solid fa-arrow-rotate-left"></i>
                            </span>Reset</button>
                    </div>

                    <div className='d-flex mb-1'>
                        {Status?.length !== 0 ?
                            <>
                                <div className='text-success p-0 pt-2 m-0 fw-bold' style={{ lineHeight: '14px' }}>Success : {Status?.SuccessCount} &nbsp; &nbsp;
                                    <span className='text-danger'>Error : {Status?.ErrorCount}
                                        {Status?.length !== 0 && Status?.ErrorData?.length !== 0 ?
                                            <>&nbsp;<i className="fa fa-download  color-success" style={{ cursor: 'pointer' }} onClick={ErrorData}></i></>
                                            : ""}
                                    </span></div>
                            </>
                            : ""}
                    </div>


                    </div>
                    {/*<div className="col-md-6" style={{ marginTop:"70px" }}>*/}

                    {/*    <p style={{ color: "black", fontWeight: "bold" }}> <span style={{ color: "red" }}> Note:</span>Please Upload Only Excel File Of Format.xlsx. </p>*/}
                    {/*</div>*/}
            </div>
            </div>
            <Table
                Token={TokenData}
                PageName='MovementEntry'
                handleEdit={() => alert('hii')}
                handleDelete={() => alert('hii')}
                handleFilter={() => {
                    getFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                  loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {/* Table Component End*/}

            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>
    )
}

export default BulkMovementEntry