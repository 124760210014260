import React, { useEffect, useState, useRef } from 'react'
import { InputBox, SelectInputBox, FormButtonWithPrint } from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER } from '../../Utils';

//services
import { post } from '../../apiservices/service';
import { getMasterData } from '../../apiservices/CommonServices';
import PickUpOrderData from '../commoncomponents/PickUpOrderData';


function UpdateForwarding() {
    const inputrefs = useRef([]);
    const { user } = useAuth();

    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }

    //initial state
    const [recipe, setRecipe] = useState({ PickupNo: '', AWBNo: '', TrackingNo: '', ForwardingParty: '' })
    const [loading, setLoading] = useState(false)
    const [ForwarderParty, setForwarderParty] = useState([])
    const [dataLoading, setDataLoading] = useState(false)

    useEffect(() => {
        getForwardingParty()
        inputrefs.current['txtPickupNo'].focus();
        document.title = 'Nihao: Updating Forwarding No'
        getFormData()
    }, [])


    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }


    const getForwardingParty = () => {
        setForwarderParty([])
        let temp = {
            Type: "GetForwardingParty",
        }
        setDataLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setForwarderParty([])
                    setDataLoading(false)
                } else {
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setForwarderParty(data)
                    setDataLoading(false)
                }
            }
        });

    }

    const getFormData = (pickno) => {
        
        post(`${API_SERVER}api/Operation/GetUpdateForwardingPartyDetail`, { ...TokenData, ...recipe, PickupNo: pickno }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setRecipe(recipe => ({ ...recipe, bookingDate: '', AWBNo: '', TrackingNo: '', ForwardingParty: '' }))
                    getForwardingParty()
                   // toast.error(res.message)
                } else {
                    let data = res.data
                    getForwardingParty()
                    setRecipe({ ...data, PickupNo: pickno, AWBNo: data.awbNo, TrackingNo: data.trackingNo, ForwardingParty: data.forwardingPartyCode })
                }
            }
        });
    }

    //Submit Handler
    const handleSubmit = () => {
        setLoading(true)
      post(`${API_SERVER}api/Operation/UpdateForwardingParty`, { ...TokenData, ...recipe }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                    inputrefs.current[res.focus].focus();
                } else if (res.status === "SUCCESS") {
                    setLoading(false)
                    toast.success(res.message)
                    handleClear()
                }
                else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message)
                    setLoading(false)
                }
            }
      });
        setLoading(false)
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({ ...recipe, bookingDate: '', PickupNo: '', AWBNo: '', TrackingNo: '', ForwardingParty: '' }))
    }


    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0"> Update Forwarding No</h6>
                </div>
                <div className='card-body row'>
                    {/* Left Section Start*/}
                    <div className='col-md-6 col-lg-6'>
                        <div className='row'>

                            <div className={`col-md-6 col-sm-12 mb-1`} >
                                <label className="form-label" htmlFor='txtPinCode'>Awb No
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    ref={ref => (inputrefs.current['txtPickupNo'] = ref)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtForwardingParty'].focus() };
                                    }}
                                    type='text'
                                    label='Awb No'
                                    id='txtPickupNo'
                                    maxLength='25'
                                    autoComplete='off'
                                    className='form-control'
                                    placeholder="Awb No"
                                    value={recipe.PickupNo}
                                    onBlur={() => getFormData(recipe.PickupNo)}
                                    onChange={(e) => {
                                        setRecipe({ ...recipe, PickupNo: e.target.value })
                                    }}
                                    required
                                />
                            </div>
                            <div className={`col-md-6 col-sm-12 mb-1`}>
                                <label className="form-label" htmlFor='txtArea'>Forwarding Party <span style={{ color: "red" }}>*</span></label>
                                <select
                                    class="form-select"
                                    ref={ref => (inputrefs.current['txtForwardingParty'] = ref)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            inputrefs.current['txtTrackingNo'].focus()
                                        };
                                    }}
                                    id='txtForwardingParty'
                                    
                                    maxLength='50'

                                    value={recipe.ForwardingParty}
                                    onChange={(e) => setRecipe({ ...recipe, ForwardingParty: e.target.value })}
                                >
                                    <option value=''>-Please Select-</option>
                                    {dataLoading ? <option> Loading... </option>
                                        : ForwarderParty?.map((item, index) => {
                                            return (
                                                <option key={index} value={item.code}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>

                            </div>
                            <div className={`col-md-6 col-sm-12 mb-1`} style={{ display: "none" }}>
                                <label className="form-label" htmlFor='txtArea'>AWB No <span style={{ color: "red" }}>*</span></label>
                                <input
                                    ref={ref => (inputrefs.current['txtAwbNo'] = ref)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtTrackingNo'].focus() };
                                    }}
                                    type='text'
                                    label='Awb No'
                                    id='txtAwbNo'
                                    autoComplete='off'
                                    className='form-control'
                                    maxLength='25'
                                    placeholder="Awb No"
                                    value={recipe.AWBNo}
                                    onChange={(e) => HandleChange('AWBNo', e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='row'>
                          

                            <div className={`col-md-6 col-sm-12 mb-1`}>
                                <label className="form-label" htmlFor='txtArea'>Tracking No <span style={{ color: "red" }}>*</span> </label>
                                <input
                                    ref={ref => (inputrefs.current['txtTrackingNo'] = ref)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSubmit()    
                                        };
                                    }}
                                    type='text'
                                    autoComplete='off'
                                    label='Tracking No'
                                    className='form-control'
                                    id='txtTrackingNo'
                                    maxLength='25'
                                    placeholder="Tracking No"
                                    value={recipe.TrackingNo}
                                    onChange={(e) => HandleChange('TrackingNo', e.target.value)}
                                />

                            </div>

                           
                        </div>

                        <div className='row'>
                            <FormButtonWithPrint
                                save={true}
                                print={false}
                                reset={true}
                                handleSubmit={handleSubmit}
                                handleClear={handleClear}
                            />
                        </div>
                    </div>
                    {/* Left Section End*/}

                    {/* Right Section Start*/}
                    <div className='col-md-6 col-lg-6 h-25'>
                        <PickUpOrderData recipe={recipe} />
                    </div>
                    {/* Right Section End*/}

                </div>
             
            </div>
           
            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}

        </>
    )
}

export default UpdateForwarding