import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, SelectInputBox } from "../commoncomponents/InputBox";
import { confirmAlert } from 'react-confirm-alert';

import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';

//services

import { getCommonData } from '../../apiservices/CommonServices';

import { post } from '../../apiservices/service';

function ConsignorConsigneeMaster() {
    const inputrefs = useRef([]);
    const { user } = useAuth();

    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }

    const ConsingorConsigneeTypeArr = [
        { value: 'Consignor', label: 'Consignor' },
        { value: 'Consignee', label: 'Consignee' },
        { value: 'Both', label: 'Both' },
    ]
    //initial state
    const [recipe, setRecipe] = useState({ ConsignorName: '', Type: '', ContactPerson: '', Address: '', Address2: '',
        Address3: '', Zip: '', Client: "", Country: '', State: '', City: '', Email: '', Phone: '', GSTIN: '', Active: true
    })
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [pincodeAllData, setpincodeAllData] = useState([])

    const [countryAllData, setCountryAllData] = useState([])
    const [branchAllData, setBranchAllData] = useState([])
    const [stateAllData, setStateAllData] = useState([])
    const [cityAllData, setCityAllData] = useState([])
    const [pincodePre, setpincodePre] = useState('')
    const [ClientDataArr, setClientDataArr] = useState([])
    const [countryPre, setCountryPre] = useState('')
    const [statePre, setStatePre] = useState('')
    const [CityPre, setCityPre] = useState('')
    const [dataLoading, setDataLoading] = useState(false)



    const PincodeData = () => {
        let temp = {
            Type: "GetPinCode",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setpincodeAllData([])
                    setDataLoading(false)
                } else {
                    let data = res.dataList
                    setpincodeAllData(data)
                    setDataLoading(false)
                }
            }
        });
    }
    //-----------------------------------get All Country Data-----------------------------//
    const CountryData = () => {
        let temp = {
            Type: "GetCountry",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setCountryAllData([])
                    setDataLoading(false)
                } else {
                    let data = res.dataList
                    setCountryAllData(data)
                    setDataLoading(false)
                }
            }
        });
    }

    //-----------------------------get Country Wise State----------------------------------//
    const StateAllData = () => {
        setStateAllData([])
        let temp = {
            Type: "GetCountrywiseState",
            Prefix: '',
            ContextKey: countryPre.split(':')[0].trim(),
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setStateAllData([])
                    setDataLoading(false)
                } else {
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setStateAllData(data)
                    setDataLoading(false)
                }
            }
        });
    }

    //--------------------------get City Data--------------------------------------------//
    const CityAllData = () => {
        setCityAllData([])
        let temp = {
            Type: "GetCountryStateWiseCity",
            Prefix: '',
            ContextKey: countryPre.split(':')[0].trim(),
            ContextKey2: statePre.split(':')[0].trim()
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setCityAllData([])
                    setDataLoading(false)
                } else {
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setCityAllData(data)
                    setDataLoading(false)
                }
            }
        });
    }
    //------------------------To Get Client Data----------------------//
    //const getClientData = () => {
    //    let temp = {
    //        Type: "GetClient",
    //        Prefix: '',
    //        ContextKey: "",
    //        ContextKey2: ""
    //    }
    //    setDataLoading(true)
    //    getCommonData({ ...TokenData, ...temp }, (res) => {
    //        if (res && res.status) {
    //            if (res.status === "ERROR") {
    //                setClientDataArr([])
    //                setDataLoading(false)
    //            } else {
    //                let data = res.dataList
    //                setClientDataArr(data)
    //                setDataLoading(false)
    //            }
    //        }
    //    });
    //}

    //------------------------------------get Table Data---------------------------------------//
    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/MASTER/ShowConsignorConsigneeMaster`, { ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }


    const handleChange1 = (event, value) => {
        getFormData(value);
    };


    useEffect(() => {
        inputrefs.current['txtType'].focus()
        document.title = 'Nihao: Consignor/Consignee Master';
        getFormData()
    }, [sortConfig])

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //validation handler
   

    //Submit Handler
    const handleSubmit = () => {
   
            setLoading(true)
            post(`${API_SERVER}api/MASTER/InsertUpdateCCMaster`, { ...TokenData, ...recipe }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        if (res.focus === 'txtClient'|| res.focus === 'txtBranchName' || res.focus === 'txtCountry' || res.focus === 'txtState' || res.focus === 'txtCity' || res.focus === 'txtPinCode') {
                            inputrefs.current[res.focus].children[0].children[0].focus()
                        }else inputrefs.current[res.focus].focus();
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.status === "SUCCESS") {
                        setLoading(false)
                        toast.success(res.message)
                        getFormData()
                        handleClear()
                    }
                    else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message)
                        setLoading(false)
                    }
                }
            });
        setLoading(false)
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/MASTER/EditConsignorConsigneeMaster`, { ...TokenData, CCID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                } else if (res.status === "SUCCESS") {
                    setLoading(false)
                    let data = res.data
                    setCountryPre(data.country)
                    setStatePre(data.state)
                    setRecipe({
                        ...data, ConsignorName: data.consignorName, Type: data.type,
                        ContactPerson: data.contactPerson, Address: data.address, Address2: data.address2, Address3: data.address3, Country: data.country,
                        State: data.state,  City: data.city, Zip: data.zip, Email: data.email, Phone: data.phone, GSTIN: data.gstin, Active: data.active
                    })
                   
                }
                else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message)
                    setLoading(false)
                }
            }
        });
        setLoading(false)
    }

    //Handle Delete
    const handleDelete = (Id) => {
        confirmAlert({
            message: 'Do you want to Delete this Record ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setLoading(true)
                        await post(`${API_SERVER}api/MASTER/DeletConsignorConsigneeMaster`, { ...TokenData, CCID: Id }, (res) => {
                            if (res && res.status) {
                                if (res.status === "ERROR") {
                                    toast.error(res.message)
                                    setLoading(false)
                                } else if (res.status === "SUCCESS") {
                                    toast.success(res.message)
                                    setLoading(false)
                                    getFormData()
                                    handleClear()
                                }
                                else if (res.status === "UNAUTHORIZED") {
                                    toast.error(res.message)
                                    setLoading(false)
                                }
                            }
                        });
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
        setLoading(false)
    }
    const getCityStateByPincode = (Zip) => {
        //setLoading(true)
        post(`${API_SERVER}api/mASTER/GetPinCodeData`, { ...TokenData,Zip }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    //setLoading(false)
                    setRecipe({
                        ...recipe,
                        Zip: "",
                        Country: "",
                        State: "",
                        City: "",


                    })
                } else {
                    let data = res.data
                    setpincodePre(data.zip)
                    setCountryPre(data.country)
                    setStatePre(data.state)
                    setRecipe({
                        ...recipe,
                        Zip: data.zip,
                        Country: data.country,
                        State: data.state,
                        City: data.city,

                    })
                    //setLoading(false)
                }
            }
        });
    }
    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe, CCID:"", ConsignorName: '', Type: '', ContactPerson: '', Address: '', Address2: '',
            Address3: '', Client: "", Country: '', State: '', City: '', Email: '', Phone: '', Zip: '', PinCode: '', GSTIN: '', Active: true
        }))
        filterData.SearchText = ""
        getFormData()
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };
    let pinCodeData = []
    for (let i = 0; i < pincodeAllData.length; i++) {
        let mydata = pincodeAllData[i]?.values
        pinCodeData.push(mydata)
    };

    let countryData = []
    for (let i = 0; i < countryAllData.length; i++) {
        let mydata = countryAllData[i]?.values
        countryData.push(mydata)
    };
    let BranchData = []
    for (let i = 0; i < branchAllData.length; i++) {
        let mydata = branchAllData[i]?.values
        BranchData.push(mydata)
    }
    let stateData = []
    for (let i = 0; i < stateAllData.length; i++) {
        let mydata = stateAllData[i]?.values
        stateData.push(mydata)
    };
    let cityData = []
    for (let i = 0; i < cityAllData.length; i++) {
        let mydata = cityAllData[i]?.values
        cityData.push(mydata)
    }
  
    //let clientData = []
    //for (let i = 0; i < ClientDataArr.length; i++) {
    //    let mydata = ClientDataArr[i]?.values
    //    clientData.push(mydata)
    //};
   

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    return (

        <>

            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">CC Master</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>

                        

                        <SelectInputBox
                            label=' Type'
                            inputrefs={inputrefs}
                            id='txtType'
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtConsignorName'].focus() };
                            }}
                            required={true}
                            maxLength='50'
                            value={recipe.Type}
                            onChange={(e) => HandleChange('Type', e.target.value)}
                        >
                            <option value=''>-Please Select-</option>
                            {
                                ConsingorConsigneeTypeArr?.map((item, index) => {
                                    return (
                                        <option key={index} value={item.value}>{item.label}</option>
                                    )
                                })
                            }
                         
                        </SelectInputBox>
                        {/*<AutoCompleteBox*/}
                        {/*    loading={dataLoading}*/}
                        {/*    inputrefs={inputrefs}*/}
                        {/*    onKeyDown={(e) => {*/}
                        {/*        if (e.key === 'Enter') { inputrefs.current['txtConsignorName'].focus() };*/}
                        {/*    }}*/}
                        {/*    label='Client'*/}
                        {/*    placeholder="Client"*/}
                        {/*    maxLength='50'*/}
                        {/*    options={clientData}*/}
                        {/*    id='txtClient'*/}
                        {/*    required={true}*/}
                        {/*    onFocus={() => getClientData()}*/}
                        {/*    inputValue={recipe.Client ? recipe.Client : ''}*/}
                        {/*    error={errors.Client}*/}
                        {/*    onInputChange={(event, newInputValue) => {*/}
                        {/*        setRecipe({ ...recipe, Client: newInputValue })*/}
                        {/*    }}*/}
                        {/*/>*/}

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtContactPerson'].focus() };
                            }}
                            label='Consignor/Consignee Name'
                            id='txtConsignorName'
                            maxLength='50'
                            placeholder="Consignor Name"
                            value={recipe.ConsignorName}

                            onChange={(e) => HandleChange('ConsignorName', e.target.value)}
                            required
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtAddress'].focus()
                                }
                            }}
                            label='Contact Person'
                            id='txtContactPerson'
                            maxLength='50'
                            placeholder="Contact Person"
                            value={recipe.ContactPerson}
                            error={errors.ContactPerson}
                            onChange={(e) => HandleChange('ContactPerson', e.target.value)}
                            
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtAddress1'].focus()
                                }
                            }}
                            label='Address1'
                            id='txtAddress'
                            maxLength='255'
                            placeholder="Address1"
                            value={recipe.Address}
                            required={true }
                            onChange={(e) => HandleChange('Address', e.target.value)}

                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtAddress2'].focus()
                                }
                            }}
                            label='Address 2'
                            id='txtAddress1'
                            maxLength='100'
                            placeholder="Address2"
                            value={recipe.Address2}

                            onChange={(e) => HandleChange('Address2', e.target.value)}

                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtPinCode'].children[0].children[0].focus()
                                }
                            }}
                            label='Address 3'
                            id='txtAddress2'
                            maxLength='100'
                            placeholder="Address 3"
                            value={recipe.Address3}

                            onChange={(e) => HandleChange('Address3', e.target.value)}

                        />
                        <AutoCompleteBox
                            loading={dataLoading}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtCountry'].children[0].children[0].focus() };
                            }}
                            label='Pin Code'
                            placeholder="Pin Code"
                            maxLength='15'
                            options={pinCodeData}
                            id='txtPinCode'
                            required={true}

                            onFocus={() => PincodeData()
                                
                            }
                            onBlur={() => getCityStateByPincode(recipe.Zip)}
                            inputValue={recipe.Zip ? recipe.Zip : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('Zip', newInputValue)
                                setRecipe(recipe => ({ ...recipe, Country: '', State: '', City: '' }))
                                setpincodePre(newInputValue)
                               
                            }}
                        />
                        <AutoCompleteBox
                            loading={dataLoading}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtState'].children[0].children[0].focus() };
                            }}
                            label='Country Name'
                            placeholder="Country Name"
                            maxLength='100'
                            options={countryData}
                            id='txtCountry'
                            required={true}
                            error={errors.country}
                            onFocus={() => CountryData()}

                            inputValue={recipe.Country ? recipe.Country : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('Country', newInputValue)
                                setRecipe(recipe => ({ ...recipe, State: '', City: '' }))
                                setCountryPre(newInputValue)
                            }}
                        />

                        <AutoCompleteBox
                            loading={dataLoading}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtCity'].children[0].children[0].focus() };
                            }}
                            label='State Name'
                            placeholder="State Name"
                            maxLength='100'
                            options={stateData}
                            id='txtState'
                            onFocus={() => StateAllData()}
                            inputValue={recipe.State ? recipe.State : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('State', newInputValue)
                                setRecipe(recipe => ({ ...recipe, City: '' }))
                                
                                setStatePre(newInputValue)
                            }}
                        />

                        <AutoCompleteBox
                            loading={dataLoading}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtEmail'].focus() };
                            }}
                            label='City'
                            placeholder="City"
                            maxLength='100'
                            options={cityData}
                            id='txtCity'
                            onFocus={() => CityAllData()}
                            error={errors.City}
                            inputValue={recipe.City ? recipe.City : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('City', newInputValue)
                                setCityPre(newInputValue)
                            }}
                        />
                       
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtPhone'].focus()
                                }
                            }}
                            label='Email'
                            id='txtEmail'
                            maxLength='100'
                            placeholder="Email"
                            value={recipe.Email}
                          
                            onChange={(e) => HandleChange('Email', e.target.value)}

                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtGSTIN'].focus()
                                }
                            }}
                            label='Mobile Number'
                            id='txtPhone'
                            maxLength='15'
                            placeholder="Mobile Number"
                            value={recipe.Phone}
                           
                            onChange={(e) => HandleChange('Phone', e.target.value.replace(/[^0-9+\s-]/g, "").replace(/(\+.*\+)/g, "+").replace(/-+/g, "-").replace(/\s+/g, " "))}

                        />

                       

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtIsActive'].focus()
                                }
                            }}
                            label='GSTIN'
                            id='txtGSTIN'
                            maxLength='15'
                            placeholder="GSTIN"
                            value={recipe.GSTIN}

                            onChange={(e) => HandleChange('GSTIN', e.target.value)}

                        />

                        <Switch
                            inputrefs={inputrefs}
                            label='Active'
                            checked={recipe.Active}
                            id='txtIsActive'
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSubmit()
                                }
                            }}
                            onChange={(e) => HandleChange('Active', e.target.checked)}
                        />

                        <FormButton
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/MASTER/ShowConsignorConsigneeMaster', ExportRequest, 'ConsignorConsignee Master')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>

            {/* Table Component start*/}
            <Table
                Token={TokenData}
                PageName='ConsignorConsigneeMaster'
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleFilter={() => {
                    getFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                //  loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {/* Table Component End*/}


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}

        </>
    )
}

export default ConsignorConsigneeMaster
