
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { FileUploader } from "react-drag-drop-files";


export const DownloadFileAtURL = (File) => {
    let FileName = `/${File}`
    const fileName = FileName.split('/').pop()
    const aTag = document.createElement('a');
    aTag.href = FileName
    aTag.setAttribute('download', fileName)
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
}

export const ERRORDATALIST = (Status, FileName) => {
    const workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.aoa_to_sheet([
        (Object.keys(Status.ErrorData[0]))
        ,
        ...Status?.ErrorData?.map((records) =>
            Object.keys(Status.ErrorData[0])?.map((data, i) =>
                records[data]
            )
        ),
    ]);
    XLSX.utils.book_append_sheet(workbook, worksheet, FileName);
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${FileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

}

export const readFile = async (e) => {
    const file = e
    let FileName = (e.name);
    //let ext = extArr[1];
    //let name = extArr[0];
    const data = await file.arrayBuffer(file)
    const excelFile = XLSX.read(data)
    const excelSheet = excelFile.Sheets[excelFile.SheetNames[0]]
    const exceljson = XLSX.utils.sheet_to_json(excelSheet)
    return { exceljson, FileName }
}

export const HandleBulkInsertData = async (url, Records, data) => {
    let SuccessCount = 0
    let ErrorData = []
    let ErrorCount = 0
    if (Records.length !== 0) {
        console.log(Records,"Records,Records")
        for (let i = 0; i < Records.length; i++) {
            let stringifiedRecord = {};
            for (const key in Records[i]) {
                if (Object.hasOwnProperty.call(Records[i], key)) {
                    stringifiedRecord[key] = Records[i][key].toString();
                }
            }
            let response = { ...data, ...stringifiedRecord };
            await fetch(url, {
                method: 'POST',
                body: JSON.stringify(response),
                headers: {
                    "Content-Type": "application/json"
                },
                // eslint-disable-next-line no-loop-func
            }).then(async (resp) => {
                let res = await resp.json();
                if (res.status === "SUCCESS") {
                    SuccessCount = SuccessCount + 1
                } else if (res.status === 'ERROR') {
                    ErrorData.push({ ...Records[i], ERROR: res.message })
                    console.log(ErrorData,'tttttttttttt')
                    ErrorCount = ErrorCount + 1
                }
            }
            )
        }
    } else {
        alert('No Record Found !')
    }
  
    return { SuccessCount, ErrorCount, ErrorData }
}
export const BulkClearData = () => {
    let a = document.querySelectorAll('.jRuVVs');
    if (a.length > 0) {
        a[0].innerHTML = 'Upload  or drop a file right here'
        a[0].innerHTML = a[0].innerHTML.replace('Upload', '<span className="text-decoration-underline">Upload</span>');
    }
}
//export const HandleBulkInsertData = async (url, Records, data, refreshData) => {
//    let SuccessCount = 0;
//    let ErrorData = [];
//    let ErrorCount = 0;

//    if (Records.length !== 0) {
//        for (let i = 0; i < Records.length; i++) {
//            // Convert numeric data to strings
//            let stringifiedRecord = {};
//            for (const key in Records[i]) {
//                if (Object.hasOwnProperty.call(Records[i], key)) {
//                    stringifiedRecord[key] = Records[i][key].toString();
//                }
//            }

//            let response = { ...data, ...stringifiedRecord };

//            await fetch(url, {
//                method: 'POST',
//                body: JSON.stringify(response),
//                headers: {
//                    'Content-Type': 'application/json',
//                },
//            }).then(async (resp) => {
//                let res = await resp.json();
//                if (res.status === 'SUCCESS') {
//                    SuccessCount = SuccessCount + 1;
//                } else if (res.status === 'ERROR') {
//                    ErrorData.push({ ...Records[i], ERROR: res.message });
//                    ErrorCount = ErrorCount + 1;
//                }
//            });
//        }
//    } else {
//        alert('No Record Found !');
//    }

//    refreshData();
//    return { SuccessCount, ErrorCount, ErrorData };
//};
//export const HandleBulkInsertData = async (url, Records, data, refreshData) => {
//    let SuccessCount = 0;
//    let ErrorData = [];
//    let ErrorCount = 0;

//    if (Records.length !== 0) {
//        for (let i = 0; i < Records.length; i++) {
//            // Convert numeric data to strings
//            let stringifiedRecord = {};
//            for (const key in Records[i]) {
//                if (Object.hasOwnProperty.call(Records[i], key)) {
//                    if (key === 'StatusDate' || key === 'StatusTime') {
//                        stringifiedRecord[key] = formatDateTime(Records[i][key]);
//                    } else {
//                        stringifiedRecord[key] = Records[i][key].toString();
//                    }
//                }
//            }

//            let response = { ...data, ...stringifiedRecord };

//            await fetch(url, {
//                method: 'POST',
//                body: JSON.stringify(response),
//                headers: {
//                    'Content-Type': 'application/json',
//                },
//            }).then(async (resp) => {
//                let res = await resp.json();
//                if (res.status === 'SUCCESS') {
//                    SuccessCount = SuccessCount + 1;
//                } else if (res.status === 'ERROR') {
//                    ErrorData.push({ ...Records[i], ERROR: res.message });
//                    ErrorCount = ErrorCount + 1;
//                }
//            });
//        }
//    } else {
//        alert('No Record Found !');
//    }

//    refreshData();
//    return { SuccessCount, ErrorCount, ErrorData };
//};



const formatDateTime = (dateTimeString) => {
    const originalDate = new Date(dateTimeString);
    if (isNaN(originalDate.getTime())) {
        console.error('Invalid date format:', dateTimeString);
        return dateTimeString;
    }

    // Format date as "YYYY-MM-DD" (adjust format as needed)
    const formattedDate = `${originalDate.getFullYear()}-${padZero(originalDate.getMonth() + 1)}-${padZero(originalDate.getDate())}`;

    return formattedDate;
};

// Helper function to pad a number with leading zeros if needed
const padZero = (num) => {
    return num.toString().padStart(2, '0');
};


export const HandleBulkInsertDataJSON = async (url, Records, common, refreshData) => {

    let ErrorData = []

    if (Records.length !== 0) {

        let response = ({ ...common, data: Records })

        await fetch(url, {
            method: 'POST',
            body: JSON.stringify(response),
            headers: {
                "Content-Type": "application/json"
            },
            // eslint-disable-next-line no-loop-func
        }).then(async (resp) => {
            let res = await resp.json();
            if (res.status === "SUCCESS") {
            } else if (res.status === 'ERROR') {
                ErrorData.push({ ERROR: res.message })

            }
        }
        )

    } else {
        alert('No Record Found !')
    }
    refreshData()
    return { ErrorData }
}

//export const handleDownloadExcel = async (handleApiUrl, requestData, FileName) => {
//    const apiUrl = handleApiUrl;
//    const requestBody = requestData
//    const requestHeaders = {
//        'Content-Type': 'application/json',
//    };
//    try {
//        const response = await fetch(apiUrl, {
//            method: 'POST',
//            body: JSON.stringify(requestBody),
//            headers: requestHeaders,
//        });
//        const res = await response.json();
//        if (res.Status === "SUCCESS") {
//            const workbook = XLSX.utils.book_new();
//            let worksheet = XLSX.utils.aoa_to_sheet([
//                res?.HeadersValue
//                ,
//                ...res?.Data?.map((records) =>
//                    res.HeadersKey?.map((data, i) =>
//                        records[data]
//                    )
//                ),
//            ]);
//            XLSX.utils.book_append_sheet(workbook, worksheet, FileName);
//            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
//            const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
//            const url = window.URL.createObjectURL(data);
//            const link = document.createElement('a');
//            link.href = url;
//            link.setAttribute('download', `${FileName}.xlsx`);
//            document.body.appendChild(link);
//            link.click();
//            document.body.removeChild(link);
//            window.URL.revokeObjectURL(url);
//            return
//        } else if (res.Status === 'ERROR') {
//            NotificationSound(res.Status, res.Message)
//        }
//    } catch (err) {
//        toast.error(err.message, {
//            position: 'top-center',
//        });
//    }


//};


