import React, { useEffect, useState, useRef } from 'react'
import { InputBox, DateBox,  FormButtonWithPrint } from "../commoncomponents/InputBox";

import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';

//loder
import BlurLoader from "../Loder/BlurLoader";

//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';

//services
import { post } from '../../apiservices/service';

function UserLogReport() {

    const inputrefs = useRef([]);
    const { user } = useAuth();

    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }

    const [recipe, setRecipe] = useState({ FromDate: '', ToDate: '',LogType:'',FormName:'', AWBNo: '' })
    const [result, setResult] = useState({})
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [IsUpdate, setIsUpdate] = useState(false)

    const getUserLogReportFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        // setLoading(true)

        let temp = {
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            PageIndex: PageIndex.toString(),
            Type: "s"
        }
        post(`${API_SERVER}api/Report/UserLogReport`, { ...TokenData, ...temp, ...sortConfig, ...filterData, ...recipe }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }


    useEffect(() => {
        document.title = 'Nihao: KYC Details'
        if (IsUpdate) {
            setIsUpdate(false)
            getUserLogReportFormData()
        }

    }, [IsUpdate])

    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    const handleChange1 = (event, value) => {
        getUserLogReportFormData(value);
    };

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
        getUserLogReportFormData()
    };


    //Handle Submit
    const handleSubmit = () => {
        getUserLogReportFormData()
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({ ...recipe, FromDate: '', ToDate: '', LogType: '', FormName: '', AWBNo: '' }))
        setIsUpdate(true)
    }

    //Start Export functionality
    let ExportRequest = {
        ...recipe,
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">User Log Report</h6>
                </div>

                <div className='card-body'>
                    <div className="row">

                        <DateBox
                            id='txtFromDate'
                            inputrefs={inputrefs}
                            label='From Date'
                            placeholder="From Date"
                            required={false}
                            selected={recipe.FromDate === '' ? '' : new Date(recipe.FromDate)}
                            onChange={(e) => {
                                let dateArr = e?.toString()?.split(' ')
                                dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                setRecipe({ ...recipe, FromDate: dateArr })
                            }}
                            maxDate={new Date()}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtToDate'].input.focus() };
                            }}
                        />

                        <DateBox
                            id='txtToDate'
                            inputrefs={inputrefs}
                            label='To Date'
                            placeholder="To Date"
                            required={false}
                            selected={recipe.ToDate === '' ? '' : new Date(recipe.ToDate)}
                            onChange={(e) => {
                                let dateArr = e?.toString()?.split(' ')
                                dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                setRecipe({ ...recipe, ToDate: dateArr })
                            }}
                            maxDate={new Date()}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtAWBNo'].focus() };
                            }}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDocumentType'].focus() };
                            }}
                            label='Log Type'
                            id='txtLogType'
                            maxLength='25'
                            placeholder="Log Type"
                            value={recipe.LogType}
                            onChange={(e) => HandleChange('LogType', e.target.value)}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDocumentType'].focus() };
                            }}
                            label='Form Name'
                            id='txtFormName'
                            maxLength='25'
                            placeholder="Form Name "
                            value={recipe.FormName}
                            onChange={(e) => HandleChange('FormName', e.target.value)}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtDocumentType'].focus() };
                            }}
                            label='AWB No'
                            id='txtAWBNo'
                            maxLength='25'
                            placeholder="AWB No "
                            value={recipe.AWBNo}
                            onChange={(e) => HandleChange('AWBNo', e.target.value)}
                        />

                        <FormButtonWithPrint
                            save={true}
                            Export={true}
                            reset={true}
                            buttonName='Search'
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/Report/UserLogReport', ExportRequest, 'UserLog Report')}
                            handleClear={handleClear}
                        />

                    </div>

                </div>
            </div>

            {/* Table Component start*/}
            <Table
                Filter={false}
                Token={TokenData}
                PageName='UserLogReport'
              
                tableData={result}
                handleFilter={() => {
                }}
                //  loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {/* Table Component End*/}


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}

        </>
    )

}
export default UserLogReport
