import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, FormButtonWithPrint } from "../commoncomponents/InputBox";

import { useAuth } from '../../context/auth';


//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER } from '../../Utils';

//services

import { post } from '../../apiservices/service';
import PickUpOrderData from '../commoncomponents/PickUpOrderData';

function LabelPrint() {
    const inputrefs = useRef([]);
    const { user } = useAuth();
    const [isAwbNoDisabled, setIsAwbNoDisabled] = useState(false);

    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }

    //initial state
    const [recipe, setRecipe] = useState({ OrderNo:'',AwbNo:'' })
    const [loading, setLoading] = useState(false)
    const [label, setLabel] = useState('')

    useEffect(() => {
        inputrefs.current['txtOrderNo'].focus();
        document.title = 'Nihao: Label Print' 
    }, [])

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    const getLabelPrint = () => {
        setLoading(true)
        setLabel('')
        
        
        post(`${API_SERVER}api/Print/PrintAwb`, { ...TokenData, ...recipe }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    setLoading(false)
                    if (res.focus)inputrefs.current[res.focus].focus()
                    setLabel('')
                } else {
                    setLoading(false)
                    setLabel(res.base64PDF)
                    setIsAwbNoDisabled(true);
                }
            }
           
        });
        setLoading(false)
    }
  
    //Submit Handler
    const handleSubmit = () => {   
        getLabelPrint()
    }

    const getFormData = (pickno) => {

        post(`${API_SERVER}api/Operation/GetUpdateForwardingPartyDetail`, { ...TokenData, ...recipe, PickupNo: pickno }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setRecipe(recipe => ({ ...recipe, bookingDate: '', AwbNo: '', TrackingNo: '', ForwardingParty: '', ForwardingService: '', ForwardingWeight: '' }))
                  //  toast.error(res.message)
                } else {
                    let data = res.data
                    setRecipe({ ...data, OrderNo: pickno, AwbNo: data.awbNo, TrackingNo: data.trackingNo, ForwardingParty: data.forwardingPartyCode, ForwardingService: data.forwardingService, ForwardingWeight: data.forwardingWeight })
                   
                }
            }
        });
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({ ...recipe, OrderNo: '', AwbNo: '', bookingDate: '' }))
        inputrefs.current['txtOrderNo'].focus();
        setIsAwbNoDisabled(false);
        setLabel('')
    }

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0"> Label Print</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-6 col-lg-6 h-25'>
                            <div className='row'>
                                <div className='col-md-6 col-lg-6 h-25'>
                                    <InputBox
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                inputrefs.current['txtAwbNo'].focus()
                                                getLabelPrint()
                                            };
                                        }}
                                        label='Awb No'
                                        id='txtOrderNo'
                                        maxLength='12'
                                        placeholder="Awb No"
                                        ignore_common={true}
                                        value={recipe.OrderNo}
                                        autoComplete='off'
                                        onBlur={() => {
                                            getFormData(recipe.OrderNo)
                                        }}
                                        onChange={(e) => HandleChange('OrderNo', e.target.value)}
                                        required
                                    />
                                </div>

                                <div className='col-md-6 col-lg-6 h-25' style={{ display: "none" }}>
                                    <InputBox
                                      
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleSubmit()
                                            };
                                        }}
                                        label='Awb No'
                                        id='txtAwbNo'
                                        maxLength='25'
                                        ignore_common={true}
                                        placeholder="Awb No"
                                        value={recipe.AwbNo}
                                        onChange={(e) => HandleChange('AwbNo', e.target.value)}
                                        required={false}
                                        disabled={isAwbNoDisabled}
                                    />
                                </div>
                            </div>

                            <FormButtonWithPrint
                                save={true}
                                print={false}
                                reset={true}
                                buttonName='Get'
                                handleSubmit={handleSubmit}
                                handleClear={handleClear}
                            />

                            
                       </div>

                        <div className='col-md-6 col-lg-6 ' style={{ maxHeight: "200px", overflow: "hidden" }}>
                            <PickUpOrderData recipe={recipe} />
                        </div>
                    </div>
                </div>
            </div>

            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}

            {label &&
                <div className='card-body mt-2 p-3'>
                    <embed src={`data:application/pdf;base64,${label}`} width='100%' height='400px' />
                </div>}
        </>
    )
}

export default LabelPrint