import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';


//react toast 
import { toast } from 'react-toastify';

//custom images
import sipllogo from '../../assests/images/sipllogo.png';
import nihaologo from '../../assests/images/nihaologo.png'
import loginpage from '../../assests/images/loginpage.jpg';

//loder
import BlurLoader from "../Loder/BlurLoader";

//services
import { post } from "../../apiservices/service";
import { API_SERVER } from "../../Utils";

const ForgotPasswordSlide2 = () => {
    const navigate = useNavigate();
    const inputrefs = useRef([]);
    let url = window.location.search;
    const queryString = new URLSearchParams(url);
    const UserCode = queryString.get('UserCode');
    const Token = queryString.get('Token');

    //initial state
    const [recipe, setRecipe] = useState({ NewPassword: '', ConfirmPassword:'' })
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        inputrefs.current['txtNewPassword'].focus();
        document.title = 'Nihao: Forgot Password'
    }, [])

    //Recipe Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //validation handler
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "NewPassword", msg: 'Please Enter New Password.' },
            { field: "ConfirmPassword", msg: 'Please Enter Confirm Password.' }
        ]

        
        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        if (recipe.NewPassword && recipe.ConfirmPassword) {
            if (recipe.ConfirmPassword !== recipe.NewPassword) {
                setErrors(errors => ({ ...errors, confirm_password_match: 'Confirm Password Not Match' }));
                isValid = false;
            }
         }
        return isValid;
    }

    //submit button Handler
    const HandleSubmit = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/UserLogin/UpdateForgotPassword`, { ...recipe, UserCode, Token, AuthToken: "der324k343%@923041!" }, (res) => {
                // console.log(res);
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setRecipe(recipe => ({ ...recipe, NewPassword: '', ConfirmPassword:'' }))
                        setLoading(false)
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        navigate('/')
                    }
                }
            });
        }

        }


    return (
        <>
            {/* Forgot Password Form Start */}
            <div className='login-page login-bg'>
                <div className="container-fluid">
                    <div className='row justify-content-center' style={{ height: '100vh' }}>

                        <div className='col-lg-8 col-md-7 col-xl-8 p-0' >
                            <div className="d-flex align-items-center justify-content-center log_height" >
                                <img className="login-image" src={loginpage} alt='' />
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-5 col-xl-4 bg-white align-items-center justify-content-between d-flex flex-column'>

                            <div className='login-content'>
                                <div className='text-center'><img className='mb-2 sampark-logo' src={nihaologo} alt='Nihao logo' /></div>
                                <h5 className='fs-5 text-center'>Forgot <span style={{ color: '#fdb813' }}>Password</span></h5>

                                <div className="form-group text-start" >
                                    <label className="form-label mb-1">New Password <span className='required'>*</span></label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="txtNewPassword"
                                        ref={ref => (inputrefs.current['txtNewPassword'] = ref)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtconfirmPassword'].focus() };
                                        }}
                                        placeholder="New Password"
                                        name='New Password'
                                        value={recipe.NewPassword}
                                        onChange={(e) => HandleChange('NewPassword', e.target.value)}
                                    />

                                    {errors.NewPassword && <div className="error-msg">{errors.NewPassword}</div>}

                                </div>

                                <div className="form-group text-start mb-3" >
                                    <label className="form-label mb-1">Confirm Password <span className='required'>*</span></label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="txtconfirmPassword"
                                        ref={ref => (inputrefs.current['txtconfirmPassword'] = ref)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['btnSubmit'].focus() };
                                        }}
                                        placeholder="Confirm Password"
                                        name='Confirm Password'
                                        value={recipe.ConfirmPassword}
                                        onChange={(e) => HandleChange('ConfirmPassword', e.target.value)}
                                    />

                                    {errors.ConfirmPassword && <div className="error-msg">{errors.ConfirmPassword}</div>}
                                    {errors.confirm_password_match && <div className="error-msg">{errors.confirm_password_match}</div>}
                                </div>

                                <button
                                    ref={ref => (inputrefs.current['btnSubmit'] = ref)}
                                    className='btn-primary btn w-100'
                                    onClick={HandleSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                            <div className='sagar_logo text-center d-flex flex-column mb-5'>
                                <img src={sipllogo} alt='sagar logo' />
                                <Link to="https://www.sagarinfotech.com/" className='small' target='_blank'>Sagar Informatics Pvt. Ltd.</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Forgot Password Form End */}

            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>

    )
}

export default ForgotPasswordSlide2;