import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch, AutoCompleteBox, SelectInputBox } from "../commoncomponents/InputBox";
import { confirmAlert } from 'react-confirm-alert';


import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';

//services
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';

function ClientMaster() {
    const inputrefs = useRef([]);
    const { user } = useAuth();

    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }

    //initial state
    const [recipe, setRecipe] = useState({
        ClientID: '',
        CCode: 'System Generated',
        ClientName: '',
        ContactPerson: "",
        Address1: "",
        Address2: "",
        Zip: "",
        Country: "",
        State: "",
        City: "",
        Phone: "",
        Email: "",
        PanNo: "",
        AadhaarNo: "",
        GSTIN: "",
        Remarks: "",
        Currency:"",
        Active: true
    })
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [isUpdate, setIsUpdate] = useState(false)
    const [pinCodePre, setPinCodepre] = useState('')
    const [countryPre, setCountryPre] = useState('')
    const [statePre, setStatePre] = useState('')
    const [PinCodeAllData, setPinCodeAllData] = useState([])

    const [countryAllData, setCountryAllData] = useState([])
    const [stateAllData, setStateAllData] = useState([])
    const [cityAllData, setCityAllData] = useState([])
    const [CurrencyMasterArr, setCurrencyMasterArr] = useState([])
    const [dataLoading, setDataLoading] = useState(false)

    //----------------get country data-----------------------//
    const PinCodeData = () => {
        let temp = {
            Type: "GetPinCode",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setDataLoading(false)
                } else {
                    let data = res.dataList
                    setPinCodeAllData(data)
                    setDataLoading(false)
                }
            }
        });
    }

    //----------------get country data-----------------------//
    const CountryData = () => {
        let temp = {
            Type: "GetCountry",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setDataLoading(false)
                } else {
                    let data = res.dataList
                    setCountryAllData(data)
                    setDataLoading(false)
                }
            }
        });
    }

    //-----------------get Country wise state--------------------------//
    const StateAllData = () => {
        setStateAllData([])
        let temp = {
            Type: "GetCountrywiseState",
            Prefix: '',
            ContextKey: countryPre.split(':')[0].trim(),
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setStateAllData([])
                    setDataLoading(false)
                } else {
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setStateAllData(data)
                    setDataLoading(false)
                }
            }
        });
    }

    //-----------------------get consignee all city data--------------------------//
    const CityAllData = () => {
        let temp = {
            Type: "GetCountryStateWiseCity",
            Prefix: '',
            ContextKey: countryPre.split(':')[0].trim() ,
            ContextKey2: statePre.split(':')[0].trim()
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setCityAllData([])
                    setDataLoading(false)
                } else {
                    let data = res.dataList
                    setCityAllData(data)
                    setDataLoading(false)
                }
            }
        });
    }


    //------------------------get Currency Data-------------------------------//
    const getCurrencyData = () => {
        setCurrencyMasterArr([])
        let temp = {
            Type: "GetCurrencyMaster",
        }
        setDataLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setCurrencyMasterArr([])
                    setDataLoading(false)
                } else {
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setCurrencyMasterArr(data)
                    setDataLoading(false)
                }
            }
        });
    }

    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            PageIndex: PageIndex.toString(),
            Type: "s"
        }
        post(`${API_SERVER}api/Client/ShowClientMaster`, { ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                    setFilterData({ ...filterData,  })
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        },[filterData]);
    }

    const handleChange1 = (event, value) => {
        getFormData(value);
    };

    useEffect(() => {
        inputrefs.current['txtClientCode'].focus()
        document.title = 'Nihao: Client Master'
        getFormData()
    }, [sortConfig, isUpdate])

    useEffect(() => {
        getCurrencyData()
    },[])

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }
    const getCityStateByPincode = (Zip) => {
        //setLoading(true)
        post(`${API_SERVER}api/mASTER/GetPinCodeData`, { ...TokenData, Zip }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    //setLoading(false)
                    setRecipe({
                        ...recipe,
                        Zip: Zip,
                        Country: "",
                        State: "",
                        City: "",
                    
                    })
                } else {
                    let data = res.data
                    setPinCodepre(data.zip)
                    setCountryPre(data.country)
                    setStatePre(data.state)
                    setRecipe({
                        ...recipe,
                        Zip: data.zip,
                        Country: data.country,
                        State: data.state,
                        City: data.city,

                    })
                    //setLoading(false)
                }
            }
        });
    }
    //Submit Handler
    const handleSubmit = () => {
            setLoading(true)
        post(`${API_SERVER}api/Client/InsertUpdateClientMaster`, { ...TokenData, ...recipe }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        if (res.focus === 'txtCountry' || res.focus === 'txtState' || res.focus === 'txtCity' || res.focus ==='txtPinCode') {
                            inputrefs.current[res.focus].children[0].children[0].focus()
                        } else {
                            inputrefs.current[res.focus].focus()
                        }
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.status === "SUCCESS") {
                        setLoading(false)
                        toast.success(res.message)
                        getFormData()
                        handleClear()
                    }
                    else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message)
                        setLoading(false)
                    }
                }
        });
        setLoading(false)
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/Client/EditClientMaster`, { ...TokenData, ClientID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    if (res.focus === 'txtCountry' || res.focus === 'txtState' || res.focus === 'txtCity') {
                        inputrefs.current[res.focus].children[0].children[0].focus()
                    } else {
                        inputrefs.current[res.focus].focus()
                    }
                    toast.error(res.message)
                    setLoading(false)
                    setCountryPre('')
                    setStatePre('')
                } else if (res.status === "SUCCESS") {
                    setLoading(false)
                    let data = res.data
                    setCountryPre(data.country)
                    setStatePre(data.state)
                    getCurrencyData()
                    setRecipe({
                        ...data,
                        CCode: data.cCode,
                        ClientName: data.clientName,
                        ContactPerson: data.contactPerson,
                        Address1: data.address1,
                        Address2: data.address2,
                        Zip: data.zip,
                        Country: data.country,
                        State:data.state,
                        City: data.city,
                        Phone: data.phone,
                        Email: data.email,
                        PanNo: data.panNo,
                        AadhaarNo: data.aadhaarNo,
                        GSTIN: data.gstin,
                        Currency: data.currency,
                        Remarks: data.remarks,
                        Active: data.active
                    })
                }
                else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message)
                    setLoading(false)
                }
            }
        });

        setLoading(false)
    }

    //Handle Delete
    const handleDelete = (Id) => {
        confirmAlert({
            message: 'Do you want to Delete this Record ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setLoading(true)
                        await post(`${API_SERVER}api/Client/DeleteClientMaster`, { ...TokenData, ClientID: Id }, (res) => {
                            if (res && res.status) {
                                if (res.status === "ERROR") {
                                    toast.error(res.message)
                                    setLoading(false)
                                } else if (res.status === "SUCCESS") {
                                    toast.success(res.message)
                                    setLoading(false)
                                    getFormData()
                                    handleClear()
                                }
                                else if (res.status === "UNAUTHORIZED") {
                                    toast.error(res.message)
                                    setLoading(false)
                                }
                            }
                        });
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
        setLoading(false)
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            ClientID: '',
            CCode: 'System Generated',
            ClientName: '',
            ContactPerson: "",
            Address1: "",
            Address2: "",
            Zip: "",
            Country: "",
            State: "",
            City: "",
            Phone: "",
            Email: "",
            PanNo: "",
            AadhaarNo: "",
            GSTIN: "",
            Currency: "",
            Remarks:"",
            Active: true
}))
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality


    //---------------------Extracting data for Auto complete start--------------------------------//
    let pincodeData = []
    for (let i = 0; i < PinCodeAllData.length; i++) {
        let mydata = PinCodeAllData[i]?.values
        pincodeData.push(mydata)
    };
    let countryData = []
    for (let i = 0; i < countryAllData.length; i++) {
        let mydata = countryAllData[i]?.values
        countryData.push(mydata)
    };

    let StateData = []
    for (let i = 0; i < stateAllData.length; i++) {
        let mydata = stateAllData[i]?.values
        StateData.push(mydata)
    };

    let cityData = []
    for (let i = 0; i < cityAllData.length; i++) {
        let mydata = cityAllData[i]?.values
        cityData.push(mydata)
    };
    //---------------------Extracting data for Auto complete End--------------------------------//

    return (

        <>
            <div className='row'>
                <div className='col-lg-12'>
            <div className='main-card card border-0 '>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Client Master</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputrefs.current['txtClientName'].focus()
                                }
                            }}
                            label='Client Code'
                            id='txtClientCode'
                            disabled={true}
                            maxLength='10'
                            placeholder="System Generated"
                            value={recipe.CCode}
                            onChange={(e) => HandleChange('CCode', e.target.value)}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtContactPerson'].focus() };
                            }}
                            label='Client Name'
                            id='txtClientName'
                            maxLength='50'
                            placeholder="Client Name"
                            value={recipe.ClientName}
                            onChange={(e) => HandleChange('ClientName', e.target.value)}
                            required
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtAddress1'].focus() };
                            }}
                            label='Contact Person'
                            id='txtContactPerson'
                            maxLength='100'
                            placeholder="Contact Person"
                            value={recipe.ContactPerson}
                            onChange={(e) => HandleChange('ContactPerson', e.target.value)}
                            required
                        />

                        <InputBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtAddress2'].focus() };
                                    }}
                                    label='Address 1'
                                    id='txtAddress1'
                                    maxLength='100'
                                    placeholder="Address 1"
                                    value={recipe.Address1}
                                    onChange={(e) => HandleChange('Address1', e.target.value)}
                                    required={true }
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtPinCode'].children[0].children[0].focus() };
                            }}
                            label='Address 2'
                            id='txtAddress2'
                            maxLength='100'
                            placeholder="Address 2"
                            required={false }
                            value={recipe.Address2}
                            onChange={(e) => HandleChange('Address2', e.target.value)}
                        />

                       

                                <AutoCompleteBox
                                    loading={dataLoading}
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtCountry'].children[0].children[0].focus() };
                                    }}
                                    label='Pin Code'
                                    placeholder="Pin Code"
                                    maxLength='15'
                                    options={pincodeData}
                                    id='txtPinCode'
                                    onBlur={() => getCityStateByPincode(recipe.Zip)}
                                    required={true}
                                    onFocus={() => PinCodeData()}
                                    inputValue={recipe.Zip ? recipe.Zip : ''}
                                    onInputChange={(event, newInputValue) => {
                                        HandleChange('Zip', newInputValue)
                                        setRecipe(recipe => ({ ...recipe, Country: "", State: '', City: "", }))
                                     
                                       
                                    }}
                                />
                       

                        <AutoCompleteBox
                            loading={dataLoading}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtState'].children[0].children[0].focus() };
                            }}
                            label='Country'
                            placeholder="Country"
                            maxLength='50'
                            options={countryData}
                            id='txtCountry'
                            required={true}
                            onFocus={() => CountryData() }
                            inputValue={recipe.Country ? recipe.Country : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('Country', newInputValue)
                                setRecipe(recipe => ({ ...recipe, State: '' }))
                                setCountryPre(newInputValue)
                            }}
                        />

                        <AutoCompleteBox
                            loading={dataLoading}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtCity'].children[0].children[0].focus() };
                            }}
                            label='State'
                            placeholder="State"
                            maxLength='50'
                            options={StateData}
                            id='txtState'
                            required={false}
                            onFocus={() => StateAllData()}
                            inputValue={recipe.State ? recipe.State : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('State', newInputValue)
                                setStatePre(newInputValue)
                            }}
                        />

                        <AutoCompleteBox
                            loading={dataLoading}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtPhone'].focus() };
                            }}
                            label='City'
                            placeholder="City"
                            maxLength='50'
                            options={cityData}
                            id='txtCity'
                            required={false}
                            onFocus={() => CityAllData()}
                            inputValue={recipe.City ? recipe.City : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('City', newInputValue)
                            }}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtEmailID'].focus() };
                            }}
                            label='Mobile Number'
                            id='txtPhone'
                            maxLength='25'
                            placeholder="Mobile Number"
                            required={false}
                            value={recipe.Phone}
                                    onChange={(e) => HandleChange('Phone', e.target.value.replace(/[^0-9+\s-]/g, "").replace(/(\+.*\+)/g, "+").replace(/-+/g, "-").replace(/\s+/g, " "))}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtPanNo'].focus() };
                            }}
                            label='Email'
                            id='txtEmailID'
                            maxLength='50'
                            placeholder="Email"
                            value={recipe.Email}
                        
                            onChange={(e) => HandleChange('Email', e.target.value)}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtAadhaarNo'].focus() };
                            }}
                            label='PAN No.'
                            id='txtPanNo'
                            maxLength='10'
                            placeholder="PAN No."
                            value={recipe.PanNo}
                            onChange={(e) => HandleChange('PanNo', e.target.value)}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtGSTIN'].focus() };
                            }}
                            label='Aadhaar No.'
                            id='txtAadhaarNo'
                            maxLength='12'
                            placeholder="Aadhaar No."
                            value={recipe.AadhaarNo}
                            onChange={(e) => HandleChange('AadhaarNo', e.target.value)}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtRemarks'].focus() };
                            }}
                            label='GSTIN'
                            id='txtGSTIN'
                            maxLength='15'
                            placeholder="GSTIN"
                            value={recipe.GSTIN}
                            
                            onChange={(e) => HandleChange('GSTIN', e.target.value)}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtCurrency'].focus() };
                            }}
                            label='Remarks'
                            id='txtRemarks'
                            maxLength='50'
                          
                            placeholder="Remarks"
                            value={recipe.Remarks}
                            onChange={(e) => HandleChange('Remarks', e.target.value)}
                        />

                        <SelectInputBox
                            label='Currency'
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtIsActive'].focus() };
                            }}
                            id='txtCurrency'
                            required={false}
                            maxLength='50'
                            value={recipe.Currency}
                            onChange={(e) => setRecipe({ ...recipe, Currency: e.target.value })}
                        >
                            <option value=''>-Please Select-</option>

                            {CurrencyMasterArr?.map((item, index) => {
                                    return (
                                        <option key={index} value={item.code}>{item.code}</option>
                                    )
                                })
                            }
                        </SelectInputBox> 
                        
                        <Switch
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { handleSubmit ()};
                            }}
                                    label='Active'
                                    checked={recipe.Active}
                            id='txtIsActive'
                            nextinputid='txtFranchiseGroup'
                                    onChange={(e) => HandleChange('Active', e.target.checked)}
                        />

                        <FormButton
                            handleSubmit={handleSubmit}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/Client/ShowClientMaster', ExportRequest, 'Client Master')}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>

            {/* Table Component start*/}
            <Table
                Token={TokenData}
                PageName='ClientMaster'
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleFilter={() => {
                    getFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                //  loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {/* Table Component End*/}


            {/* Loder start*/}
            {loading && < BlurLoader />}
                    {/* Loder End*/}
                </div>
            </div>
        </>
    )
}

export default ClientMaster