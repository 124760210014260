import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import SideNav from './SideNav';

const Layout = () => {
    const [open, setopen] = useState(false)
    const [isopenheader,setIsopenheader] = useState(true)

    const handleOpen = () => {
        setopen(true)
    }

    const handleClose = () => {
        setopen(false)
    }

    const Handletoggle =()=>{
        setopen(!open)
    }

    const HandleheaderToogle = () => {
        setIsopenheader(!isopenheader)
    }


    return (
        <div>
            <Header Handletoggle={Handletoggle} HandleheaderToogle={HandleheaderToogle} open={open} isopenheader={isopenheader } />
            {/*<LeftPannel handleOpen={handleOpen} handleClose={handleClose} open={open} isopenheader={isopenheader} />*/}
            <SideNav handleOpen={handleOpen} handleClose={handleClose} open={open} isopenheader={isopenheader} />
            <div className={`main-dash ${open ? '' : 'marg-left'}`} style={{ marginTop:'80px' }}>
                <div className='container-fluid'>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Layout;