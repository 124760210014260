import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButton, Switch } from "../commoncomponents/InputBox";
import { confirmAlert } from 'react-confirm-alert'

import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';

//services
import { post } from '../../apiservices/service';

function UserGroup() {
    const inputrefs = useRef([]);
    const { user } = useAuth();

    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }

    //initial state
    const [recipe, setRecipe] = useState({ UserGroup:'', Active: true })
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
   
    const [isUpdate, setIsUpdate] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/User/ShowUserGroup`, { ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
        setLoading(false)
    }

    const handleChange1 = (event, value) => {
        getFormData(value);
    };

    useEffect(() => {
        inputrefs.current['txtUserGroup'].focus();
        document.title = 'Nihao: User Group'
        getFormData()
    }, [sortConfig, isUpdate])

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //Submit Handler
    const handleSubmit = () => {
            setLoading(true)
            post(`${API_SERVER}api/User/InsertUpdateUserGroup`, { ...TokenData, ...recipe }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        inputrefs.current[res.focus].focus();
                        setLoading(false)
                    } else if (res.status === "SUCCESS") {
                        setLoading(false)
                        toast.success(res.message)
                        getFormData()
                        handleClear()
                    }
                    else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message)
                        setLoading(false)
                    }
                }
            });
        setLoading(false)
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/User/EditUserGroup`, { ...TokenData, UserGroupID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    inputrefs.current[res.focus].focus();
                    setLoading(false)
                } else if (res.status === "SUCCESS") {
                    setLoading(false)
                    let data = res.data
                    setRecipe({ ...data, UserGroup: data.userGroup, Active: data.active })
                }
                else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message)
                    setLoading(false)
                }
            }
        });

        setLoading(false)
    }

    //Handle Delete
    const handleDelete = (Id) => {
        confirmAlert({
            message: 'Do you want to Delete this Record ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setLoading(true)
                        post(`${API_SERVER}api/User/DeleteUserGroup`, { ...TokenData, UserGroupID: Id }, (res) => {
                            if (res && res.status) {
                                if (res.status === "ERROR") {
                                    toast.error(res.message)
                                    setLoading(false)
                                } else if (res.status === "SUCCESS") {
                                    toast.success(res.message)
                                    setLoading(false)
                                    getFormData()
                                    handleClear()
                                }
                                else if (res.status === "UNAUTHORIZED") {
                                    toast.error(res.message)
                                    setLoading(false)
                                }
                            }
                        });
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
        setLoading(false)
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({ ...recipe, UserGroupID: '', UserGroup: '', Active: true }))
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
        setIsEdit(false)
    }

    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

  return (
      <>
          <div className='main-card card border-0'>
              <div className='card-header mb-1'>
                  <h6 className="m-0">User Group</h6>
              </div>
              <div className='card-body'>
                  <div className='row'>

                      <InputBox
                          inputrefs={inputrefs}
                          onKeyDown={(e) => {
                              if (e.key === 'Enter') { inputrefs.current['txtIsActive'].focus() };
                          }}
                          label='User Group'
                          id='txtUserGroup'
                          maxLength='50'
                          required
                          placeholder="User Group"
                          value={recipe.UserGroup}
                          onChange={(e) => HandleChange('UserGroup', e.target.value)}
                      />

                      <Switch
                          inputrefs={inputrefs}
                          onKeyDown={(e) => {
                              if (e.key === 'Enter') { handleSubmit() };
                          }}
                          label='Active'
                          checked={recipe.Active}
                          id='txtIsActive'
                          nextinputid='txtFranchiseGroup'
                          onChange={(e) => HandleChange('Active', e.target.checked)}
                      />

                      <FormButton
                          handleSubmit={handleSubmit}
                          handleExport={() => handleDownloadExcel(API_SERVER + 'api/User/ShowUserGroup', ExportRequest, 'User Group')}
                          handleClear={handleClear} 
                      />
                  </div>
              </div>
          </div>

          {/* Table Component start*/}
          <Table
              Token={TokenData}
              PageName='UserGroupMaster'
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleFilter={() => {
                  getFormData();
                  setFilterData({
                      ...filterData,
                  });
              }}
              tableData={result}
              //  loading={loading}
              filterData={filterData}
              setFilterData={setFilterData}
              currentPageNo={currentPageNo}
              handleChange1={handleChange1}
              handleSorting={handleSorting}
              sortConfig={sortConfig}
          />
          {/* Table Component End*/}


          {/* Loder start*/}
          {loading && < BlurLoader />}
          {/* Loder End*/}
      </>
  )
}

export default UserGroup