import React, { useEffect, useState, useRef } from 'react'
import { InputBox} from "../commoncomponents/InputBox";
import { useAuth } from '../../context/auth';
//loder
import BlurLoader from "../Loder/BlurLoader";
//react toast 
import { toast } from 'react-toastify';
//common Data
import { API_SERVER, downloadPDF } from '../../Utils';
import { FileUploader } from "react-drag-drop-files";
//services
import { post } from '../../apiservices/service';
import PickUpOrderData from '../commoncomponents/PickUpOrderData';

function PODupload() {
    const inputrefs = useRef([]);
    const { user } = useAuth();
 
    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }

    //initial state
    const [recipe, setRecipe] = useState({
        AwbNo: '',
        PODImage: "",
        PODImageName: "",
        PODImageExt: "",
    })
    const [loading, setLoading] = useState(false)
    const [fileSizeError, setFileSizeError] = useState('');
    const [label, setLabel] = useState('')

    useEffect(() => {
        inputrefs.current['txtAwbNo'].focus();
        document.title = 'Nihao: Label Print'
    }, [])

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //Submit Handler
    const handleSubmit = () => {
        setLoading(true);

        post(`${API_SERVER}api/Operation/UploadPODImage`, { ...TokenData, ...recipe }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message);
                    setLoading(false)
                    inputrefs.current[res.focus].focus();
                } else if (res.status === "SUCCESS") {
                    toast.success(res.message);
                    setLoading(false)
                    handleClear();
                } else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message);
                }
            }
            setLoading(false); 
        });
    };
    const handleFileChange = (file) => {
        if (file) {
            const maxSizeInBytes = 5 * 1024 * 1024; 
            if (file.size > maxSizeInBytes) {
                setFileSizeError("Pls Uploaded File Size Should be less than 5mb");
                return;
            }
            setFileSizeError('');
            let extArr = file.name.split(".");
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let logo = reader.result.split(',')[1];
                setRecipe({
                    ...recipe,
                    PODImage: logo,
                    PODImageExt: extArr[1],
                    PODImageName: extArr[0],
                });
            };
        }
    };
    
    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe, AwbNo: '',
            PODImage: "",
            PODImageName: "",
            PODImageExt: "", }))
        inputrefs.current['txtAwbNo'].focus();
     
        setLabel('')
    }

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">POD Upload</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-12 col-lg-12 h-25'>
                            <div className='row'>
                                    <div className="col-md-5 d-flex ">
                                        <div className=' col-sm-12  password-policy-border  border border-1 p-0 ' style={{ height: "fit-content" }}>
                                            <div className=' py-2 password-policy px-3' style={{ backgroundColor: "#F8F9FA" }}>
                                                <h6 className="m-0 font-weight-bold text-black">{'Instructions:'}</h6>
                                            </div>
                                            <div className=''>
                                                <div className="form-group">
                                                    <ol>1.POD Image Type Should be PNG,JPG,JPEG,PDF</ol>
                                                    <ol>2.POD Image Size Should be less than  5mb</ol>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-lg-3 h-25 row'>
                                <InputBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSubmit()
                                        };
                                    }}
                                    label='Awb No'
                                    id='txtAwbNo'
                                    maxLength='25'
                                    placeholder="Awb No"
                                    ignore_common={true}
                                    value={recipe.AwbNo}
                                    autoComplete='off'
                                    onChange={(e) => HandleChange('AwbNo', e.target.value)}
                                    required
                                />

                                <div
                                    className={`mb-1`}
                                    ref={ref => (inputrefs.current['txtbtnSave'] = ref)}  >
                                    <label className="form-label">Upload POD </label>
                                    <FileUploader
                                        classes='upload-section file-uploader'
                                        label='Upload Document'
                                        handleChange={handleFileChange}
                                        name="file" types={["JPG", "JPEG", "PDF","PNG"]}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                inputrefs.current['txtIfAccounted'].focus()
                                            };
                                        }}
                                    />
                                    {fileSizeError && <div style={{ color: 'red' }}>{fileSizeError}</div>}
                                    <div className='mt-1'>
                                        {recipe.UploadInvoice ?
                                            <>
                                                <div>
                                                    {/*<Link style={{ cursor: 'pointer' }} to={`/PdfView#data:application/pdf;base64,${encodeURI(recipe.UploadInvoice)}`} target="_blank">
                                                    <FaRegFilePdf />
                                                </Link>*/}
                                                    <span style={{ fontSize: "20px", cursor: 'pointer' }} className="text-warning ml-5"
                                                        onClick={() => downloadPDF(recipe.UploadInvoice, recipe.ImageName)}><i className="fa fa-download text-default"></i> </span>
                                                    <span style={{ fontSize: "10px" }}>{recipe.ImageName + '.' + recipe.ImageExt}</span>

                                                </div>
                                            </>
                                            : <></>}
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="btn btn-rounded btn-primary me-3 mb-0" style={{ minWidth: '8px' }} onClick={handleSubmit}>
                                <i className="fa fa-upload text-white color-success"></i> Upload
                            </button>
                        </div>
                        <div className='col-md-6 col-lg-6 ' style={{ maxHeight: "200px", overflow: "hidden" }}>
                            <PickUpOrderData recipe={recipe} />
                        </div>
                    </div>
                </div>
            </div>
            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}

            {label &&
                <div className='card-body mt-2 p-3'>
                    <embed src={`data:application/pdf;base64,${label}`} width='100%' height='400px' />
                </div>}
        </>
    )
}

export default PODupload