import nihaologo from '../../assests/images/nihaologo.png'

const BlurLoader = ({ text = 'Please wait...' }) => {
    return (
        <>
            <div className="main-div-blur">
                <div className='position-relative text-center'>
                    <div className="loader">
                    </div>
                    <img src={nihaologo} alt='' width={100}></img>
                    <h3 className="text-centerx" style={{ color: 'black' }}>{text}</h3>
                </div>
            </div>
        </>
    )
}

export default BlurLoader;