import { BarChart } from "./Charts";
import { useAuth } from '../../context/auth';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState, useRef } from 'react'
import { DateBox } from "../commoncomponents/InputBox";
import { API_SERVER, handleDownloadExcel } from "../../Utils";
import { post } from "../../apiservices/service";
import BlurLoader from "../Loder/BlurLoader";
import { Table } from "../commoncomponents/Table";
import Chart from "react-apexcharts";

const Dashboard = () => {
    const inputrefs = useRef([]);
    const { user } = useAuth();

    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }
    const [chart, setChart] = useState([]);
    var date = new Date();
    var first = new Date(date.getFullYear(), date.getMonth(), 1);
    let firstdate = first.toString()?.split(' ')
    firstdate = `${firstdate[2]}-${firstdate[1]}-${firstdate[3]}`

    let endate = new Date().toString()?.split(' ')
    endate = `${endate[2]}-${endate[1]}-${endate[3]}`

    //initial states
    const [recipe, setRecipe] = useState({ StartDate: firstdate, EndDate: endate })
    const [loading, setLoading] = useState(false)
    const [dashboard, setDashboard] = useState({})
    const [result, setResult] = useState({})
    const [ShipmentType, setShipmentType] = useState('')
    const [recordsName, setRecordsName] = useState([])
    const [recordsValue, setRecordsValue] = useState({})

    const getFormData = () => {
        setLoading(true)
        post(`${API_SERVER}api/Dashboard/DashboardShipmentCount`, { ...TokenData, ...recipe }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setDashboard({})
                    setLoading(false)
                } else {
                    setDashboard({ ...res })
                    setLoading(false)
                    getChart()
                }
            }
        });
    }

   
    //const getChart = async () => {
    //    try {
    //        const response = await post(`${API_SERVER}api/DashBoard/GetChartData`, { ...TokenData, ...recipe });

    //        console.log("API Response:", response);

    //        if (response && response.status && response.status !== "ERROR") {
    //            const newDataNames = response.map(item => item.recordsName_title);
    //            const newDataValues = response.map(item => item.recordsValue_value);

    //            console.log("recordsDataName:", newDataNames);
    //            console.log("recordsDataValue:", newDataValues);

    //            setRecordsName(newDataNames);
    //            setRecordsValue(newDataValues);
    //        } else {
    //            // Handle error case
    //            setRecordsName([]);
    //            setRecordsValue([]);
    //        }
    //    } catch (error) {
    //        console.error("Error fetching data:", error);
    //        // Handle error case
    //        setRecordsName([]);
    //        setRecordsValue([]);
    //    }
    //};
    //const getChart = async () => {
    //    post(`${API_SERVER}api/DashBoard/GetChartData`, { ...TokenData, ...recipe }, (res) => {
    //        const recordsDataName = [];
    //        const recordsDataValue = [];
    //        const categoryArray = res.options.xaxis;
    //      /*  const seriesArray = res.options.series;*/

    //        //for (let i = 0; i < seriesArray.length; i++) {
    //        //   /* recordsDataName.push(seriesArray[i].name);*/
               
    //        //    recordsDataValue.push(parseInt(seriesArray[i].data));
    //        //}
    //        for (let i = 0; i < categoryArray.length; i++) {
    //            recordsDataName.push(categoryArray[i].categories);

    //            recordsDataValue.push(parseInt(categoryArray[i].data));
    //        }
    //        console.log("recordsDataName:", recordsDataName);
    //        console.log("recordsDataValue:", recordsDataValue);

    //        console.log(res, "gfdgfgfhh");

    //        if (res && res.status) {
    //            if (res.status === "ERROR") {
    //                setRecordsName([]);
    //                setRecordsValue([]);
    //            } else {
    //                setRecordsName(recordsDataName);
    //                console.log(recordsDataName, "record data name");
    //                setRecordsValue(recordsDataValue);
    //            }
    //        }
    //    });
    //}
    const getChart = async () => {
        post(`${API_SERVER}api/DashBoard/GetChartData`, { ...TokenData, ...recipe }, (response) => {
            console.log("API Response:", response);

            const options = response.options || {}; // Ensure options exist
            const seriesData = options.series || []; // Ensure series array exists
            const recordsDataValue = [];
            const recordsDataName = response.options.xaxis.categories.map(category => category);
            
            const seriesArray = response.options.series;

            for (let i = 0; i < seriesArray.length; i++) {
               
                recordsDataValue.push(parseInt(seriesArray[i].data));
            }
            console.log("recordsDataName:", recordsDataName);
            console.log("recordsDataValue:", recordsDataValue);

            if (response && response.status === "SUCCESS") {
                setRecordsName(recordsDataName);
                setRecordsValue(seriesArray);
            } else {
                // Handle error case
                console.error("Error fetching data:", response.message);
                setRecordsName([]);
                setRecordsValue([]);
            }
        });
    };


    useEffect(() => {
        document.title = 'Nihao: Dashboard'
        getFormData()
        getChart()
        AOS.init(
            {
                duration: 1000,
            }
        );
    }, [])
    const generateColors = (length) => {
        const colors = ['#2CBAFB', '#CAB9F0', '#FDB31B', '#C2EED0', '#7D57D0'];
        const generatedColors = [];

        for (let i = 0; i < length; i++) {
            generatedColors.push(colors[i % colors.length]);
        }

        return generatedColors;
    };
    const GetModelData = (type) => {
        setLoading(true)
        setResult({})
        setShipmentType(type)
        post(`${API_SERVER}api/Dashboard/ShowDashboardShipmentData`, { ...TokenData, ...recipe, ShipmentType:type, Type:'s' }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setResult({})
                    setLoading(false)
                } else {
                    setResult({ ...res })
                    setLoading(false)
                }
            }
        });
    }

    //Handle Submit
    const HandleSubmit = () => {
        getFormData()
    }

    //Handle Reset
    const HandleClear = () => {
        setRecipe({ ...recipe, StartDate: firstdate, EndDate: endate })
        getFormData()
    }

    //Start Export functionality
    let ExportRequest = {
        ...recipe,
        ...TokenData,
        ShipmentType,
        Type: 'E'
    }
    //End Export functionality

    return (
        <>
            <>
                <div className='container-fluid dashboard'>
                    <div className='mb-3'>
                        <h4 className="m-0">Dashboard</h4>
                    </div>
                    <div className='main-card card border-0 mb-2'>
                        <div className='row p-3'>
                                <DateBox
                                    id='txtStartDate'
                                    inputrefs={inputrefs}
                                    label='Start Date'
                                    placeholder="start Date"
                                    required={true}
                                    selected={recipe.StartDate === '' ? '' : new Date(recipe.StartDate)}
                                    onChange={(e) => {
                                        let dateArr = e?.toString()?.split(' ')
                                        dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                        setRecipe({ ...recipe, StartDate: dateArr })
                                    }}
                                    maxDate={new Date()}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtEndDate'].input.focus() };
                                    }}
                                />
                          
                                <DateBox
                                    id='txtEndDate'
                                    inputrefs={inputrefs}
                                    label='End Date'
                                    placeholder="End Date"
                                    required={true}
                                    selected={recipe.EndDate === '' ? '' : new Date(recipe.EndDate)}
                                    onChange={(e) => {
                                        let dateArr = e?.toString()?.split(' ')
                                        dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                        setRecipe({ ...recipe, EndDate: dateArr })
                                    }}
                                    maxDate={new Date()}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {  };
                                    }}
                            />

                            <div className='col-md-3 cntrl-width'>
                                <div className='d-flex align-items-right'>
                                    <div className='mt-4' >
                                        <button type="button" className="btn btn-rounded btn-primary text-white" style={{ minWidth: '2.87rem' }} onClick={HandleSubmit}><i className="fa fa-search"></i></button>
                                        <button type="button" className="btn btn-rounded btn-danger text-white" style={{ minWidth: '2.87rem' }} onClick={HandleClear}>Reset</button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <div className='row p-2'>
                            <div className='mb-3'>
                                <h5 className="m-0">Shipment Details</h5>
                            </div>
                            {/*---------- Total Booking Start -----------*/}
                            <div className='col-sm-6 col-lg-3' style={{ cursor: "pointer" }}>
                                <div className='main-card card border-0' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={()=>GetModelData('TotalBooking') }>
                                    <div className='card-header mb-1'>
                                        <h6 className="m-0">Total Booking</h6>
                                    </div>
                                    <div className='card-body'>
                                        <div className='d-flex align-items-center'>
                                            <div className='dash-icon1' data-aos="fade-right">
                                                <i className='fa-solid fa-money-bills' style={{ color: "black" }}></i>
                                            </div>
                                            <div>
                                                <h5 className="m-0">{dashboard.totalBooking}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*---------- Total Booking End -----------*/}


                            {/*---------- AWB Updated Start -----------*/}
                            <div className='col-sm-6 col-lg-3' style={{ cursor: "pointer" }}>
                                <div className='main-card card border-0' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => GetModelData('AWBUpdated')} >
                                    <div className='card-header mb-1'>
                                        <h6 className="m-0">AWB Updated</h6>
                                    </div>
                                    <div className='card-body'>
                                        <div className='d-flex align-items-center'>
                                            <div className='dash-icon2' data-aos="fade-left">
                                                <i className='fa-solid fa-money-bills' style={{ color: "black" }}></i>
                                            </div>
                                            <div>
                                                <h5 className="m-0">{dashboard.awbUpdated}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*---------- AWB Updated End -----------*/}

                            {/*---------- Tracking Updated Start -----------*/}
                            <div className='col-sm-6 col-lg-3' style={{ cursor: "pointer" }}>
                                <div className='main-card card border-0' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => GetModelData('TrackingUpdated')}>
                                    <div className='card-header mb-1'>
                                        <h6 className="m-0">Tracking Updated</h6>
                                    </div>
                                    <div className='card-body'>
                                        <div className='d-flex align-items-center'>
                                            <div className='dash-icon3' data-aos="fade-right">
                                                <i className='fa-solid fa-money-bills' style={{ color: "black" }}></i>
                                            </div>
                                            <div>
                                                <h5 className="m-0">{dashboard.trackingUpdated}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*---------- Tracking Updated End -----------*/}

                            {/*---------- Delivered Start -----------*/}
                            <div className='col-sm-6 col-lg-3' style={{ cursor: "pointer" }}>
                                <div className='main-card card border-0' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => GetModelData('Delivered')}>
                                    <div className='card-header mb-1'>
                                        <h6 className="m-0">Delivered</h6>
                                    </div>
                                    <div className='card-body'>
                                        <div className='d-flex align-items-center'>
                                            <div className='dash-icon4' data-aos="fade-left">
                                                <i className='fa-solid fa-truck' style={{ color: "black" }}></i>
                                            </div>
                                            <div>
                                                <h5 className="m-0">{dashboard.delivered}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*---------- Delivered End -----------*/}

                            {/*---------- In Transit Start -----------*/}
                            <div className='col-sm-6 col-lg-3' style={{ cursor: "pointer" }}>
                                <div className='main-card card border-0' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => GetModelData('InTransit')} >
                                    <div className='card-header mb-1'>
                                        <h6 className="m-0">In Transit</h6>
                                    </div>
                                    <div className='card-body'>
                                        <div className='d-flex align-items-center'>
                                            <div className='dash-icon5' data-aos="fade-right">
                                                <i className='fa-solid fa-money-bills' style={{ color: "black" }}></i>
                                            </div>
                                            <div>
                                                <h5 className="m-0">{dashboard.inTransit}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*---------- In Transit End-----------*/}

                        </div>
                    </div>

                   
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='main-card card border-0'>
                                <div className='card-header mb-1'>
                                    <h5 className="m-0">Total Shipment</h5>
                                </div>
                                <div className='card-body' >
                                    
                                    <Chart
                                        type="bar"
                                        height={350}
                                        series={recordsValue}
                                        options={{
                                            chart: {
                                                height: "350",
                                                type: "bar",
                                            },
                                            plotOptions: {
                                                bar: {
                                                    horizontal: false,
                                                    columnWidth: "55%",
                                                    endingShape: "rounded",
                                                },
                                            },
                                            dataLabels: {
                                                enabled: false,
                                            },
                                            stroke: {
                                                show: true,
                                                width: 2,
                                                colors: ['transparent'],  
                                            },
                                            xaxis: {
                                                categories: recordsName,
                                            },
                                            yaxis: {
                                                title: {
                                                    text: "",
                                                },
                                            },
                                            fill: {
                                                opacity: 1,
                                            },
                                            tooltip: {
                                                y: {
                                                    formatter: '',
                                                },
                                            },
                                           
                                        }}
                                    />


                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </>

            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}


            <div className="modal fade" id="exampleModal" data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="aboutUsLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content" style={{ background: "mintcream" }}>
                        <div className="modal-header" style={{ padding: '7px 15px' }}>
                            <h5 className="modal-title d-flex" id="exampleModalLabel">
                                {(ShipmentType === 'TotalBooking' && 'Total Booking') ||
                                    (ShipmentType === 'AWBUpdated' && 'AWB Updated') ||
                                    (ShipmentType === 'TrackingUpdated' && 'Tracking Updated') ||
                                    (ShipmentType === 'Delivered' && 'Delivered') ||
                                    (ShipmentType === 'InTransit' && 'In Transit') } 
                            </h5>
                            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
                        </div>
                        <div className="modal-body table-responsive" style={{ maxHeight: '210px', padding: '8px' }}>
                            {/* Table Component start*/}
                            <Table
                                Token={TokenData}
                                PageName='Dashboard'
                                handleEdit={() => alert('hii')}
                                handleDelete={() => alert('hii')}
                                handleFilter={() => { }}
                                tableData={result}
                                //  loading={loading}
                                filterData={''}
                                setFilterData={''}
                                currentPageNo={''}
                                handleChange1={''}
                                handleSorting={''}
                                sortConfig={''}
                            />
                            {/* Table Component End*/}
                        </div>
                        <div className="modal-footer" style={{ padding: '3px' }}>
                            <button type="button"
                                onClick={() => handleDownloadExcel(API_SERVER + 'api/Dashboard/ShowDashboardShipmentData', ExportRequest, ShipmentType)}
                                className="btn btn-rounded btn-warning"
                            >
                                <span className=" text-warning me-2">
                                    <i className="fa fa-download text-white color-success">
                                    </i>
                                </span>Export
                            </button>
                            <button type="button" className="btn btn-secondary" style={{ margin: '5px', minWidth: '30px' }} data-bs-dismiss="modal"> <i className='fa fa-close' /> Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default Dashboard;