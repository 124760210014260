import { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';
import nihaologo from '../../assests/images/nihaologo.png'

//react toast 
import { toast } from 'react-toastify';

//custom images
import sipllogo from '../../assests/images/sipllogo.png';
import logo from '../../assests/images/logo.png';
import loginpage from '../../assests/images/loginpage.jpg';

//loder
import BlurLoader from "../Loder/BlurLoader";

//services
import { post } from "../../apiservices/service";
import { API_SERVER } from "../../Utils";

const ForgotPasswordSlide1 = () => {
    const inputrefs = useRef([]);


    //initial state
    const [recipe, setRecipe] = useState({ UserCode :'' })
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        inputrefs.current['txtUserCode'].focus();
        document.title = 'Nihao: Forgot Password'
    }, [])

    //Recipe Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }


    //submit button Handler
    const HandleSubmit = () => {
            setLoading(true)
            post(`${API_SERVER}api/UserLogin/ForgetPassword`, { ...recipe, AuthToken: "der324k343%@923041!" }, (res) => {
                // console.log(res);
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setRecipe(recipe => ({ ...recipe, UserCode: "" }))
                        if (res.focus) inputrefs.current[res.focus].focus()
                        setLoading(false)
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                    }
                }
            });
    }

    return (
        <>
            {/* Forgot Password Form Start */}
            <div className='login-page login-bg'>
                <div className="container-fluid">
                    <div className='row justify-content-center' style={{ height: '100vh' }}>

                        <div className='col-lg-8 col-md-7 col-xl-8 p-0' >
                            <div className="d-flex align-items-center justify-content-center log_height" >
                                <img className="login-image" src={loginpage} alt='' />
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-5 col-xl-4 bg-white align-items-center justify-content-between d-flex flex-column'>

                            <div className='login-content'>
                                <div className='text-center'><img className='mb-2 sampark-logo' src={nihaologo} alt='Nihao logo' /></div>
                                <h5 className='fs-5 mb-3 text-center'>Forgot <span style={{ color: '#fdb813' }}>Password</span></h5>

                                <div className="form-group text-start mb-3" >
                                    <label className="form-label mb-1">User ID <span className='required'>*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        ref={ref => (inputrefs.current['txtUserCode'] = ref)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['btnSubmit'].focus() };
                                        }}
                                        placeholder="User ID"
                                        name='UserCode'
                                        value={recipe.UserCode}
                                        onChange={(e) => HandleChange('UserCode', e.target.value)}
                                    />

                                </div>

                                <div className='login-trouble mb-2'>
                                    <Link to='/'>Login</Link>
                                </div>

                                <button
                                    ref={ref => (inputrefs.current['btnSubmit'] = ref)}
                                    className='btn-primary btn w-100'
                                    onClick={HandleSubmit}
                                >
                                    Send Email
                                </button>
                            </div>
                            <div className='sagar_logo text-center d-flex flex-column mb-5'>
                                <img src={sipllogo} alt='sagar logo' />
                                <Link to="https://www.sagarinfotech.com/" className='small' target='_blank'>Sagar Informatics Pvt. Ltd.</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Forgot Password Form End */}


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>

    )
}

export default ForgotPasswordSlide1;