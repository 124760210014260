import React, { useEffect, useState, useRef } from 'react'
import { InputBox, DateBox, SelectInputBox, FormButtonWithPrint, AutoCompleteBox, ImportButton } from "../commoncomponents/InputBox";
import { FileUploader } from "react-drag-drop-files";
import { useAuth } from '../../context/auth';
import { confirmAlert } from 'react-confirm-alert';
//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, initializeFilterData } from '../../Utils';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';
import { Table } from '../commoncomponents/Table';
import PickUpOrderData from '../commoncomponents/PickUpOrderData';
import { BulkClearData, DownloadFileAtURL, ERRORDATALIST, HandleBulkInsertData, readFile } from './ImportData';

function MovementEntry() {
    const inputrefs = useRef([]);
    const { user } = useAuth();
    const [status, setStatus] = useState([]);
    const [ImportData, setImportData] = useState([]);
    const [StatusDetailsData, setStatusDetailsData] = useState([])

    const [Name, setName] = useState('');
    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }

    //intital States
    const [recipe, setRecipe] = useState({
        OrderProgressID:"",
       
        AwbNo: "",
        StatusDate: "",
        StatusTime: "",
        Location: "",
        PacketStatus: "",
        StatusDetails:""
    })
    const StatusData = () => {
        let temp = {
            Type: "GetStatusDetails",
            Prefix: "",
            ContextKey: "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setDataLoading(false)
                    setStatusDetailsData([])
                } else {
                    setDataLoading(false)
                    let data = res.dataList
                    setStatusDetailsData(data)
                }
            }
        });
    }

    let statusData = []
    for (let i = 0; i < StatusDetailsData.length; i++) {
        let mydata = StatusDetailsData[i]?.values
        statusData.push(mydata)
    };

    const [dataLoading, setDataLoading] = useState(false);
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false);
    const [packetAllData, setPacketAllData] = useState([]);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [isUpdate, setIsUpdate] = useState(false)

    //------------------------To Get Packet Status Data----------------------//
    const getPacketStatusData = () => {
        let temp = {
            Type: "GetPacketStatusMaster"
        }
        setDataLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setPacketAllData([])
                    setDataLoading(false)
                } else {
                    let data = res.dataList
                    setPacketAllData(data)
                    setDataLoading(false)
                }
            }
        });
        setLoading(false)
    }
    //const getFormData = () => {
    //    setLoading(true)
    //    setResult({})
    //    post(`${API_SERVER}api/Operation/GetMovementEntryDetail`, { ...TokenData, ...recipe,  Type: 'S' }, (res) => {
    //        if (res && res.status) {
    //            console.log(res, "ad")
    //            if (res.status === "ERROR") {
    //                setLoading(false)
    //                setResult({})
    //            } else {
    //                setLoading(false)
    //                setResult({ ...res})
    //                console.log(res, "resresres")
    //            }
    //        }
    //    });
    //    setLoading(false)
    //}
    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/Operation/GetMovementEntryDetail`, { ...TokenData, ...temp, ...sortConfig, ...filterData, ...recipe }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    const BulkSubmit = async (e) => {
        let data = await readFile(e)
        setImportData(data.exceljson)
        setName(data.FileName)
        let a = document.querySelectorAll('.jRuVVs')
        if (a.length > 0) {
            a[0].innerHTML = data.FileName
        }
    };
    //const EXCEL_FILE_URL = `MovementEntry.xlsx`;
    //const DownloadFileAtURL = (url) => {
    //    const fileName = url.split('/').pop()

    //    const aTag = document.createElement('a');
    //    aTag.href = url
    //    aTag.setAttribute('download', fileName)
    //    document.body.appendChild(aTag);
    //    aTag.click();
    //    aTag.remove();
    //}
    const DownloadFormat = async () => {
        await DownloadFileAtURL('MovementEntry.xlsx')
    };

    const ErrorData = async () => {
        await ERRORDATALIST(status, 'MovementEntryError')
    };
    const handleBulk = async () => {
        if (ImportData.length !== 0) {

            if (true) {
                //  setSubLoading(true)
                let Data = await HandleBulkInsertData(`${API_SERVER}api/operation/SaveMovementEntry`, ImportData, TokenData, '', '')
                setStatus(Data)
                // setSubLoading(false)
                toast.info(`SUCCESS : ${Data.SuccessCount} Error : ${Data.ErrorCount}`)
             
            } else {
                toast.error('Header Format is not valid !', {
                    position: 'top-center'
                })
              
            }
        } else {
            toast.error('Please Choose File With Valid Data.!')
            
        }

    };

    

    const handleDelete = (Id) => {
        confirmAlert({
            message: 'Do you want to Delete this Record ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setLoading(true)
                        await post(`${API_SERVER}api/Operation/DeleteMovementEntryDetail`, { ...TokenData, OrderProgressID: Id }, (res) => {
                            if (res && res.status) {
                                if (res.status === "ERROR") {
                                    toast.error(res.message)
                                    setLoading(false)
                                } else if (res.status === "SUCCESS") {
                                    toast.success(res.message)
                                    setLoading(false)
                                    getFormData()
                                    handleClear()
                                }
                                else if (res.status === "UNAUTHORIZED") {
                                    toast.error(res.message)
                                    setLoading(false)
                                }
                            }
                        });
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
        setLoading(false)
    }
    const handleChange1 = (event, value) => {
        getFormData(value);
    };

    useEffect(() => {
          getPacketStatusData()
        getFormData()
        inputrefs.current['txtOrderNo'].focus();
        document.title = 'Nihao:Movement Entry'
    }, [])


    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //Submit Handler
    const handleSubmit = () => {
        setLoading(true)
        post(`${API_SERVER}api/operation/SaveMovementEntry`, { ...TokenData, ...recipe }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    if (res.focus === 'txtStatusDate') {
                        inputrefs.current[res.focus].input.focus()
                    } else inputrefs.current[res.focus].focus()

                    setLoading(false)
                } else if (res.status === "SUCCESS") {
                    setLoading(false)
                    toast.success(res.message)
                    handleClear()
                }
                else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message)
                    setLoading(false)
                }
            }
        });
        setLoading(false)

    }

    const getForwardingData = (pickno,awbno) => {
        post(`${API_SERVER}api/Operation/GetUpdateForwardingPartyDetail`, { ...TokenData, ...recipe, PickupNo: pickno, AwbNo: awbno }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setRecipe(recipe => ({ ...recipe, bookingDate: '', AwbNo: '', StatusDate: "", StatusTime: "", Location: "", PacketStatus: "" }))
                   // toast.error(res.message)
                } else {
                    let data = res.data
                   /* console.log(data,awbno)*/
                    setRecipe({
                        ...data, AwbNo: data.awbNo, StatusDate: "", StatusTime: "", Location: "", PacketStatus: ""
                    })
                   
                }
            }
        });
    }


    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            OrderProgressID:"",
            bookingDate:"",
            AwbNo: "",
            StatusDate: "",
            StatusTime: "",
            Location: "",
            PacketStatus: "",
            StatusDetails:""
        }))
        setIsUpdate(!isUpdate)
        setImportData([])
        BulkClearData()
        setResult({})
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Movement Entry</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-6 col-lg-6 h-25'>
                            <div className='row'>
                                <div className='col-md-6 col-lg-6 h-25'>
                                    <InputBox
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtStatusDate'].input.focus() };
                                        }}
                                        label='Awb No'
                                        id='txtOrderNo'
                                        maxLength='25'
                                        autoComplete='off'
                                        ignore_common={true}
                                        placeholder="Awb No"
                                        onBlur={() => {
                                            getForwardingData(recipe.AwbNo)
                                            getFormData()
                                            setRecipe({ ...recipe, AwbNo:'' })
                                        }}
                                        value={recipe.AwbNo}
                                        onChange={(e) => HandleChange('AwbNo', e.target.value)}
                                        required={true}
                                    />
                                </div>

                                {/*<div className='col-md-6 col-lg-6 h-25' style={{ display: "none" }}>*/}
                                {/*    <InputBox*/}
                                {/*        inputrefs={inputrefs}*/}
                                {/*        onKeyDown={(e) => {*/}
                                {/*            if (e.key === 'Enter') { inputrefs.current['txtStatusDate'].input.focus() };*/}
                                {/*        }}*/}
                                {/*        label='AWB No'*/}
                                {/*        id='txtAwbNo'*/}
                                {/*        maxLength='25'*/}
                                {/*        ignore_common={true}*/}
                                {/*        onBlur={() => {*/}
                                {/*            getForwardingData(recipe.AwbNo)*/}
                                {/*            setRecipe({ ...recipe, OrderNo: '' })*/}
                                {/*        }}*/}
                                {/*        placeholder="AWB No"*/}
                                {/*        value={recipe.AwbNo}*/}
                                {/*        onChange={(e) => HandleChange('AwbNo', e.target.value)}*/}
                                {/*        required={false}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                <div className='col-md-6 col-lg-6 h-25'>
                                    <DateBox
                                        id='txtStatusDate'
                                        inputrefs={inputrefs}
                                        label='Status Date'
                                        placeholder="Status Date"
                                        required={true}
                                        ignore_common={true}
                                        selected={recipe.StatusDate === '' ? '' : new Date(recipe.StatusDate)}
                                        onChange={(e) => {
                                            let dateArr = e?.toString()?.split(' ')
                                            dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                            setRecipe({ ...recipe, StatusDate: dateArr })
                                        }}
                                        maxDate={new Date()}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtStatusTime'].focus() };
                                        }}
                                    />

                                </div>
                               
                            </div>
                           
                            <div className='row'>
                               

                                <div className='col-md-6 col-lg-6 h-25'>
                                    <div className="movement-time-picker">
                                        <label className='form-label'>Status Time <span className='required'>*</span></label>
                                        <input
                                            type="time"
                                            ref={ref => (inputrefs.current['txtStatusTime'] = ref)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtLocation'].focus() };
                                            }}
                                            className='form-control'
                                            value={recipe.StatusTime}
                                            max="23:59"
                                            onChange={(e) => setRecipe({ ...recipe, StatusTime: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-6 h-25'>
                                    <InputBox
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtPacketStatus'].focus() };
                                        }}
                                        label='Location'
                                        ignore_common={true}
                                        id='txtLocation'
                                        maxLength='50'
                                        placeholder="Location"
                                        value={recipe.Location}
                                        onChange={(e) => HandleChange('Location', e.target.value)}
                                        required={true}
                                    />
                                </div>

                            </div>


                            <div className='row d-flex'>
                                <div className='col-md-6 col-lg-6 h-25'>
                                    <SelectInputBox
                                        label='Packet Status'
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtStatusDetails'].children[0].children[0].focus() };
                                        }}
                                        id='txtPacketStatus'
                                        ignore_common={true}
                                        nextinputid='txtStatusDetails'
                                        required={true}
                                        maxLength='50'
                                       
                                        value={recipe.PacketStatus}
                                        onChange={(e) => setRecipe({ ...recipe, PacketStatus: e.target.value })}
                                    >
                                        <option value=''>-Please Select-</option>
                                        {
                                            packetAllData?.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.code}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </SelectInputBox>
                                </div>
                                <div className='col-md-6 col-lg-6 h-25'>
                                    {/*<InputBox*/}
                                    {/*    inputrefs={inputrefs}*/}
                                        
                                    {/*    label='Status Details'*/}
                                    {/*    ignore_common={true}*/}
                                    {/*    id='txtStatusDetails'*/}
                                    {/*    maxLength='50'*/}
                                    {/*    placeholder="Status Details"*/}
                                    {/*    value={recipe.StatusDetails}*/}
                                    {/*    onChange={(e) => HandleChange('StatusDetails', e.target.value)}*/}
                                    {/*    required={true}*/}
                                    {/*/>*/}
                                    <AutoCompleteBox
                                        loading={dataLoading}
                                        divclassname='col-md-12'
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            handleSubmit()
                                        }}
                                        label='Status Details'
                                        placeholder="Status Details"
                                        maxLength='50'
                                        options={statusData}
                                        id='txtStatusDetails'
                                        required={true}
                                        onFocus={() => StatusData()}
                                        inputValue={recipe.StatusDetails ? recipe.StatusDetails : ''}
                                        onInputChange={(event, newInputValue) => {
                                            HandleChange('StatusDetails', newInputValue)
                                        }}
                                    />
                                </div>

                                <FormButtonWithPrint
                                    save={true}
                                    print={false}
                                    reset={true}
                                    handleSubmit={handleSubmit}
                                    handleClear={handleClear}
                                />
                            </div>
                            <ImportButton
                                handleBulk={handleBulk}
                                BulkSubmit={(e) => BulkSubmit(e)}
                                downloadFormat={DownloadFormat}
                                ErrorData={ErrorData}
                                Status={status}
                            >
                            </ImportButton>

                            {/*<div className=' col-sm-12  password-policy-border  border border-1 p-0 mx-4 my-sm-2' style={{ height: "fit-content" }}>*/}
                            {/*    <div className="d-flex gap-4 " style={{ paddingLeft: "10px", paddingTop:"10px" }}>*/}
                            {/*    <FileUploader handleChange={BulkSubmit} name="file" types={["xlsx"]} />*/}
                            {/*    <span type="button" style={{ minWidth: '8px', textDecoration: 'underline', color: 'blue', fontSize: '13px' }} onClick={() => DownloadFileAtURL(EXCEL_FILE_URL)}>*/}
                            {/*        Download Format <i className="fa fa-download color-success"></i>*/}
                            {/*    </span>*/}
                            {/*</div>*/}

                            {/*<div className='mt-2'>*/}
                            {/*    <button type="button" className="btn btn-rounded btn-primary me-3 mb-0" style={{ minWidth: '6px', }} onClick={handleBulk}>*/}
                            {/*        <i className="fa fa-upload text-white color-success"></i> Upload*/}
                            {/*    </button>*/}
                            {/*    <button type="button" onClick={handleClear} className="btn btn-rounded btn-danger me-3 mb-0"><span className="text-white me-2"><i className="fa-solid fa-arrow-rotate-left"></i>*/}
                            {/*    </span>Reset</button>*/}
                            {/*</div>*/}

                            {/*    <div className='d-flex mb-1'>*/}
                            {/*        {status?.length !== 0 ?*/}
                            {/*            <>*/}
                            {/*                <div className='text-success p-0 pt-2 m-0 fw-bold' style={{ lineHeight: '14px' }}>Success : {status?.SuccessCount} &nbsp; &nbsp;*/}
                            {/*                    <span className='text-danger'>Error : {status?.ErrorCount}*/}
                            {/*                        {status?.length !== 0 && status?.ErrorData?.length !== 0 ?*/}
                            {/*                        <>&nbsp;<i className="fa fa-download  color-success" style={{ cursor: 'pointer' }} onClick={ErrorData}></i></>*/}
                            {/*                        : ""}*/}
                            {/*                </span></div>*/}
                            {/*        </>*/}
                            {/*        : ""}*/}
                            {/*</div>*/}
                            {/*</div>*/}

                        </div>

                        <div className='col-md-6 col-lg-6 h-25'>
                            <PickUpOrderData recipe={recipe} />
                        </div>
                    </div>
                </div>
            </div>

            {/* Table Component start*/}
            <Table
                Token={TokenData}
                PageName='MovementEntry'
            
                handleDelete={handleDelete}
                handleFilter={() => {
                    getFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                //  loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {/* Table Component End*/}

            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>
    )
}

export default MovementEntry