import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';
import { getCommonData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';
import { useAuth } from '../../context/auth';
import BlurLoader from "../Loder/BlurLoader";
import { AutoCompleteBox, DateBox, FormButtonWithPrint, InputBox } from "../commoncomponents/InputBox";
import { Table } from '../commoncomponents/Table';

function SeaCargoReport() {
    const inputrefs = useRef([]);
    const { user } = useAuth();
    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }

    //intial states

    const [recipe, setRecipe] = useState({
        OrderNo: "",
        "BLMTDNo": "",
        "ForwardingNo": "",
        "BookingFromDate": "",
        "BookingToDate": "",
        "PacketStatus": "",
        "PaymentType": "",
        "PacketType": "",
        "Client": "",
        "PhoneNo": "",
        "Consignor": "",
        "Consignee": "",
        "ZipCode": "",
    })

    const [tableData, setTableData] = useState({})
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [ConsignorDataArr, setConsignorDataArr] = useState([])
    const [ConsigneeDataArr, setConsigneeDataArr] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const [ClientDataArr, setClientDataArr] = useState([])
    const [IsUpdate, setIsUpdate] = useState(false)
    //----------------Consignor api--------------------------

    const getConsignorData = () => {
        let temp = {
            Type: "GetConsignor",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setDataLoading(false)
                    setConsignorDataArr([])
                } else {
                    let data = res.dataList
                    setDataLoading(false)
                    setConsignorDataArr(data)
                }
            }
        });
    }

    let ConsignorData = []
    for (let i = 0; i < ConsignorDataArr.length; i++) {
        let mydata = ConsignorDataArr[i]?.values
        ConsignorData.push(mydata)
    };


    //---------------consignee api--------------------------
    const getConsigneeData = () => {
        let temp = {
            Type: "GetConsignee",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setConsigneeDataArr([])
                    setDataLoading(false)
                } else {
                    let data = res.dataList
                    setConsigneeDataArr(data)
                    setDataLoading(false)
                }
            }
        });
    }

    let ConsigneeData = []
    for (let i = 0; i < ConsigneeDataArr.length; i++) {
        let mydata = ConsigneeDataArr[i]?.values
        ConsigneeData.push(mydata)
    };


    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            PageIndex: PageIndex.toString(),
            Type: "s"
        }
        post(`${API_SERVER}api/Report/SeaCargoReport`, { ...TokenData, ...temp, ...sortConfig, ...filterData, ...recipe }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setTableData({ ...res })
                    toast.error(res.Message)
                } else {
                    setLoading(false)
                    setTableData({ ...res })
                    toast.success(res.Message)
                }
            }
        });
        setLoading(false)
    }

    useEffect(() => {
        if (IsUpdate) {
            setIsUpdate(false)
            getFormData()
        }
    }, [IsUpdate])

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe, OrderNo: '', AwbNo: '', BookingFromDate: '', BookingToDate: '', ForwardingNo: "", PacketStatus: "", PaymentType: "", PacketType: "",
            Client: "", PhoneNo: "", Consignor: "", Consignee: "", ZipCode: ""

        }))
        setIsUpdate(true)
    }


    //------------------------To Get Client Data----------------------//
    const getClientData = () => {
        let temp = {
            Type: "GetClient",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setClientDataArr([])
                    setDataLoading(false)
                } else {
                    let data = res.dataList
                    setClientDataArr(data)
                    setDataLoading(false)
                }
            }
        });
    }

    let clientData = []
    for (let i = 0; i < ClientDataArr.length; i++) {
        let mydata = ClientDataArr[i]?.values
        clientData.push(mydata)
    };

    //Start Export functionality
    let ExportRequest = {
        ...recipe,
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }

    useEffect(() => {
        // inputrefs?.current["txtBookingFromDate"] && inputrefs.current["txtBookingFromDate"].focus()
        document.title = 'Nihao: Sea Cargo Report'
    }, [])

    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    const handleChange1 = (event, value) => {
        getFormData(value);
    };

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
        getFormData()
    };

    const handlePrint = (value) => {
        post(`${API_SERVER}api/Print/PrintSeaCargoEntry`, { ...TokenData, BLMTDNo: value }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                } else {
                    window.open(`/PdfView/#data:application/pdf;base64,${res.base64PDF}`)
                }
            }
        })
    };

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Sea Cargo Report</h6>
                </div>
                <div className='card-body'>
                    <div className="row">
                        <DateBox
                            id='txtBookingFromDate'
                            inputrefs={inputrefs}
                            label='Booking Date'
                            placeholder="Booking From Date"
                            required={false}
                            selected={recipe.BookingFromDate === '' ? '' : new Date(recipe.BookingFromDate)}
                            onChange={(e) => {
                                let dateArr = e?.toString()?.split(' ')
                                dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                setRecipe({ ...recipe, BookingFromDate: dateArr })
                            }}
                            maxDate={new Date()}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtBookingToDate'].input.focus() };
                            }}
                        />

                        <DateBox
                            id='txtBookingToDate'
                            inputrefs={inputrefs}
                            label='Booking To Date'
                            placeholder="Booking To Date"
                            required={false}
                            selected={recipe.BookingToDate === '' ? '' : new Date(recipe.BookingToDate)}
                            onChange={(e) => {
                                let dateArr = e?.toString()?.split(' ')
                                dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                setRecipe({ ...recipe, BookingToDate: dateArr })
                            }}
                            maxDate={new Date()}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtBLMTDNo'].focus() };
                            }}
                        />
                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtClient'].children[0].children[0].focus() };
                            }}
                            label='BLMTD No'
                            id='txtBLMTDNo'
                            maxLength='20'
                            placeholder="BLMTD No"
                            value={recipe.BLMTDNo}
                            onChange={(e) => HandleChange('BLMTDNo', e.target.value)}
                        />

                        <AutoCompleteBox
                            loading={dataLoading}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtPhoneNo'].focus() };
                            }}
                            label='Client'
                            placeholder="Client"
                            maxLength='50'
                            options={clientData}
                            id='txtClient'
                            required={false}
                            onFocus={() => getClientData()}
                            inputValue={recipe.Client ? recipe.Client : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('Client', newInputValue)
                            }}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtConsignor'].children[0].children[0].focus() };
                            }}
                            label=' Consignee Phone No'
                            id='txtPhoneNo'
                            maxLength='20'
                            placeholder=" Consignee Phone No "
                            value={recipe.PhoneNo}
                            onChange={(e) => HandleChange('PhoneNo', e.target.value.replace(/\D/g, ""))}
                        />

                        <AutoCompleteBox
                            loading={dataLoading}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtConsigneeName'].children[0].children[0].focus() };
                            }}
                            label='Consignor Name'
                            placeholder="Consignor Name"
                            maxLength='50'
                            options={ConsignorData}
                            id='txtConsignor'
                            required={false}
                            onFocus={() => getConsignorData()}
                            inputValue={recipe.Consignor ? recipe.Consignor : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('Consignor', newInputValue)

                            }}
                        />

                        <AutoCompleteBox
                            loading={dataLoading}
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') { inputrefs.current['txtZipCode'].focus() };
                            }}
                            label='Consignee Name'
                            placeholder="Consignee Name"
                            maxLength='50'
                            options={ConsigneeData}
                            id='txtConsigneeName'
                            required={false}
                            onFocus={() => getConsigneeData()}
                            inputValue={recipe.Consignee ? recipe.Consignee : ''}
                            onInputChange={(event, newInputValue) => {
                                HandleChange('Consignee', newInputValue)

                            }}
                        />

                        <InputBox
                            inputrefs={inputrefs}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    getFormData()
                                };
                            }}
                            label='Consignee ZipCode'
                            id='txtZipCode'
                            maxLength='6'
                            placeholder="Consignee ZipCode"
                            value={recipe.ZipCode}

                            onChange={(e) => HandleChange('ZipCode', e.target.value.replace(/\D/g, ""))}
                        />

                        <FormButtonWithPrint
                            save={true}
                            Export={true}
                            reset={true}
                            buttonName='Search'
                            handleSubmit={getFormData}
                            handleExport={() => handleDownloadExcel(API_SERVER + 'api/Report/SeaCargoReport', ExportRequest, 'Sea Cargo Report')}
                            handleClear={handleClear}
                        />

                    </div>

                </div>
            </div>

            {/* Table Component start*/}
            <Table
                Token={TokenData}
                PageName='ReportQuery'
                handleEdit={() => alert('Edit')}
                handleDelete={() => alert('delete')}
                tableData={tableData}
                handleFilter={() => {
                    getFormData();
                }}
                //  loading={loading}
                filterData={filterData}
                handlePrint={handlePrint}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {/* Table Component End*/}


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}

        </>
    )

}
export default SeaCargoReport;
