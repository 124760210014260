import React, { useEffect, useRef, useState } from 'react'
import { InputBox, FormButton, FormButtonWithPrint, InputBoxWithLabel } from "../commoncomponents/InputBox";
import { useNavigate, } from 'react-router-dom';



import { useAuth } from '../../context/auth';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER  } from '../../Utils';

//services
import { post } from '../../apiservices/service';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
function ChangePassword() {
    const inputrefs = useRef([]);
    const { user, logout } = useAuth();
    const navigate = useNavigate()
   
    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }
    //initial State
    const [recipe, setRecipe] = useState({ OldPassword: '', NewPassword: '', ConfirmPassword:'' })
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        inputrefs.current['txtOldPassword'].focus();
        document.title = 'Nihao: Change Password'
    }, [])

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe,[prop]: value }))
    }
    const handleTogglePassword = (field) => {
        setShowPassword((prevShowPassword) => ({ ...prevShowPassword, [field]: !prevShowPassword[field] }));
    };
    //Handle Submit
    const handleSubmit = () => {
            setLoading(true)
            post(`${API_SERVER}api/User/ChangePassword`, { ...recipe, ...TokenData }, (res) => {
                // console.log(res);
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        inputrefs.current[res.focus].focus()
                        setLoading(false)
                     
                    } else {
                        setLoading(false)
                       // toast.success(res.message);
                        logout()   
                        handleClear()
                    }
                }
            });
        setLoading(false)
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({ ...recipe, OldPassword: '', NewPassword: '', ConfirmPassword: '' }))
    }

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Change Password</h6>
                </div>
                <div className='card-body d-flex'>
                    <div className='row'>
                        <div className="col-md-6">
                        {/*    <InputBoxWithLabel*/}
                               
                        {/*    inputrefs={inputrefs}*/}
                        {/*    onKeyDown={(e) => {*/}
                        {/*        if (e.key === 'Enter') { inputrefs.current['txtNewPassword'].focus() };*/}
                        {/*    }}*/}
                        {/*    label='Old Password'*/}
                        {/*    id='txtOldPassword'*/}
                        {/*    maxLength='15'*/}
                        {/*    required*/}
                        {/*    type='password'*/}
                        {/*    placeholder="Old Password"*/}
                        {/*    value={recipe.OldPassword}*/}
                        {/*    onChange={(e) => HandleChange('OldPassword',e.target.value) }*/}
                        {/*/>*/}
                    
                            <div className='col-md-5 lg-5 mb-1 '>
                                <label className="form-label" htmlFor='txtArea'>Old Password <span style={{ color: "red" }}> *</span></label>
                                <div className="d-flex" style={{ position: "relative" }}>
                                    <input
                                        ref={ref => (inputrefs.current['txtOldPassword'] = ref)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                inputrefs.current['txtNewPassword'].focus();
                                            }
                                        }}
                                        type={showPassword.OldPassword ? 'text' : 'password' }
                                        label='Old Password'
                                        id='txtOldPassword'
                                        style={{ borderRadius: "5px" }}
                                        autoComplete='off'
                                        className='form-control'
                                        maxLength='15'
                                        placeholder="Old Password"
                                        value={recipe.OldPassword}
                                        onChange={(e) => HandleChange('OldPassword', e.target.value)}
                                    />
                                    <span style={{ position: "relative", right: "22px", top: "3px", cursor: "pointer" }} onClick={() => handleTogglePassword('OldPassword')}>
                                        {showPassword.OldPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>
                                </div>
                            </div>

                            {/*<InputBoxWithLabel*/}
                            {/*inputrefs={inputrefs}*/}
                            {/*onKeyDown={(e) => {*/}
                            {/*    if (e.key === 'Enter') { inputrefs.current['txtConfirmPassword'].focus() };*/}
                            {/*}}*/}
                            {/*label='New Password'*/}
                            {/*id='txtNewPassword'*/}
                            {/*maxLength='15'*/}
                            {/*placeholder="New Password"*/}
                            {/*required*/}
                            {/*type='password'*/}
                            {/*value={recipe.NewPassword}*/}
                            {/*onChange={(e) => HandleChange('NewPassword', e.target.value)}*/}
                            {/*/>*/}

                            <div className='col-md-5 lg-5 mb-1 '>
                                <label className="form-label" htmlFor='txtArea'>New Password <span style={{ color: "red" }}> *</span></label>
                                <div className="d-flex" style={{ position: "relative" }}>
                                    <input
                                        ref={ref => (inputrefs.current['txtNewPassword'] = ref)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                inputrefs.current['txtConfirmPassword'].focus();
                                            }
                                        }}
                                        type={showPassword.NewPassword ? 'text' : 'password'}
                                        label='New Password'
                                        id='txtNewPassword'
                                        autoComplete='off'
                                        className='form-control'
                                        maxLength='15'
                                        style={{ borderRadius: "5px" }}
                                        placeholder="New Password"
                                        value={recipe.NewPassword}
                                        onChange={(e) => HandleChange('NewPassword', e.target.value)}
                                    />
                                    <span style={{ position: "relative", right: "22px", top: "3px", cursor: "pointer" }} onClick={() => handleTogglePassword('NewPassword')}>
                                        {showPassword.NewPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>

                                </div>
                            </div>
                            <div className='col-md-5 lg-5 mb-1 '>
                                <label className="form-label" htmlFor='txtArea'>Confirm New Password <span style={{ color: "red" }}> *</span></label>
                                <div className="d-flex" style={{ position: "relative" }}>
                                    <input
                                        ref={ref => (inputrefs.current['txtConfirmPassword'] = ref)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleSubmit()
                                            }
                                        }}
                                        type={showPassword.ConfirmPassword ? 'text' : 'password'}
                                        label='Confirm New Password'
                                        id='txtConfirmPassword'
                                        autoComplete='off'
                                        className='form-control'
                                        maxLength='15'
                                        style={{ borderRadius:"5px" }}
                                        placeholder="Confirm New Password"
                                        value={recipe.ConfirmPassword}
                                        onChange={(e) => HandleChange('ConfirmPassword', e.target.value)}
                                    />
                                    <span style={{ position: "relative", right: "22px", top: "3px", cursor: "pointer" }} onClick={() => handleTogglePassword('ConfirmPassword')}>
                                        {showPassword.ConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>


                                </div>
                            </div>

                        {/*    <InputBoxWithLabel*/}
                        {/*    inputrefs={inputrefs}*/}
                        {/*    label='Confirm New Password'*/}
                        {/*    id='txtConfirmPassword'*/}
                        {/*    maxLength='15'*/}
                        {/*    required*/}
                        {/*    type='password'*/}
                        {/*    placeholder="Confirm New Password"*/}
                        {/*    value={recipe.ConfirmPassword}*/}
                        {/*    onChange={(e) => HandleChange('ConfirmPassword', e.target.value)}*/}
                        {/*/>*/}

                        <FormButtonWithPrint
                            save={true}
                            print={false }
                            reset={true} 
                            btn_icon='fa-solid fa-clock-rotate-left'
                            buttonName='Change Password'
                            handleSubmit={handleSubmit}
                            handleClear={handleClear}
                            />
                        </div>

                        <div className="col-md-5 d-flex ">

                            <div className=' col-sm-12  password-policy-border  border border-1 p-0 mx-4 my-sm-2' style={{ height: "fit-content" }}>
                                <div className=' py-2 password-policy px-3' style={{ backgroundColor: "#F8F9FA" }}>
                                    <h6 className="m-0 font-weight-bold text-black">{'Password Policy'}</h6>
                                </div>
                                <div className='p-4'>
                                    <div className="form-group px-2">
                                        <li>White spaces are not allowed.</li>
                                        <li>Password must be between 8 to 15 character(s).</li>
                                        <li>Password must be combination of atleast one numeric and one upper case letter,one lower Case letter  and Special character [@@$#!() ]</li>
                                        <li>Old Password and New Password Can not be Same</li>
                                    </div>
                                </div>


                            </div>
                        </div>
                </div>
                </div>
            </div>


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>
    )
}

export default ChangePassword;