import { useEffect, useState, useRef } from "react";
import { Link, Navigate } from 'react-router-dom';

//react toast 
import { toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
//custom images
import sipllogo from '../../assests/images/sipllogo.png';
import loginpage from '../../assests/images/loginpage.jpg';
import nihaologo from '../../assests/images/nihaologo.png'
//loder
import BlurLoader from "../Loder/BlurLoader";
//services
import { useAuth } from "../../context/auth";
import { post } from "../../apiservices/service";
import { API_SERVER } from "../../Utils";

const Login = () => {
    const inputrefs = useRef([]);
    const { login, user } = useAuth();
const [showPassword, setShowPassword] = useState(false);

    //initial state
    const [recipe, setRecipe] = useState({ UserCode: '', Password:'' })
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!user) {
            inputrefs.current['txtUserId'].focus();
            document.title = 'Nihao: Login'
        }
    }, [loading, user])


    //Recipe Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({...recipe, [prop] : value }))
    }
    const handleTogglePassword = (field) => {
        setShowPassword((prevShowPassword) => ({ ...prevShowPassword, [field]: !prevShowPassword[field] }));
    };
    //validation handler
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "UserCode", msg: 'Please Enter User ID.' },
            { field: "Password", msg: 'Please Enter Password.' },
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    //submit button Handler
    const HandleSubmit = () => {
        if (Validate()) {
            setLoading(true)
            post(`${API_SERVER}api/UserLogin/Login`, { ...recipe, AuthToken: "der324k343%@923041!" }, (res) => {
                // console.log(res);
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        setRecipe(recipe => ({ ...recipe, UserCode: '', Password:'' }))
                        setLoading(false)
                    } else {
                        setLoading(false)
                        toast.success(res.message)
                        login(res)
                    }
                }
            });
        }   
    }

    if (user) {
        return <Navigate to="/Dashboard" />
    } else {
        return (
            <>
                {/* Login Form Start */}
                <div className='login-page login-bg'>
                    <div className="container-fluid">
                        <div className='row justify-content-center' style={{ height: '100vh' }}>

                            <div className='col-lg-8 col-md-7 col-xl-8 p-0' >
                                <div className="d-flex align-items-center justify-content-center log_height" >
                                    <img className="login-image" src={loginpage} alt=''  />
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-5 col-xl-4 bg-white align-items-center justify-content-between d-flex flex-column'>

                                <div className='login-content'>
                                    <div className='text-center'><img className='mb-2 sampark-logo' src={nihaologo} alt='Nihao logo' /></div>
                                    <h5 className='fs-5 mb-3 text-center'>Login</h5>

                                    <div className="form-group text-start mb-3" >
                                        <label className="form-label mb-1">User ID <span className='required'>*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="floatingInput"
                                            ref={ref => (inputrefs.current['txtUserId'] = ref)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtPassword'].focus() };
                                            }}
                                            placeholder="User ID"
                                            name='UserCode'
                                            value={recipe.UserCode}
                                            onChange={(e) => HandleChange('UserCode', e.target.value)}
                                        />

                                        {errors.UserCode && <div className="error-msg">{errors.UserCode}</div>}

                                    </div>

                                    <div className="form-group text-start mb-3 mt-2" style={{ position: "relative", }}>
                                        <label className="form-label mb-1">Password <span className='required'>*</span></label>
                                        <input
                                            type={showPassword.Password ? 'text' : 'password'}
                                            className="form-control"
                                            id="floatingPassword"
                                            ref={ref => (inputrefs.current['txtPassword'] = ref)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['btnSubmit'].focus() };
                                            }}
                                            placeholder="Password"
                                            name='Password'
                                            value={recipe.Password}
                                            onChange={(e) => HandleChange('Password', e.target.value)}
                                        />
                                        <span style={{ position: "absolute", left: "90%", bottom: "10%", cursor: "pointer" }} onClick={() => handleTogglePassword('Password')}>
                                            {showPassword.Password ? <FaEyeSlash /> : <FaEye />}
                                        </span>
                                        {errors.Password && <div className="error-msg">{errors.Password}</div>}

                                    </div>

                                    <div className='d-flex justify-content-between login-trouble mb-3'>
                                        <Link to='/forgotPassword'>Forgot Password</Link>
                                        {/* <Link to='/'>OTP Login</Link>*/}
                                    </div>

                                    <button
                                        ref={ref => (inputrefs.current['btnSubmit'] = ref)}
                                        className='btn-primary btn w-100'
                                        onClick={HandleSubmit}
                                    >
                                        Sign in
                                    </button>
                                </div>
                                <div className='sagar_logo text-center d-flex flex-column mb-5'>
                                    <img src={sipllogo} alt='sagar logo' />
                                    <Link to="https://www.sagarinfotech.com/" className='small' target='_blank'>Sagar Informatics Pvt. Ltd.</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Login Form End */}

                {/* Loder start*/}
                {loading && < BlurLoader />}
                {/* Loder End*/}
            </>

        )
    }
}

export default Login;