import React, { useEffect, useRef, useState } from 'react'
import { post } from '../../apiservices/service';
import { API_SERVER } from '../../Utils';
import { InputBox, FormButton, FormButtonWithPrint, InputBoxWithLabel, AutoCompleteBoxWithLabel } from "../commoncomponents/InputBox";
import { toast } from 'react-toastify';
import { useAuth } from '../../context/auth';

//loder
import BlurLoader from "../Loder/BlurLoader";

import { getCommonData } from "../../apiservices/CommonServices";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
function ResetPassword() {
    const { user } = useAuth();
    const inputrefs = useRef([]);
    const [dataLoading, setDataLoading] = useState(false)
    const [UserAllData, setUserAllData] = useState([])
    const [UserIDPre, setUserIDPre] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }

    //initial State
    const [recipe, setRecipe] = useState({ UserID: '', NewPassword: '', ConfirmPassword:'' })
    const [loading, setLoading] = useState(false);
    

    //change handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop] : value }))
    }
    const handleTogglePassword = (field) => {
        setShowPassword((prevShowPassword) => ({ ...prevShowPassword, [field]: !prevShowPassword[field] }));
    };
    const UserData = () => {
        let temp = {
            Type: "GetUserCode",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setUserAllData([])
                    setDataLoading(false)
                } else {
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setUserAllData(data)
                    setDataLoading(false)
                }
            }
        });
    }

    const UserList = [];
    for (let i = 0; i < UserAllData?.length; i++) {
        let USERID = (UserAllData[i]?.values)
        UserList?.push(USERID)
    };

    useEffect(() => {

        inputrefs.current['txtUserID'].children[0].children[0].focus();
        document.title = 'Nihao: Reset Password'
    }, [])

    //HandleSubmit
    const HandleSubmit = () => {
            setLoading(true)
            post(`${API_SERVER}api/user/ResetPassword`, { ...recipe, ...TokenData }, (res) => {
                // console.log(res);
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        toast.error(res.message)
                        if (res.focus === 'txtUserID') {
                            inputrefs.current[res.focus].children[0].children[0].focus();
                        }  else {
                            inputrefs.current[res.focus].focus()

                        }
                        setLoading(false)
                    } else {
                        setLoading(false)
                        if (res.focus) {
                            inputrefs.current[res.focus].focus();
                        }
                        toast.success(res.message)
                        handleClear()
                    }
                }
            });
        setLoading(false)
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({ ...recipe, UserID: '', NewPassword: '', ConfirmPassword: '' }))
    }

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Reset Password</h6>
                </div>
                <div className='card-body d-flex'>
                    <div className='row'>
                        <div className="col-md-6">
                            <AutoCompleteBoxWithLabel
                                className="col-md-7 lg-7 mb-1"
                                loading={dataLoading}
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtNewPassword'].focus() };
                                }}
                                label='User'
                                placeholder="User"
                                maxLength='100'
                                options={UserList}
                                id='txtUserID'
                                required={true}
                                onFocus={() => UserData()}
                                inputValue={recipe.UserID ? recipe.UserID : ''}
                                onInputChange={(event, newInputValue) => {
                                    HandleChange('UserID', newInputValue)
                                    setUserIDPre(newInputValue)

                                }}
                            />

                            {/*<InputBoxWithLabel*/}
                            {/*onKeyDown={(e) => {*/}
                            {/*    if (e.key === 'Enter') { inputrefs.current['txtConfirmPassword'].focus() };*/}
                            {/*}}*/}
                            {/*inputrefs={inputrefs}*/}
                            {/*label='New Password'*/}
                            {/*id='txtNewPassword'*/}
                            {/*type='password'*/}
                            {/*maxLength='15'*/}
                            {/*placeholder="New Password"*/}
                            {/*value={recipe.NewPassword}*/}
                            {/*onChange={(e) => HandleChange('NewPassword',e.target.value)}*/}
                            {/*/>*/}
                            <div className='col-md-4 lg-4 mb-1 '>
                                <label className="form-label" htmlFor='txtArea'> New Password <span style={{ color: "red" }}> *</span></label>
                                
                                    <div className="d-flex " style={{ position: "relative", width: "112%" }}>
                                    <input
                                        ref={ref => (inputrefs.current['txtNewPassword'] = ref)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                if (e.key === 'Enter') { inputrefs.current['txtConfirmPassword'].focus() }
                                            }
                                        }}
                                        type={showPassword.NewPassword ? 'text' : 'password'}
                                        label=' New Password'
                                        id='txtNewPassword'
                                        autoComplete='off'
                                        className='form-control'
                                        maxLength='15'
                                        style={{ borderRadius: "5px" }}
                                        placeholder=" New Password"
                                        value={recipe.NewPassword}
                                        onChange={(e) => HandleChange('NewPassword', e.target.value)}
                                    />
                                    <span style={{ position: "relative", right: "22px", top: "3px", cursor: "pointer" }} onClick={() => handleTogglePassword('NewPassword')}>
                                        {showPassword.NewPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>

                                </div>
                            </div>

                            <div className='col-md-4 lg-4 mb-1 '>
                                <label className="form-label" htmlFor='txtArea'>Confirm New Password <span style={{ color: "red" }}> *</span></label>
                                <div className="d-flex " style={{ position: "relative", width: "112%" }}>
                                    <input
                                        ref={ref => (inputrefs.current['txtConfirmPassword'] = ref)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                HandleSubmit()
                                            }
                                        }}
                                        type={showPassword.ConfirmPassword ? 'text' : 'password'}
                                        label='Confirm New Password'
                                        id='txtConfirmPassword'
                                        autoComplete='off'
                                        className='form-control'
                                        maxLength='15'
                                        style={{ borderRadius: "5px" }}
                                        placeholder="Confirm New Password"
                                        value={recipe.ConfirmPassword}
                                        onChange={(e) => HandleChange('ConfirmPassword', e.target.value)}
                                    />
                                    <span style={{ position: "relative", right: "22px", top: "3px", cursor: "pointer" }} onClick={() => handleTogglePassword('ConfirmPassword')}>
                                        {showPassword.ConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>


                                </div>
                            </div>
                        {/*    <InputBoxWithLabel*/}
                        {/*    inputrefs={inputrefs}*/}
                        {/*    onKeyDown={(e) => {*/}
                        {/*        if (e.key === 'Enter') { HandleSubmit() };*/}
                        {/*    }}*/}
                        {/*    label='Confirm New Password'*/}
                        {/*    id='txtConfirmPassword'*/}
                        {/*    maxLength='15'*/}
                        {/*    type='password'*/}
                        {/*    placeholder="Confirm New Password"*/}
                        {/*    value={recipe.ConfirmPassword}*/}
                        {/*    onChange={(e) => HandleChange('ConfirmPassword',e.target.value)}*/}
                        {/*/>*/}
                            <FormButtonWithPrint
                                save={true}
                                print={false}
                                reset={true}
                                btn_icon='fa-solid fa-clock-rotate-left'
                                buttonName='Reset Password'
                                handleSubmit={HandleSubmit}
                                handleClear={handleClear}
                            />
                    </div>
                 
                    <div className="col-md-5 d-flex ">

                        <div className=' col-sm-12  password-policy-border  border border-1 p-0 mx-4 my-sm-2' style={{ height: "fit-content" }}>
                            <div className=' py-2 password-policy px-3' style={{ backgroundColor: "#F8F9FA" }}>
                                <h6 className="m-0 font-weight-bold text-black">{'Password Policy'}</h6>
                            </div>
                            <div className='p-4'>
                                <div className="form-group px-2">
                                    <li>White spaces are not allowed.</li>
                                    <li>Reset Password must be between 8 to 15 character(s).</li>
                                    <li>Reset Password must be combination of atleast one numeric and one upper case letter,one lower Case letter and Special character [@@$#!() ]</li>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                      
                </div>
            </div>

            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>
    )
}

export default ResetPassword;