import axios from "axios";

const post = (url, payload, onDone) => {
    axios({
        method: "post",
        url: url,
        data: payload
    }).then(res => {
        onDone(res.data);

    }).catch(error => {
        if (error.response) {
            console.log(error.response)
        }
    });
};


export { post };
