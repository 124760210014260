import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { FormButton, SelectInputBox } from "../commoncomponents/InputBox";

//toast
import { toast } from 'react-toastify';

//user auth
import { useAuth } from '../../context/auth';

//loder
import BlurLoader from "../Loder/BlurLoader";

//services
import { getMasterData } from "../../apiservices/CommonServices";
import { post } from "../../apiservices/service";
import { API_SERVER } from "../../Utils";

function GroupRights() {
    const inputrefs = useRef([]);
    const { user } = useAuth();

    //initial states
    const [CheckView, setCheckView] = useState(false);
    const [CheckEdit, setCheckEdit] = useState(false);
    const [loading, setLoading] = useState(false)
    const [CheckDel, setCheckDel] = useState(false);
    const [CheckAdd, setCheckAdd] = useState(false);
    const [CheckExp, setCheckExp] = useState(false);
    const [CheckAll, setCheckAll] = useState(false);
    const [checkByFolderName, setCheckByFolderName] = useState(false);
    const [UserGroupIDPre, setUserGroupIDPre] = useState('');
    const [UserGroupDataArr, setUserGroupDataArr] = useState([])
    const [recipe, setRecipe] = useState({
        UserGroupID:""
    })
    const [formData, setFormData] = useState([])
    const [dataLoading, setDataLoading] = useState(false)

    //auth Token
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }

    let Request = {
        data: formData,
    
    }

    let MPageID = '';

    //------------to get the data of Menus---------------------------------//
    const getData = async () => {
        setLoading(true)
        await post(`${API_SERVER}api/USER/GetMenuUserGroupRight`, { ...TokenData,...recipe }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setFormData([])
                    setLoading(false)
                    toast.error(res.message)
                } else if (res.status === 'SUCCESS') {
                    setFormData(res.getGroupUserRightsMenu)
                    setLoading(false)
                }
            }
        });
        setLoading(false)
    };

    //---------------------------to get user data-----------------------------------//
    const getUserGroupData = () => {
        let temp = {
            Type: "GetUserGroup"
        }
        setDataLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setUserGroupDataArr([])
                    setDataLoading(false)
                } else {
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setUserGroupDataArr(data)
                    setDataLoading(false)
                }
            }
        });
        setLoading(false)
    }

    useEffect(() => {
        inputrefs.current['txtUserGroupID'].focus()
        document.title = 'Nihao: Group Rights'
        getData()
    }, [recipe])


    //Handle Clear
    const handleClear = () => {
        setRecipe({ UserGroupID: "" })
        setCheckAll(false)
        setCheckAdd(false)
        setCheckEdit(false)
        setCheckDel(false)
        setCheckExp(false)
        setCheckView(false)
    }

    //-----------------------------------Handle Submit------------------------//
    const handleSubmit = () => {
        post(`${API_SERVER}api/user/InsertUpdateGroupUserRights`, { ...TokenData, ...Request, ...recipe, getGroupUserRightsMenu: formData }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    inputrefs.current[res.focus].focus()
                    toast.error(res.message)
                } else {
                    toast.success(res.message)
                    getData()
                    handleClear()
                }
            }
        });
    }


       //---------------------Handle View all--------------------------------------------//
        const ViewCheckAll = () => {
            if (CheckView === false) {
                for (let i = 0; i < formData.length; i++) {
                    const updatedData = [...formData];
                    updatedData[i]['view'] = true;
                    setFormData(updatedData);
                }
                setCheckView(true)
            } else if (CheckView === true) {
                setCheckView(false)
                for (let i = 0; i < formData.length; i++) {
                    const updatedData = [...formData];
                    updatedData[i]['view'] = false;
                    setFormData(updatedData);
                }
            }
    };


        //----------------------------Handle Add All ------------------------------------------//
        const AddCheckAll = () => {
            if (CheckAdd === false) {
                for (let i = 0; i < formData.length; i++) {
                    const updatedData = [...formData];
                    updatedData[i]['add'] = true;
                    setFormData(updatedData);
                }
                setCheckAdd(true)
            } else if (CheckAdd === true) {
                setCheckAdd(false)
                for (let i = 0; i < formData.length; i++) {
                    const updatedData = [...formData];
                    updatedData[i]['add'] = false;
                    setFormData(updatedData);
                }
            }
    };


        //-----------------------------Edit All----------------------------------------//
        const EditCheckAll = () => {
            if (CheckEdit === false) {
                for (let i = 0; i < formData.length; i++) {
                    const updatedData = [...formData];
                    updatedData[i]['edit'] = true;
                    setFormData(updatedData);
                }
                setCheckEdit(true)
            } else if (CheckEdit === true) {
                setCheckEdit(false)
                for (let i = 0; i < formData.length; i++) {
                    const updatedData = [...formData];
                    updatedData[i]['edit'] = false;
                    setFormData(updatedData);
                }
            }
        };


        //--------------------------------Handle Delete All----------------------------------------//
        const DelCheckAll = () => {
            if (CheckDel === false) {
                for (let i = 0; i < formData.length; i++) {
                    const updatedData = [...formData];
                    updatedData[i]['del'] = true;
                    setFormData(updatedData);
                }
                setCheckDel(true)
            } else if (CheckDel === true) {
                setCheckDel(false)
                for (let i = 0; i < formData.length; i++) {
                    const updatedData = [...formData];
                    updatedData[i]['del'] = false;
                    setFormData(updatedData);
                }
            }
    };


    //--------------------------------Handle Export All ---------------------------------------------------//
        const ExpCheckAll = () => {
            if (CheckExp === false) {
                for (let i = 0; i < formData.length; i++) {
                    const updatedData = [...formData];
                    updatedData[i]['exp'] = true;
                    setFormData(updatedData);
                }
                setCheckExp(true)
            } else if (CheckExp === true) {
                setCheckExp(false)
                for (let i = 0; i < formData.length; i++) {
                    const updatedData = [...formData];
                    updatedData[i]['exp'] = false;
                    setFormData(updatedData);
                }
            }
        };


        //-------------------------------------Handle Check All ----------------------------------------------//
        const AllCheckAll = () => {
            if (CheckAll === false) {
                for (let i = 0; i < formData.length; i++) {
                    const updatedData = [...formData];
                    updatedData[i]['view'] = true;
                    updatedData[i]['add'] = true;
                    updatedData[i]['del'] = true;
                    updatedData[i]['exp'] = true;
                    updatedData[i]['edit'] = true;
                    updatedData[i]['all'] = true;
                    setFormData(updatedData);
                }
                setCheckAll(true)
                setCheckAdd(true)
                setCheckEdit(true)
                setCheckView(true)
                setCheckDel(true)
                setCheckExp(true)

            } else if (CheckAll === true) {
                setCheckAll(false)
                setCheckAdd(false)
                setCheckEdit(false)
                setCheckDel(false)
                setCheckExp(false)
                setCheckView(false)
                for (let i = 0; i < formData.length; i++) {
                    const updatedData = [...formData];
                    updatedData[i]['view'] = false;
                    updatedData[i]['add'] = false;
                    updatedData[i]['del'] = false;
                    updatedData[i]['exp'] = false;
                    updatedData[i]['edit'] = false;
                    updatedData[i]['all'] = false;
                    setFormData(updatedData);
                }
            }
        };

        //------------------------------------Handle Check by folder Name------------------------------------------//
        const CheckByFolderNameCheckBox = (e, index, mPageID) => {

            for (let i = 0; i < formData.length; i++) {
                const updatedData = [...formData];
                if (updatedData[i]['mPageID'] === mPageID) {
                    if (e.target.checked === true) {
                        updatedData[i]['view'] = true;
                        updatedData[i]['add'] = true;
                        updatedData[i]['del'] = true;
                        updatedData[i]['exp'] = true;
                        updatedData[i]['edit'] = true;
                        updatedData[i]['all'] = true;
                    }
                    else {
                        updatedData[i]['view'] = false;
                        updatedData[i]['add'] = false;
                        updatedData[i]['del'] = false;
                        updatedData[i]['exp'] = false;
                        updatedData[i]['edit'] = false;
                        updatedData[i]['all'] = false;
                    }
                }
                setCheckByFolderName(e.target.checked);
                setFormData(updatedData);
            }
    }

    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

        

        const RightToLeftCheck = (e, index) => {
            if (e.target.checked === true) {
                formData[index].view = true
                formData[index].add = true
                formData[index].exp = true
                formData[index].del = true
                formData[index].edit = true
            } else {
                formData[index].view = false
                formData[index].add = false
                formData[index].exp = false
                formData[index].del = false
                formData[index].edit = false
            }
        };

        const handleCheckboxChange = (pageIndex, key, e) => {
            const updatedData = [...formData];
            updatedData[pageIndex][key] = !updatedData[pageIndex][key];
            setFormData(updatedData);
        };

        return (
            <>
                <div className='main-card card border-0'>
                    <div className='card-header mb-1'>
                        <h6 className="m-0">Group Rights</h6>
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            <SelectInputBox
                                label='User Group'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSubmit()
                                    };
                                }}
                                id='txtUserGroupID'
                                required={true}
                                maxLength='50'
                                onFocus={() => getUserGroupData()}
                                value={recipe.UserGroupID}
                                onChange={(e) => setRecipe({ ...recipe, UserGroupID: e.target.value })}
                            >
                                <option value=''>-Please Select-</option>
                                {dataLoading ? <option> Loading... </option>
                                    : UserGroupDataArr?.map((item, index) => {
                                        return (
                                            <option key={index} value={item.code}>{item.name}</option>
                                        )
                                    })
                                }
                            </SelectInputBox> 
                        </div>
                        <FormButton
                            id='btnSave'
                            inputrefs={inputrefs}
                            Export={false}
                            handleSubmit={handleSubmit}
                            handleClear={handleClear}
                        
                        />
                    </div>
                    <>
                        <div className='card-body'>
                       
                                <div className="table-responsive">
                                    {formData ?
                                        (
                                            <table className="table border">
                                                <thead>
                                                    <tr className='light-bg' key={'Header'}>
                                                        <th>Folder Name</th>
                                                        <th></th>
                                                        <th>Page</th>
                                                        <th><input type="checkbox" onChange={ViewCheckAll} checked={CheckView} />View</th>
                                                        <th><input type="checkbox" onChange={AddCheckAll} checked={CheckAdd} disabled={!CheckView} />Add</th>
                                                        <th><input type="checkbox" onChange={EditCheckAll} checked={CheckEdit} disabled={!CheckView} />Edit</th>
                                                        <th><input type="checkbox" onChange={DelCheckAll} checked={CheckDel} disabled={!CheckView} />Delete</th>
                                                        <th><input type="checkbox" onChange={ExpCheckAll} checked={CheckExp} disabled={!CheckView} />Export</th>
                                                        <th><input type="checkbox" onChange={AllCheckAll} checked={CheckAll} disabled={!CheckView} />CheckAll</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (formData)?.map((item, index) => (
                                                            <React.Fragment key={`${item.pageID}Validity`}>
                                                                {MPageID !== item.mPageID && index > 0 ?
                                                                    <tr key={`${item.pageID}Validity`} style={{ background: '#fae9b2' }}>
                                                                        <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr> : <></>}
                                                                <tr key={`${index}${item.menuName}`}>
                                                                    {MPageID !== item.mPageID ?
                                                                        <><td rowSpan={item.rowSpan}
                                                                            className="text-danger fs-6 fw-bold">
                                                                            {
                                                                                MPageID = item.mPageID,
                                                                                item.folderName === '' ? <></> : item.folderName
                                                                            }
                                                                        </td>
                                                                            <td rowSpan={item.rowSpan}>
                                                                                <span className="MM">
                                                                                    <input
                                                                                        name='check'
                                                                                        type="checkbox"
                                                                                        onClick={(e) => CheckByFolderNameCheckBox(e, index, item.mPageID)}
                                                                                    />
                                                                                </span>
                                                                            </td></>
                                                                        : <></>}
                                                                    <td>{item.menuName}</td>
                                                                    <td>
                                                                        <span className="MM">
                                                                            <input
                                                                                name='view'
                                                                                type="checkbox"
                                                                                checked={item.view}
                                                                                onChange={() => handleCheckboxChange(index, 'view')}
                                                                            />
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="MM">
                                                                            <input
                                                                                name='add'
                                                                                type="checkbox"
                                                                                checked={item.add}
                                                                                onChange={() => handleCheckboxChange(index, 'add')}
                                                                                disabled={!formData[index].view}
                                                                            />
                                                                        </span></td>
                                                                    <td>
                                                                        <span className="MM">
                                                                            <input
                                                                                name='edit'
                                                                                type="checkbox"
                                                                                checked={item.edit}
                                                                                onChange={() => handleCheckboxChange(index, 'edit')}
                                                                                disabled={!formData[index].view}
                                                                            />
                                                                        </span>
                                                                    </td>
                                                                    <td><span className="MM">
                                                                        <input
                                                                            name='del'
                                                                            type="checkbox"
                                                                            checked={item.del}
                                                                            onChange={() => handleCheckboxChange(index, 'del')}
                                                                            disabled={!formData[index].view}
                                                                        />
                                                                    </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="MM">
                                                                            <input
                                                                                name='exp'
                                                                                type="checkbox"
                                                                                checked={item.exp}
                                                                                onChange={() => handleCheckboxChange(index, 'exp')}
                                                                                disabled={!formData[index].view}
                                                                            />
                                                                        </span>
                                                                    </td>
                                                                    <td> <span className="MM">
                                                                        <input
                                                                            name='all'
                                                                            type="checkbox"
                                                                            checked={item.all}
                                                                            onClick={(e) => RightToLeftCheck(e, index)}
                                                                            onChange={() => handleCheckboxChange(index, 'all')}
                                                                            disabled={!formData[index].view}
                                                                        />
                                                                    </span>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        ) : (<></>)}
                                </div>
                             
                        </div>
                      
                    </>
                </div>

                {/* Loder start*/}
                {loading && < BlurLoader />}
                {/* Loder End*/}
            </>
        )
    }


export default GroupRights