import React, { useEffect, useState, useRef } from 'react';
import { InputBox, DateBox, AutoCompleteBox, SelectInputBox, FormButtonWithPrint } from "../commoncomponents/InputBox";
import { FileUploader } from "react-drag-drop-files";

import { useAuth } from '../../context/auth';

//react toast 
import { toast } from 'react-toastify';

//loder
import BlurLoader from "../Loder/BlurLoader";

//services
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import { post } from '../../apiservices/service';
import { API_SERVER, downloadPDF } from '../../Utils';

//import { Link } from 'react-router-dom';
//import { FaRegFilePdf } from "react-icons/fa6";

function PickupEntry() {
    const inputrefs = useRef([]);
    const { user } = useAuth();
    const [isAwbNumberEnabled, setIsAwbNumberEnabled] = useState(false);
    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }

    //initial States
    const [consignorDetails, setConsignorDetails] = useState({
        Consignor: "",
        Address1: "",
        Address2: "",
        Address3: "",
        Email: "",
        ConsignorCPerson: "",
        Zip: "",
        Country: "",
        State: "",
        City: "",
        Phone: "",
        GSTIN: "",
        ConsignorPanNo: "",
    })

    const [ConsigneeDetails, setConsigneeDetails] = useState({
        Consignee: "",
        ContactPerson: "",
        ConsigneeAddress1: "",
        ConsigneeAddress2: "",
        ConsigneeAddress3: "",
        ConsigneeEmail: "",
        ConsigneeZip: "",
        ConsigneeCountry: "",
        ConsigneeState: "",
        ConsigneeCity: "",
        ConsigneePhone: "",
        ConsigneeGSTIN: "",
        IECNo: "",
        ConsigneePan: ""
    })

    const [recipe, setRecipe] = useState({
        IsUpdate: false,
        OrderNo: "",
        ShipmentID: "",
        ReferenceNo: "",
        BDate: new Date(),
        Client: "",
        UploadInvoice: "",
        ImageName: "",
        ImageExt: "",
        Pieces: "",
        ActualWeight: "",
        TotalValue: "",
        CurrencyCode: "",
        Currency: "",
        WeightUnit: "",
        TotalWeight: "",
        TotalChargeWeight: "",
        TotalVolWeight: "",
        PerKgWeight: "",
        PacketType: "",
        PaymentType: "",
        UnitMeasurement:"",
        IsExportDeclartionCharge: false,
        IsVolWeight: false,
        Amount: ""
    })
  
    const [loading, setLoading] = useState(false)
    const [PinCodePre, setPinCodePre] = useState('')

    const [countryPre, setCountryPre] = useState('')
    const [statePre, setStatePre] = useState('')
    const [cityPre, setCityPre] = useState('')
    const [countryConsigneePre, setcountryConsigneePre] = useState('')
    const [pincodeConsigneePre, setpincodeConsigneePre] = useState('')
    const [stateconsigneePre, setStateConsigneePre] = useState('')
    const [cityConsigneePre, setCityConsigneePre] = useState('')
    const [countryAllData, setCountryAllData] = useState([])
    const [DescriptionData, setDescriptionData] = useState([])
    const [pincodeAllData, setPincodeAllData] = useState([])

    const [stateAllData, setStateAllData] = useState([])
    const [cityAllData, setCityAllData] = useState([])
    const [Length, setLength] = useState('')
    const [Breadth, setBreadth] = useState('')
    const [Height, setHeight] = useState('')
    const [VolWeight, setVolWeight] = useState('')
    const [WeightDetails, setWeightDetails] = useState([])
    const [myEditIndex, setmyEditIndex] = useState('')
    const [isEdit, setisEdit] = useState(false)
    const [packetdata, setPaketdata] = useState({})
    const [PacketDetails, setPacketDetails] = useState([])
    const [PaymentTypeArr, setPaymentTypeArr] = useState([])
    const [PacketTypeArr, setPacketTypeArr] = useState([])
    const [CurrencyMasterArr, setCurrencyMasterArr] = useState([])
    const [WeightUnitArr, setWeightUnitArr] = useState([])
    const [ClientDataArr, setClientDataArr] = useState([])
    const [ConsignorDataName, SetConsignorDataName] = useState([])
    const [ConsigneeDataName, setConigneeDataName] = useState([])
    const [StateConsigneeAllData, setStateConsigneeAllData] = useState([])
    const [CityConsigneeAllData, setCityConsigneeAllData] = useState([])
    const [isFormEdit, setisFormEdit] = useState(false)
    const [errors, setErrors] = useState({})
    const [dataLoading, setDataLoading] = useState(false);
    const [PacketDataLoading, setPacketDataLoading] = useState(false);
    const [PaymentDataLoading, setPaymentDataLoading] = useState(false);
    const [CurrencyDataLoading, setCurrencyDataLoading] = useState(false);
    const [WeightDataLoading, setWeightDataLoading] = useState(false);

    //---------------get Pincode data--------------------------//
    const PincodeData = () => {
        let temp = {
            Type: "GetPinCode",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setDataLoading(false)
                } else {
                    let data = res && res.dataList
                    setPincodeAllData(data)
                    setDataLoading(false)
                }
            }
        });
    }

    //----------------get country data-----------------------//
    const CountryData = () => {
        let temp = {
            Type: "GetCountry",
            Prefix: '',
            ContextKey: PinCodePre.split(':')[0].trim(),
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setDataLoading(false)
                } else {
                    let data = res.dataList
                    setCountryAllData(data)
                    setDataLoading(false)
                }
            }
        });
    }
    const GetDescription = () => {
        let temp = {
            Type: "GetDescription",
            Prefix: '',
            ContextKey: '',
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setDataLoading(false)
                } else {
                    let data = res.dataList
                    setDescriptionData(data)
                    setDataLoading(false)
                }
            }
        });
    }
    //-----------------get Country wise state--------------------------//
    const StateAllData = () => {
        setStateAllData([])
        let temp = {
            Type: "GetCountrywiseState",
            Prefix: '',
            ContextKey: countryPre.split(':')[0].trim(),
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setStateAllData([])
                    setDataLoading(false)
                } else {
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setStateAllData(data)
                    setDataLoading(false)
                }
            }
        });
    }

    //-----------------------get consignee all city data--------------------------//
    const CityAllData = () => {
        let temp = {
            Type: "GetCountryStateWiseCity",
            Prefix: '',
            ContextKey: countryPre.split(':')[0].trim(),
            ContextKey2: statePre.split(':')[0].trim()
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setCityAllData([])
                    setDataLoading(false)
                } else {
                    let data = res.dataList
                    setCityAllData(data)
                    setDataLoading(false)
                }
            }
        });
    }


    //-----------------get consignee Country wise state--------------------------//
    const StateConsigneeData = () => {
        setStateConsigneeAllData([])
        let temp = {
            Type: "GetCountrywiseState",
            Prefix: '',
            ContextKey: countryConsigneePre.split(':')[0].trim(),
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setDataLoading(false)
                    setStateConsigneeAllData([])
                } else {
                    setDataLoading(false)
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setStateConsigneeAllData(data)
                }
            }
        });
    }

    //-----------------------get State wise city data--------------------------//
    const CityConsigneeData = () => {
        setCityConsigneeAllData([])

        let temp = {
            Type: "GetCountryStateWiseCity",
            Prefix: '',
            ContextKey: countryConsigneePre.split(':')[0].trim(),
            ContextKey2: stateconsigneePre.split(':')[0].trim()

        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setDataLoading(false)
                    setCityConsigneeAllData([])
                } else {
                    setDataLoading(false)
                    let data = res.dataList
                    setCityConsigneeAllData(data)
                }
            }
        });
    }

    //------------------------------get Payment Type Data-----------------------------//
    const getPaymentTypeData = () => {
        setPaymentTypeArr([])
        let temp = {
            Type: "PaymentType",
        }
        setPaymentDataLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setPaymentTypeArr([])
                    setPaymentDataLoading(false)
                } else {
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setPaymentTypeArr(data)
                    setPaymentDataLoading(false)
                }
            }
        });
    }

    //---------------------------get Packet Type Data----------------------------//
    const getPacketTypeData = () => {
        setPacketTypeArr([])
        let temp = {
            Type: "PacketType",
        }
        setPacketDataLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setPacketTypeArr([])
                    setPacketDataLoading(false)
                } else {
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setPacketTypeArr(data)
                    setPacketDataLoading(false)
                }
            }
        });
    }

    //------------------------get Currency Data-------------------------------//
    const getCurrencyData = () => {
        setCurrencyMasterArr([])
        let temp = {
            Type: "GetCurrencyMaster",
        }
        setCurrencyDataLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setCurrencyMasterArr([])
                    setCurrencyDataLoading(false)
                } else {
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setCurrencyMasterArr(data)
                    setCurrencyDataLoading(false)
                }
            }
        });
    }

    //------------------------Get Weight Unit Data----------------------------
    const getWeightUnitData = () => {
        setWeightUnitArr([])
        let temp = {
            Type: "GetWeightUnit",
        }
        setWeightDataLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setWeightUnitArr([])
                    setWeightDataLoading(false)
                } else {
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setWeightUnitArr(data)
                    setWeightDataLoading(false)
                }
            }
        });
    }

    //------------------------To Get Client Data----------------------//
    const getClientData = () => {
        let temp = {
            Type: "GetClient",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setClientDataArr([])
                    setDataLoading(false)
                } else {
                    let data = res.dataList
                    setClientDataArr(data)
                    setDataLoading(false)
                }
            }
        });
    }
    const getConsignorDataFromName = () => {
        let temp = {
            Type: "GetConsignor",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    SetConsignorDataName([])
                    setDataLoading(false)
                } else {
                    let data = res.dataList
                    SetConsignorDataName(data)
                    setDataLoading(false)
                }
            }
        });
    }
    const getConsigneeDataFromName = () => {
        let temp = {
            Type: "GetConsignee",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setConigneeDataName([])
                    setDataLoading(false)
                } else {
                    let data = res.dataList
                    setConigneeDataName(data)
                    setDataLoading(false)
                }
            }
        });
    }
    //volume greater function
    //const HandleTotalVolWeightChange = (e) => {
    //    console.log(recipe.TotalWeight, "total")
    //    console.log(recipe.totalVolWeight, "vol")
    //    const newTotalVolWeight = Number(e.target.value);
    //    const newTotalWeight = Number(recipe.TotalWeight);

    //    if (newTotalVolWeight > newTotalWeight) {

    //        const newTotalChargeWeight = newTotalVolWeight;
    //        setRecipe({ ...setRecipe, TotalChargeWeight: newTotalChargeWeight });
    //    } else {
    //        setRecipe({ ...setRecipe, TotalVolWeight: newTotalVolWeight });
    //    }
    //};


    useEffect(() => {
        getPacketTypeData()
        getPaymentTypeData()
        getCurrencyData()
        getWeightUnitData()
        inputrefs.current['txtReferenceNo'].focus();
        document.title = 'Nihao: Pickup Entry'
    }, [])

    useEffect(() => {
        let totalvolweight = WeightDetails.reduce((total, item) => {
            const volWeight = parseFloat(item.VolWeight);
            return isNaN(volWeight) ? total : total + volWeight;
        }, 0);

        setRecipe({ ...recipe, TotalVolWeight: totalvolweight.toFixed(4) }); // Adjust the number of decimal places as needed
    }, [WeightDetails]);

    //useEffect(() => {
    //    let AllPackettotal = PacketDetails.map(item =>parseFloat(item.Total)).reduce((prev, curr) => prev + curr);
    //    setRecipe({ ...recipe, OverAllTotal: AllPackettotal })
    //}, [PacketDetails])
    useEffect(() => {
        let AllPackettotal = PacketDetails.map(item => parseFloat(item.Total)).reduce((prev, curr) => prev + curr, 0);

        // Setting decimal precision to 2
        AllPackettotal = parseFloat(AllPackettotal.toFixed(2));

        setRecipe({ ...recipe, OverAllTotal: AllPackettotal, TotalValue: AllPackettotal > recipe.TotalValue ? AllPackettotal : recipe.TotalValue.toString() });
    }, [PacketDetails]);

    useEffect(() => {
        if (recipe && recipe.IsUpdate) {
            inputrefs.current['txtOrderNumber'].focus()
        } else {
            inputrefs.current['txtReferenceNo'].focus()
        }
    }, [isFormEdit])


    //---------------------------getConsigneeData------------------------------//
    

    //-----------------get Client Data---------------------------//

    const getConsigneeDataByClient = (Client , type) => {
        //  setLoading(true)
        
        post(`${API_SERVER}api/Operation/GetConsigneeDetailsClientWise`, { ...TokenData, Client: Client ? Client : '', Type: type }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setConsigneeDetails({
                        ...ConsigneeDetails,
                        Consignee: '',
                        ContactPerson: '',
                        ConsigneeAddress1: '',
                        ConsigneeAddress2: '',
                        ConsigneeAddress3: '',
                        ConsigneeEmail: '',
                        ConsigneeZip: '',
                        ConsigneeCountry: '',
                        ConsigneeState: '',
                        ConsigneeCity: '',
                        ConsigneePhone: '',
                        ConsigneeGSTIN: ''
                    })
                } else {
                    let data = res.data
                    setcountryConsigneePre(data.country)
                    setStateConsigneePre(data.state)
                    setConsigneeDetails({
                        ...ConsigneeDetails,
                        Consignee: data.consigneeName,
                        ContactPerson: data.consigneeCPerson,
                        ConsigneeAddress1: data.consigneeAddress1,
                        ConsigneeAddress2: data.consigneeAddress2,
                        ConsigneeAddress3: data.consigneeAddress3,
                        ConsigneeEmail: data.consigneeEmail,
                        ConsigneeZip: data.consigneePinCode,
                        ConsigneeCountry: data.consigneeCountry,
                        ConsigneeState: data.consigneeState,
                        ConsigneeCity: data.consigneeCity,
                        ConsigneePhone: data.consigneeMobileNumber,
                        ConsigneeGSTIN: data.consigneeGSTIN,
                        ConsigneePan: data.consigneePANNO,
                        IECNo: data.consigneeIECNO, 
                    })
                    // setLoading(false)
                }
            }
        });
    }

    //---------------------------getConsignorData------------------------------//
    const getConsignorDetailsData = (name, type) => {
        let temp = {
            Type: "Consignor",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        //setLoading(true)
        post(`${API_SERVER}api/Operation/GetConsignorConsigneeDetail`, { ...TokenData, ClientCCName: name, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    //setLoading(false)
                    setConsignorDetails({
                        ...consignorDetails,
                        Consignor:name,
                        ConsignorCPerson: '',
                        Address1: '',
                        Address2: '',
                        Address3: '',
                        Email: '',
                        Zip: '',
                        Country: '',
                        State: '',
                        City: '',
                        Phone: '',
                        GSTIN: ''

                    })
                } else {
                    let data = res.data
                    setCountryPre(data.country)
                    setStatePre(data.state)
                    setConsignorDetails({
                        ...consignorDetails,
                        Consignor: name,
                        ConsignorCPerson: data.contactPerson,
                        Address1: data.address1,
                        Address2: data.address2,
                        Address3: data.address3,
                        Email: data.email,
                        Zip: data.zip,
                        Country: data.country,
                        State: data.state,
                        City: data.city,
                        Phone: data.phone,
                        GSTIN: data.gstin
                    })
                    //setLoading(false)
                }
            }
        });
    }

    const getConsigneeDetailsData = (name, type) => {
        let temp = {
            Type: "Consignee",
            Prefix: '',
            ContextKey: "",
            ContextKey2: ""
        }
        //setLoading(true)
        post(`${API_SERVER}api/Operation/GetConsignorConsigneeDetail`, { ...TokenData, ClientCCName: name, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    //setLoading(false)
                    setConsigneeDetails({
                        ...ConsigneeDetails,
                        Consignee: name,
                        ContactPerson: '',
                        ConsigneeAddress1: '',
                        ConsigneeAddress2: '',
                        ConsigneeAddress3: '',
                        ConsigneeEmail: '',
                        ConsigneeZip: '',
                        ConsigneeCountry: '',
                        ConsigneeState: '',
                        ConsigneeCity: '',
                        ConsigneePhone: '',
                        ConsigneeGSTIN: '',
                        ConsigneePan:''
                        
                    })
                } else {
                    let data = res.data
                    setCountryPre(data.country)
                    setStatePre(data.state)
                    setConsigneeDetails({
                        ...ConsigneeDetails,
                        Consignee: name,
                        ContactPerson: data.contactPerson,
                        ConsigneeAddress1: data.address1 ,
                        ConsigneeAddress2: data.address2,
                        ConsigneeAddress3: data.address3,
                        ConsigneeEmail: data.email,
                        ConsigneeZip: data.zip,
                        ConsigneeCountry: data.country,
                        ConsigneeState: data.state,
                        ConsigneeCity: data.city,
                        ConsigneePhone: data.phone,
                        ConsigneeGSTIN: data.gstin,
                       
                    })
                    //setLoading(false)
                }
            }
        });
    }

    //------------------Get City state by pincode--------------//
    const getCityStateByPincode = (Zip) => {
        //setLoading(true)
        post(`${API_SERVER}api/mASTER/GetPinCodeData`, { ...TokenData, Zip }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    //setLoading(false)
                    setConsignorDetails({
                        ...consignorDetails,
                        Country: "",
                        State: "",
                        City: "",


                    })
                } else {
                    let data = res.data
                    setPinCodePre(data.zip)
                    setCountryPre(data.country)
                    setStatePre(data.state)
                    setConsignorDetails({
                        ...consignorDetails,
                        Zip: data.zip,
                        Country: data.country,
                        State: data.state,
                        City: data.city,

                    })
                    //setLoading(false)
                }
            }
        });
    }

    const getHsnbyDescrition = () => {
        //setLoading(true)
        post(`${API_SERVER}api/Operation/GetHSNNoDescriptionWise`, { ...TokenData, ...packetdata }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setPaketdata({
                        ...packetdata,
                        HSNNO: "",
                    })
                } else {
                    let data = res.data
                    setPinCodePre(data.zip)

                    setPaketdata({
                        ...packetdata,
                        HSNNO: data.hsnNo,
                    })
                }
            }
        });
    }
    const getCityStateByPincodeConsignee = (Zip) => {
        //setLoading(true)
        post(`${API_SERVER}api/mASTER/GetPinCodeData`, { ...TokenData, Zip }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    //setLoading(false)
                    setConsigneeDetails({
                        ...ConsigneeDetails,
                        ConsigneeCountry: "",
                        ConsigneeState: "",
                        ConsigneeCity: "",
                    })
                } else {
                    let data = res.data
                    setpincodeConsigneePre(data.zip)
                    setcountryConsigneePre(data.country)
                    setStateConsigneePre(data.state)
                    setConsigneeDetails({
                        ...ConsigneeDetails,
                        ConsigneeZip: data.zip,
                        ConsigneeCountry: data.country,
                        ConsigneeState: data.state,
                        ConsigneeCity: data.city,

                    })
                    //setLoading(false)
                }
            }
        });
    }
    //useEffect(() => {
    //    // Calculate the maximum value between TotalWeight and TotalVolWeight
    //    const maxWeight = Math.max(recipe.TotalWeight, recipe.TotalVolWeight);

    //    // Set the calculated value to the Total Charge Weight field
    //    setTotalChargeWeight(maxWeight);
    //}, [recipe.TotalWeight, recipe.TotalVolWeight]);

    useEffect(() => {
        const maxWeight = Math.max(recipe.TotalWeight, recipe.TotalVolWeight);
        const totalChargeWeightString = maxWeight.toString();
        console.log(totalChargeWeightString.toString(), "tertgrtuert")
        setRecipe(prevRecipe => ({ ...prevRecipe, TotalChargeWeight: totalChargeWeightString }));
    }, [recipe.TotalWeight, recipe.TotalVolWeight]);


    //useEffect(() => {
    //    const maxWeight = Math.max(parseFloat(recipe.TotalWeight), parseFloat(recipe.TotalVolWeight));
    //    const totalChargeWeightString = maxWeight.toFixed(2);
    //    console.log(totalChargeWeightString, "tertgrtuert");
    //    setRecipe(prevRecipe => ({ ...prevRecipe, TotalChargeWeight: totalChargeWeightString }));
    //}, [recipe.TotalWeight, recipe.TotalVolWeight]);


    //useEffect(() => {
    //    const maxWeight = Math.max(recipe.TotalWeight, recipe.TotalVolWeight);
    //    const totalChargeWeightString = maxWeight.toFixed(2); 
    //    setRecipe(prevRecipe => ({ ...prevRecipe, TotalChargeWeight: totalChargeWeightString }));
    //}, [recipe.TotalWeight, recipe.TotalVolWeight]);




    //---------------Change Consignee Handler------------//
    const HandleConsigneeChange = (prop, value) => {
        setConsigneeDetails(ConsigneeDetails => ({ ...ConsigneeDetails, [prop]: value }))
    }

    //-----------Change Consignee Handler-----------------//
    const HandleConsignorChange = (prop, value) => {
        setConsignorDetails(consignorDetails => ({ ...consignorDetails, [prop]: value }))
    }

    //------------------------------handle Weight Detail start---------------------------//

    //validation handler
    const ValidatePacketDetails = () => {
        setErrors({});
        let isValid = true;
        let validation = [
            { field: "Description", msg: "Please Enter Description." },
           
        ];

        validation.forEach(item => {
            if (!packetdata[item.field] || packetdata[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
        });
        // Additional check for UnitValue if Units are provided
        if (packetdata.Units && packetdata.Units.length > 0 && (!packetdata.UnitValue || packetdata.UnitValue.length <= 0)) {
            setErrors(errors => ({ ...errors, UnitValue: "Please Enter UnitValue when Units are provided." }));
            isValid = false;
        }

        
        // if (packetdata.Units !== recipe.Pieces) {
        //     setErrors(errors => ({ ...errors, Units: 'Unit Value Should be Equal to Pieces' }));
        //     isValid = false;
        // }

        return isValid;
    };

    const HandlePacketData = (type, index) => {

        if (type === 'Edit') {
            if (ValidatePacketDetails()) {
                PacketDetails[index].Description = packetdata.Description;
                PacketDetails[index].HSNNO = packetdata.HSNNO;
                PacketDetails[index].Units = packetdata.Units;
                PacketDetails[index].UnitValue = packetdata.UnitValue;
                PacketDetails[index].Total = packetdata.Total || 0;
                setPacketDetails(PacketDetails => [...PacketDetails])
                setisEdit(false)
                setmyEditIndex('')
                setPaketdata({ ...packetdata, Description: "", HSNNO: "", Units: "", UnitValue: "", Total: "" })
            }
        } else {
            if (ValidatePacketDetails()) {
                setPacketDetails(PacketDetails => [...PacketDetails, {
                    ...packetdata,
                    Total: packetdata.Total || 0 
                }])
                setisEdit(false)
                setmyEditIndex('')
                setPaketdata({ ...packetdata, Description: "", HSNNO: "", Units: "", UnitValue: "", Total: "" })
            }

        }

    }

    //weight edit
    const HandlePacketEdit = (item, index) => {
        setmyEditIndex(index)
        setisEdit(true)
        setPaketdata({ ...packetdata, Description: item.Description, HSNNO: item.HSNNO, Units: item.Units, UnitValue: item.UnitValue, Total: item.Total })
    }

    //weight delete
    const HandlePacketDelete = (index) => {
        PacketDetails.splice(index, 1)
        setPacketDetails(PacketDetails => [...PacketDetails])
    }

    //reset Packet data
    const ResetPacketHandler = () => {
        setisEdit(false)
        setmyEditIndex('')
        setErrors({})
        setPaketdata({ ...packetdata, Description: "", HSNNO: "", Units: "", UnitValue: "", Total: '' })
    }
    //------------------------handle Packet Detail End-----------------------------//


    //-----------------handle Weight Detail start----------------------------------//

    //validation handler
    const ValidateWeigthDetails = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "Pieces", msg: "Please Enter Pieces." },
           
           
            { field: "ActualWeight", msg: "Please Enter Actual Weight." },
            { field: "WeightUnit", msg: "Please Select Weight Unit." }
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        if (WeightDetails && recipe.Pieces) {
            if (recipe.Pieces <= WeightDetails.length) {
                alert(`You cannot add more then ${recipe.Pieces} Pieces.`)
                isValid = false;
            }
        }


        return isValid;
    }

    const ValidateVolWeight = () => {
        setErrors({})
        let is_Valid = true;
        if (Length === '') {
            setErrors(errors => ({ ...errors, Length: 'Enter the Length' }));
            is_Valid = false
        }

        if (Breadth === '') {
            setErrors(errors => ({ ...errors, Breadth: 'Enter the Breadth' }));
            is_Valid = false
        }

        if (Height === '') {
            setErrors(errors => ({ ...errors, Height: 'Enter the Height' }));
            is_Valid = false
        }

        if (Length && Breadth && Height) {
            if (VolWeight === '') {
                setErrors(errors => ({ ...errors, VolWeight: 'Click on VolWeight Input to calculate Weight Volume.' }));
                is_Valid = false
            }
        }

        return is_Valid;
    }
    const calculateVolWeight = (length, breadth, height) => {
        if (recipe.WeightUnit === '2') {
            return (length * breadth * height) / 5000;
        } else if (recipe.WeightUnit === '1') {
            return (length * breadth * height) / 139;
        }
        return 0; 
    };

    const HandleWeightDetails = (type, index) => {
        if (type === 'Edit') {
            WeightDetails[index].Length = Length;
            WeightDetails[index].Breadth = Breadth;
            WeightDetails[index].Height = Height;
            WeightDetails[index].VolWeight = calculateVolWeight(Length, Breadth, Height);
            setWeightDetails([...WeightDetails]);
            ResetWeightHandler();
        } else {
            if (ValidateWeigthDetails() && ValidateVolWeight()) {
                const volWeight = calculateVolWeight(Length, Breadth, Height);
                setWeightDetails([...WeightDetails, { Length, Breadth, Height, VolWeight: volWeight }]);
                ResetWeightHandler();
            }
        }
    };

    //weight edit
    const HandleWeightEdit = (item, index) => {
        setmyEditIndex(index)
        setisEdit(true)
        setLength(item.Length)
        setBreadth(item.Breadth)
        setHeight(item.Height)
        setVolWeight(item.VolWeight)
    }

    //weight delete
    const HandleWeightDelete = (index) => {
        WeightDetails.splice(index, 1)
        setWeightDetails(WeightDetails => [...WeightDetails])
    }
    const ResetWeightHandler = () => {
        setisEdit(false)
        setmyEditIndex('')
        setLength('')
        setBreadth('')
        setHeight('')
        setVolWeight('')
    }
    //-------------------------handle Weight Detail End--------------------------------------//

    //---------------------Extracting data for Auto complete start--------------------------------//
    let PinCodeData = []
    for (let i = 0; i < pincodeAllData.length; i++) {
        let mydata = pincodeAllData[i]?.values
        PinCodeData.push(mydata)
    };
    let countryData = []
    for (let i = 0; i < countryAllData.length; i++) {
        let mydata = countryAllData[i]?.values
        countryData.push(mydata)
    };
    let descriptionData = []
    for (let i = 0; i < DescriptionData.length; i++) {
        let mydata = DescriptionData[i]?.values
        descriptionData.push(mydata)
    };
    let StateData = []
    for (let i = 0; i < stateAllData.length; i++) {
        let mydata = stateAllData[i]?.values
        StateData.push(mydata)
    };

    let cityData = []
    for (let i = 0; i < cityAllData.length; i++) {
        let mydata = cityAllData[i]?.values
        cityData.push(mydata)
    };

    let StateConsigneeArr = []
    for (let i = 0; i < StateConsigneeAllData.length; i++) {
        let mydata = StateConsigneeAllData[i]?.values
        StateConsigneeArr.push(mydata)
    };

    let cityConsigneeArr = []
    for (let i = 0; i < CityConsigneeAllData.length; i++) {
        let mydata = CityConsigneeAllData[i]?.values
        cityConsigneeArr.push(mydata)
    };

    let clientData = []
    for (let i = 0; i < ClientDataArr.length; i++) {
        let mydata = ClientDataArr[i]?.values
        clientData.push(mydata)
    };
    let ConsignorDataByName = []
    for (let i = 0; i <ConsignorDataName.length; i++) {
        let mydata = ConsignorDataName[i]?.values
        ConsignorDataByName.push(mydata)
    };
    let ConsigneeDataByName = []
    for (let i = 0; i < ConsigneeDataName.length; i++) {
        let mydata = ConsigneeDataName[i]?.values
        ConsigneeDataByName.push(mydata)
    };
    //---------------------Extracting data for Auto complete End--------------------------------//


    //handle Submit
    const handleSubmit = () => {
        let temp = {
            ...recipe,
            TotalVolWeight: typeof recipe.TotalVolWeight === 'string' ? recipe.TotalVolWeight : JSON.stringify(recipe.TotalVolWeight),
            OverAllTotal: typeof recipe.OverAllTotal === 'string' ? recipe.OverAllTotal : JSON.stringify(recipe.OverAllTotal),
            consignorDetails,
            ConsigneeDetails,
            WeightDetails: WeightDetails.map(item => {
                return {
                    ...item,
                    breath: item.Breadth,
                    VolWeight: typeof item.VolWeight === 'string' ? item.VolWeight : JSON.stringify(item.VolWeight)
                }
            }),
            PacketDetails: PacketDetails.map(item => {
                return {
                    ...item,
                    Total: typeof item.Total === 'string' ? item.Total : JSON.stringify(item.Total),
                    OverAllTotal: typeof recipe.OverAllTotal === 'string' ? recipe.OverAllTotal : JSON.stringify(recipe.OverAllTotal)
                }
            }),
            TotalValue: recipe.TotalValue.toString()
        }
        setLoading(true)
        post(`${API_SERVER}api/OPERATION/InserPickUpEntry`, { ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {

                    toast.error(res.message)
                    setLoading(false)

                    if (res.focus === 'txtbdate') {
                        inputrefs.current[res.focus].input.focus();
                    } else if (res.focus === 'txtClient' || res.focus === 'txtCountry' || res.focus === 'txtState' || res.focus === 'txtConsignor'
                        || res.focus === 'txtCity' || res.focus === 'txtZip' || res.focus === 'txtConsigneeCountry' || res.focus === 'txtConsigneeState'
                        || res.focus === 'txtConsigneeCity' || res.focus === 'txtDescription' || res.focus === 'txtConsigneeZip' || res.focus === 'txtConsignee' ) {
                        inputrefs.current[res.focus].children[0].children[0].focus()
                    } else {
                        inputrefs.current[res.focus].focus()

                    }

                } else if (res.status === "SUCCESS") {
                    setLoading(false)
                    toast.success(res.message)
                    setRecipe({ ...recipe, OrderNo: '', IsUpdate: false })
                    handlePrint(res.orderNo)
                    handleClear();
                }
                else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message)
                    setLoading(false)
                }
            }
        });
        setLoading(false)
    }

    //Handle Clear
    const handleClear = () => {
        setRecipe(recipe => ({
            ...recipe,
            OrderNo: '',
            IsUpdate: false,
            AWBNo: "",
            ShipmentID: "",
            ReferenceNo: "",
            BDate: new Date(),
            Client: "",
            UploadInvoice: "",
            ImageName: "",
            ImageExt: "",
            Pieces: "",
            TotalValue: "",
            CurrencyCode: "",
            Currency: "",
            ActualWeight: "",
            WeightUnit: "",
            TotalWeight: "",
            TotalChargeWeight: "",
            TotalVolWeight: "",
            PerKgWeight: "",
            PacketType: "",
            PaymentType: "",
            IsExportDeclartionCharge: false,
            IsVolWeight: false,
            Amount: ""
        }))
        setConsignorDetails({
            ...consignorDetails,
            Consignor: "",
            ConsignorCPerson: "",
            Address1: "",
            Address2: "",
            Address3: "",
            Email: "",
            Zip: "",
            Country: "",
            State: "",
            City: "",
            Phone: "",
            GSTIN: "",
            ConsignorPanNo: "",
        })
        setConsigneeDetails({
            ...ConsigneeDetails,
            Consignee: "",
            ContactPerson: "",
            ConsigneeAddress1: "",
            ConsigneeAddress2: "",
            ConsigneeAddress3: "",
            ConsigneeEmail: "",
            ConsigneeZip: "",
            ConsigneeCountry: "",
            ConsigneeState: "",
            ConsigneeCity: "",
            ConsigneePhone: "",
            ConsigneeGSTIN: "",
            IECNo: "",
            ConsigneePan: ""
        })
        setPaketdata({ Description: "", HSNNO: "", Units: "", UnitValue: "", Total: '' })
        setWeightDetails([])
        setPacketDetails([])
        setErrors({})
        setCountryPre('')
        setStatePre('')
        setcountryConsigneePre('')
        setStateConsigneePre('')
        let a = document.querySelectorAll('.sc-eqUAAy');
        if (a.length > 0) {
            a[0].children[0].children[0].innerHTML = ''
        }
    }

    const HandleClearOrder = () => {
        setRecipe(recipe => ({
            ...recipe,
            AWBNo: "",
            OrderNo:"",
            ShipmentID: "",
            ReferenceNo: "",
            BDate: new Date(),
            Client: "",
            UploadInvoice: "",
            ImageName: "",
            ImageExt: "",
            Pieces: "",
            TotalValue: "",
            CurrencyCode: "",
            Currency: "",
            ActualWeight: "",
            WeightUnit: "",
            TotalWeight: "",
            TotalChargeWeight: "",
            TotalVolWeight: "",
            PerKgWeight: "",
            PacketType: "",
            PaymentType: "",
            IsExportDeclartionCharge: false,
            IsVolWeight: false,
            Amount: ""
        }))

        setConsignorDetails({
            ...consignorDetails,
            Consignor: "",
            Address1: "",
            Address2: "",
            ConsignorCPerson: "",
            Address3: "",
            Email: "",
            Zip: "",
            Country: "",
            State: "",
            City: "",
            Phone: "",
            GSTIN: "",
            ConsignorPanNo: "",
        })

        setConsigneeDetails({
            ...ConsigneeDetails,
            Consignee: "",
            ContactPerson: "",
            ConsigneeAddress1: "",
            ConsigneeAddress2: "",
            ConsigneeAddress3: "",
            ConsigneeEmail: "",
            ConsigneeZip: "",
            ConsigneeCountry: "",
            ConsigneeState: "",
            ConsigneeCity: "",
            ConsigneePhone: "",
            ConsigneeGSTIN: "",
            IECNo: "",
            ConsigneePan: ""
        })
        setPaketdata({ Description: "", HSNNO: "", Units: "", UnitValue: "", Total: '' })
        setWeightDetails([])
        setPacketDetails([])
        setCountryPre('')
        setStatePre('')
        setcountryConsigneePre('')
        setStateConsigneePre('')
    }

    //----------------------------------getPickUpEntryData on Basis of Order no------------------------

    const getPickUpEntryData = (order) => {
        post(`${API_SERVER}api/OPERATION/GetPickupentryData`, { ...TokenData, OrderNo: order }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    toast.error(res.message)
                    HandleClearOrder()
                  
                } else {
                    let data = res.data
                    if (data && data.packetDetails && data.packetDetails.length > 0) {
                        var packetdata = data?.packetDetails?.map(item => {
                            return {
                                Description: item.description,
                                HSNNO: item.hsnno,
                                OverAllTotal: item.overAllTotal,
                                Total: item.total,
                                UnitValue: item.unitValue,
                                Units: item.units,
                            }
                        })

                    }
                    if (data && data.weightDetails && data.weightDetails.length > 0) {
                        var weightdata = data?.weightDetails?.map(item => {
                            return {
                                Length: item.length,
                                Breadth: item.breath,
                                Height: item.height,
                                VolWeight: item.volweight
                            }
                        })
                    }

                    setWeightDetails(weightdata ? weightdata : [])
                    setPacketDetails(packetdata ? packetdata : [])
                    getWeightUnitData()
                    getCurrencyData()
                    getPacketTypeData()
                    getPaymentTypeData()
                    setRecipe(recipe => ({
                        ...recipe,
                        OrderNo: order,
                        ShipmentID: data.shipmentID,
                        ReferenceNo: data.referenceNo,
                        BDate: data.bDate,
                        Client: data.client,
                        UploadInvoice: data.uploadInvoice,
                        ImageName: data.imageName,
                        ImageExt: data.imageExt,
                        Pieces: data.pieces,
                        TotalValue: data.totalValue,
                        CurrencyCode: data.currencyCode,
                        Currency: data.currency,
                        ActualWeight: data.actualWeight,
                        WeightUnit: data.weightUnit,
                        TotalWeight: data.totalWeight,
                        TotalChargeWeight: data.totalChargeWeight,
                        TotalVolWeight: data.totalVolWeight,
                        PerKgWeight: data.perKgWeight,
                        PacketType: data.packetType,
                        PaymentType: data.paymentType,
                        IsExportDeclartionCharge: data.isExportDeclartionCharge,
                        IsVolWeight: data.isVolWeight,
                        Amount: data.amount
                    }))

                    setConsignorDetails({
                        ...consignorDetails,
                        Consignor: data.consignorDetails.consignor,
                        ConsignorCPerson: data.consignorDetails.consignorCPerson,
                        ConsignorPanNo: data.consignorDetails.consignorPanNo,
                        Address1: data.consignorDetails.address1,
                        Address2: data.consignorDetails.address2,
                        Address3: data.consignorDetails.address3,
                        Email: data.consignorDetails.email,
                        Zip: data.consignorDetails.zip,
                        Country: data.consignorDetails.country,
                        State: data.consignorDetails.state,
                        City: data.consignorDetails.city,
                        Phone: data.consignorDetails.phone,
                        GSTIN: data.consignorDetails.gstin
                    })

                    setCountryPre(data.consignorDetails.country)
                    setStatePre(data.consignorDetails.state)

                    setConsigneeDetails({
                        ...ConsigneeDetails,
                        Consignee: data.consigneeDetails.consignee,
                        ContactPerson: data.consigneeDetails.contactPerson,
                        ConsigneeAddress1: data.consigneeDetails.consigneeAddress1,
                        ConsigneeAddress2: data.consigneeDetails.consigneeAddress2,
                        ConsigneeAddress3: data.consigneeDetails.consigneeAddress3,
                        ConsigneeEmail: data.consigneeDetails.consigneeEmail,
                        ConsigneeZip: data.consigneeDetails.consigneeZip,
                        ConsigneeCountry: data.consigneeDetails.consigneeCountry,
                        ConsigneeState: data.consigneeDetails.consigneeState,
                        ConsigneeCity: data.consigneeDetails.consigneeCity,
                        ConsigneePhone: data.consigneeDetails.consigneePhone,
                        ConsigneeGSTIN: data.consigneeDetails.consigneeGSTIN,
                        IECNo: data.consigneeDetails.iecNo,
                        ConsigneePan: data.consigneeDetails.consigneePan,
                    })
                    setcountryConsigneePre(data.consigneeDetails.consigneeCountry)
                    setStateConsigneePre(data.consigneeDetails.consigneeState)
                }
            }
        });
    }

    const handlePrint = () => {

        post(`${API_SERVER}api/Print/PrintAwb`, { ...TokenData, ...recipe }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    /*toast.error(res.message)*/
                } else {
                    window.open(`/PdfView/#data:application/pdf;base64,${res.base64PDF}`)
                }
            }
        });
    };
   
    useEffect(() => {
     
        const calculateVolWeight = () => {
            if (Length && Breadth && Height) {
                let volData;

                if (recipe.WeightUnit === '2') {
                    volData = (Length * Breadth * Height) / 5000;
                } else if (recipe.WeightUnit === '1') {
                    volData = (Length * Breadth * Height) / 139;
                }

                setVolWeight(volData); 
            }
        };

        calculateVolWeight();

    }, [Length, Breadth, Height, recipe.WeightUnit]);

    return (

        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">
                        Pickup Entry | <input type='checkbox' checked={recipe.IsUpdate} onChange={(e) => {
                            setRecipe({ ...recipe, IsUpdate: e.target.checked, OrderNo: '' })
                            setisFormEdit(!isFormEdit)
                            setisEdit(false)
                            HandleClearOrder()
                        }}
                        /> Update
                    </h6>
                </div>

                <div className='main-card m-1'>
                    <div className='card-body border-1'>
                        <div className='row'>
                            <div className={`col-md-2 col-sm-12 mb-1`}>
                                <label className="form-label me-1" htmlFor='txtArea'>Awb Number </label>
                                {!recipe.IsUpdate && (
                                    <>
                                        <span className="me-1">|</span>
                                 
                                    <input
                                        type="checkbox"
                                        checked={isAwbNumberEnabled}
                                        onChange={() => setIsAwbNumberEnabled(!isAwbNumberEnabled)}
                                        />
                                    </>
                                )}
                                <input
                                    ref={ref => (inputrefs.current['txtOrderNumber'] = ref)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtReferenceNo'].focus() };
                                    }}
                                    type='text'
                                    autoComplete='off'
                                    label='Tracking No'
                                    className='form-control'
                                    id='txtOrderNumber'
                                    maxLength='12'
                                    placeholder={(recipe.IsUpdate || isAwbNumberEnabled) ? 'Enter Awb Number' : 'System Generated'}
                                  
                                    disabled={!isAwbNumberEnabled && !recipe.IsUpdate}
                                    value={recipe.OrderNo}
                               
                                    onBlur={() => {
                                        if (recipe.IsUpdate) {
                                            getPickUpEntryData(recipe.OrderNo);
                                        }
                                    }}
                                    onChange={(e) => {
                                        setRecipe({ ...recipe, OrderNo: e.target.value });
                                    }}
                                />
                            </div>
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtbdate'].input.focus() };
                                }}
                                label='Cust. Reference Number'
                                id='txtReferenceNo'
                                maxLength='50'
                                placeholder="Cust. Reference Number"

                                value={recipe.ReferenceNo}
                                error={errors.ReferenceNo}
                                onChange={(e) => {
                                    setRecipe({ ...recipe, ReferenceNo: e.target.value })
                                }}
                            />

                            <DateBox
                                id='txtbdate'
                                inputrefs={inputrefs}
                                label='Booking Date'
                                placeholder="Booking Date"
                                required={true}
                                selected={recipe.BDate === '' ? '' : new Date(recipe.BDate)}
                                onChange={(e) => {
                                    let dateArr = e?.toString()?.split(' ')
                                    dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                    setRecipe({ ...recipe, BDate: dateArr })
                                }}
                                maxDate={new Date()}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtClient'].children[0].children[0].focus() };
                                }}
                            />
                            <AutoCompleteBox
                                loading={dataLoading}
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsignor'].children[0].children[0].focus() };
                                }}
                                label='Client'
                                placeholder="Client"
                                maxLength='50'
                                options={clientData}
                                id='txtClient'
                                required={true}
                         
                                onFocus={() => getClientData()}
                                inputValue={recipe.Client ? recipe.Client : ''}
                                error={errors.Client}
                                onInputChange={(event, newInputValue) => {
                                    setRecipe({ ...recipe, Client: newInputValue })
                                    getConsigneeDataByClient(newInputValue)
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className='main-card m-1'>
                    <div className='card-header mb-1'>
                        <h6 className="m-0">Consignor Details</h6>
                    </div>
                    <div className='card-body border-1'>
                        <div className='row'>
                            <AutoCompleteBox
                                loading={dataLoading}
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtAddress1'].focus() };
                                }}
                                label='Consignor Name'
                                placeholder="Consignor Name"
                                maxLength='50'
                                options={ConsignorDataByName}
                                id='txtConsignor'
                                required={true}

                                onFocus={() => getConsignorDataFromName()}
                                inputValue={consignorDetails.Consignor ? consignorDetails.Consignor : ''}
                                
                                onInputChange={(event, newInputValue) => {
                                    setConsignorDetails({ ...consignorDetails, Consignor: newInputValue })
                                    getConsignorDetailsData(newInputValue, "Consignor")
                                }}
                            />
                            {/*<InputBox*/}
                            {/*    inputrefs={inputrefs}*/}
                            {/*    onKeyDown={(e) => {*/}
                            {/*        if (e.key === 'Enter') {*/}
                            {/*            if (consignorDetails.Address1 === '' && consignorDetails.Address2 === '' && consignorDetails.Address3 === '' && consignorDetails.Email === '' && consignorDetails.Phone === '' && consignorDetails.ConsignorCPerson === ''*/}
                            {/*                && consignorDetails.Zip === '' && consignorDetails.Country === '' && consignorDetails.State === '' && consignorDetails.City === '' && consignorDetails.GSTIN === '' && consignorDetails.ConsignorPanNo === '')*/}
                            {/*                getConsignorDetailsData(consignorDetails.Consignor, 'Consignor')*/}
                            {/*            inputrefs.current['txtAddress1'].focus()*/}

                            {/*        };*/}
                            {/*    }}*/}
                               
                            {/*    label='Consignor Name'*/}
                            {/*    id='txtConsignor'*/}
                            {/*    maxLength='50'*/}
                            {/*    placeholder="Consignor Name"*/}
                            {/*    value={consignorDetails.Consignor}*/}
                            {/*    onChange={(e) => HandleConsignorChange('Consignor', e.target.value)}*/}
                            {/*    required={true}*/}
                            {/*/>*/}

                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtAddress2'].focus() };
                                }}
                                label='Address 1'
                                id='txtAddress1'
                                maxLength='100'
                                placeholder="Address 1"
                                value={consignorDetails.Address1}
                                onChange={(e) => HandleConsignorChange('Address1', e.target.value)}
                                required
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtAddress3'].focus() };
                                }}
                                label='Address 2'
                                id='txtAddress2'
                                maxLength='100'
                                placeholder="Address 2"
                                value={consignorDetails.Address2}
                                onChange={(e) => HandleConsignorChange('Address2', e.target.value)}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtPhone'].focus() };
                                }}
                                label='Address 3'
                                id='txtAddress3'
                                maxLength='100'
                                placeholder="Address 3"
                                value={consignorDetails.Address3}
                                onChange={(e) => HandleConsignorChange('Address3', e.target.value)}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        inputrefs.current['txtEmail'].focus()
                                    };
                                }}
                                label='Mobile Number'
                                id='txtPhone'
                                maxLength='25'
                                placeholder="Mobile Number"
                                value={consignorDetails.Phone}
                                onChange={(e) => HandleConsignorChange('Phone', e.target.value.replace(/[^0-9+\s-]/g, "").replace(/(\+.*\+)/g, "+").replace(/-+/g, "-").replace(/\s+/g, " "))}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtZip'].children[0].children[0].focus() };
                                }}
                                label='Email'
                                id='txtEmail'
                                maxLength='50'
                                placeholder="Email"

                                value={consignorDetails.Email}
                                onChange={(e) => HandleConsignorChange('Email', e.target.value)}
                            />
                            <AutoCompleteBox
                                loading={dataLoading}
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsignorCountry'].children[0].children[0].focus() };
                                }}
                                label='Pin Code'
                                placeholder="Pin Code"
                                maxLength='15'
                                options={PinCodeData}
                                onFocus={() => PincodeData()}
                                id='txtZip'
                                required={true}
                                onBlur={() => getCityStateByPincode(consignorDetails.Zip)}
                                inputValue={consignorDetails.Zip ? consignorDetails.Zip : ''}
                                onInputChange={(event, newInputValue) => {
                                    HandleConsignorChange('Zip', newInputValue)
                                    setPinCodePre(newInputValue)

                                }}
                            />
                            <AutoCompleteBox
                                loading={dataLoading}
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsignorState'].children[0].children[0].focus() };
                                }}
                                label='Country'
                                placeholder="Country"
                                maxLength='50'
                                options={countryData}
                                onFocus={() => CountryData()}
                                id='txtConsignorCountry'
                                required={true}
                                inputValue={consignorDetails.Country ? consignorDetails.Country : ''}
                                onInputChange={(event, newInputValue) => {
                                    HandleConsignorChange('Country', newInputValue)
                                    setConsignorDetails(consignorDetails => ({ ...consignorDetails, State: '', City: '' }))
                                    setCountryPre(newInputValue)
                                }}
                            />
                            <AutoCompleteBox
                                loading={dataLoading}
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtCity'].children[0].children[0].focus() };
                                }}
                                label='State'
                                placeholder="State"
                                maxLength='50'
                                options={StateData}
                                id='txtConsignorState'
                                required={false}
                                onFocus={() => StateAllData()}
                                inputValue={consignorDetails.State ? consignorDetails.State : ''}
                                onInputChange={(event, newInputValue) => {
                                    HandleConsignorChange('State', newInputValue)
                                    setConsignorDetails(consignorDetails => ({ ...consignorDetails, City: '' }))
                                    setStatePre(newInputValue)
                                }}
                            />
                            <AutoCompleteBox
                                loading={dataLoading}
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtGSTIN'].focus() };
                                }}
                                label='City'
                                placeholder="City"
                                maxLength='50'
                                options={cityData}
                                id='txtCity'
                                required={true}
                                onFocus={() => CityAllData()}
                                inputValue={consignorDetails.City ? consignorDetails.City : ''}
                                onInputChange={(event, newInputValue) => {
                                    HandleConsignorChange('City', newInputValue)
                                    setCityPre(newInputValue)
                                }}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsignorCPerson'].focus() };
                                }}
                                label='GSTIN'
                                id='txtGSTIN'
                                maxLength='15'
                                placeholder="GSTIN"
                                required={false}
                                value={consignorDetails.GSTIN}
                                onChange={(e) => HandleConsignorChange('GSTIN', e.target.value)}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsignorPanNo'].focus() };
                                }}
                                label='CPerson'
                                id='txtConsignorCPerson'
                                maxLength='50'
                                placeholder="CPerson"
                                value={consignorDetails.ConsignorCPerson}
                                onChange={(e) => HandleConsignorChange('ConsignorCPerson', e.target.value)}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsignee'].children[0].children[0].focus() };
                                }}
                                label='PAN No.'
                                id='txtConsignorPanNo'
                                maxLength='10'
                                placeholder="PAN No."
                                value={consignorDetails.ConsignorPanNo}
                                onChange={(e) => HandleConsignorChange('ConsignorPanNo', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='main-card m-1'>
                    <div className='card-header mb-1'>
                        <h6 className="m-0">Consignee Details</h6>
                    </div>
                    <div className='card-body border-1'>
                        <div className='row'>
                            <AutoCompleteBox
                                loading={dataLoading}
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtCPerson'].focus() };
                                }}
                                label='Consignee Name'
                                placeholder="Consignee Name"
                                maxLength='50'
                                options={ConsigneeDataByName}
                                id='txtConsignee'
                                required={true}

                                onFocus={() => getConsigneeDataFromName()}
                                inputValue={ConsigneeDetails.Consignee ? ConsigneeDetails.Consignee : ''}

                                onInputChange={(event, newInputValue) => {
                                    setConsigneeDetails({ ...ConsigneeDetails, Consignee: newInputValue })
                                    getConsigneeDetailsData(newInputValue,"Consignee")
                                }}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsigneeAddress1'].focus() };
                                }}
                                label='CPerson'
                                id='txtCPerson'
                                maxLength='50'
                                placeholder="CPerson"
                                value={ConsigneeDetails.ContactPerson}
                                onChange={(e) => HandleConsigneeChange('ContactPerson', e.target.value)}
                            />

                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsigneeAddress2'].focus() };
                                }}
                                label='Address 1'
                                id='txtConsigneeAddress1'
                                maxLength='100'
                                placeholder="Address 1"
                                required
                                value={ConsigneeDetails.ConsigneeAddress1}
                                onChange={(e) => HandleConsigneeChange('ConsigneeAddress1', e.target.value)}
                            />

                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsigneeAddress3'].focus() };
                                }}
                                label='Address 2'
                                id='txtConsigneeAddress2'
                                maxLength='100'
                                placeholder="Address 2"
                                value={ConsigneeDetails.ConsigneeAddress2}
                                onChange={(e) => HandleConsigneeChange('ConsigneeAddress2', e.target.value)}
                            />

                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsigneePhone'].focus() };
                                }}
                                label='Address 3'
                                id='txtConsigneeAddress3'
                                maxLength='100'
                                placeholder="Address 3"
                                value={ConsigneeDetails.ConsigneeAddress3}
                                onChange={(e) => HandleConsigneeChange('ConsigneeAddress3', e.target.value)}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsigneeEmail'].focus() };
                                }}
                                label='Mobile Number'
                                id='txtConsigneePhone'
                                maxLength='25'
                                placeholder="Mobile Number"
                                value={ConsigneeDetails.ConsigneePhone}
                                onChange={(e) => {
                                    HandleConsigneeChange('ConsigneePhone', e.target.value.replace(/[^0-9+\s-]/g, "").replace(/(\+.*\+)/g, "+").replace(/-+/g, "-").replace(/\s+/g, " "));

                                }}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsigneeZip'].children[0].children[0].focus() };
                                }}
                                label='Email'
                                id='txtConsigneeEmail'
                                maxLength='50'
                                placeholder="Email"
                                value={ConsigneeDetails.ConsigneeEmail}
                                onChange={(e) => HandleConsigneeChange('ConsigneeEmail', e.target.value)}
                            />
                            <AutoCompleteBox
                                loading={dataLoading}
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsigneeCountry'].children[0].children[0].focus() };
                                }}
                                label='Pin Code'
                                placeholder="Pin Code"
                                maxLength='50'
                                options={PinCodeData}
                                onFocus={() => PincodeData()}
                                id='txtConsigneeZip'
                                required={true}
                                onBlur={() => getCityStateByPincodeConsignee(ConsigneeDetails.ConsigneeZip)}
                                inputValue={ConsigneeDetails.ConsigneeZip ? ConsigneeDetails.ConsigneeZip : ''}
                                onInputChange={(event, newInputValue) => {
                                    HandleConsigneeChange('ConsigneeZip', newInputValue)
                                    setpincodeConsigneePre(newInputValue)

                                }}
                            />
                            <AutoCompleteBox
                                loading={dataLoading}
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsigneeState'].children[0].children[0].focus() };
                                }}
                                label='Country'
                                placeholder="Country"
                                maxLength='50'
                                options={countryData}
                                id='txtConsigneeCountry'
                                onFocus={() => CountryData()}
                                required={true}
                                inputValue={ConsigneeDetails.ConsigneeCountry ? ConsigneeDetails.ConsigneeCountry : ''}
                                onInputChange={(event, newInputValue) => {
                                    HandleConsigneeChange('ConsigneeCountry', newInputValue)
                                    setConsigneeDetails(ConsigneeDetails => ({ ...ConsigneeDetails, ConsigneeState: '', ConsigneeCity: '' }))
                                    setcountryConsigneePre(newInputValue)
                                }}
                            />
                            <AutoCompleteBox
                                loading={dataLoading}
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsigneeCity'].children[0].children[0].focus() };
                                }}
                                label='State'
                                placeholder="State"
                                maxLength='50'
                                options={StateConsigneeArr}
                                id='txtConsigneeState'
                                required={false}
                                onFocus={() => StateConsigneeData()}
                                inputValue={ConsigneeDetails.ConsigneeState ? ConsigneeDetails.ConsigneeState : ''}
                                onInputChange={(event, newInputValue) => {
                                    HandleConsigneeChange('ConsigneeState', newInputValue)
                                    setConsigneeDetails(ConsigneeDetails => ({ ...ConsigneeDetails, ConsigneeCity: '' }))
                                    setStateConsigneePre(newInputValue)
                                }}
                            />
                            <AutoCompleteBox
                                loading={dataLoading}
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtConsigneeGSTIN'].focus() };
                                }}
                                label='City'
                                placeholder="City"
                                maxLength='50'
                                options={cityConsigneeArr}
                                id='txtConsigneeCity'
                                required={true}
                                onFocus={() => CityConsigneeData()}
                                inputValue={ConsigneeDetails.ConsigneeCity ? ConsigneeDetails.ConsigneeCity : ''}
                                onInputChange={(event, newInputValue) => {
                                    HandleConsigneeChange('ConsigneeCity', newInputValue)
                                    setCityConsigneePre(newInputValue)
                                }}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtIECNo'].focus() };
                                }}
                                label='GSTIN'
                                id='txtConsigneeGSTIN'
                                maxLength='15'
                                placeholder="GSTIN"
                                value={ConsigneeDetails.ConsigneeGSTIN}
                                onChange={(e) => HandleConsigneeChange('ConsigneeGSTIN', e.target.value)}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['ConsigneePan'].focus() };
                                }}
                                label='IEC No.'
                                id='txtIECNo'
                                maxLength='20'
                                placeholder="IEC No."
                                value={ConsigneeDetails.IECNo}
                                onChange={(e) => HandleConsigneeChange('IECNo', e.target.value)}
                            />
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtPieces'].focus() };
                                }}
                                label='PAN No.'
                                id='ConsigneePan'
                                maxLength='10'
                                placeholder="PAN No."
                                value={ConsigneeDetails.ConsigneePan}
                                onChange={(e) => HandleConsigneeChange('ConsigneePan', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='main-card m-1'>
                    <div className='card-header mb-1'>
                        <h6 className="m-0">
                            <span>Weight Details | <input type='checkbox' checked={recipe.IsVolWeight} onChange={(e) => {
                                setRecipe({ ...recipe, IsVolWeight: e.target.checked })
                            }} /> Vol Weight</span>
                        </h6>
                    </div>
                    <div className='card-body border-1'>
                        <div className='row'>
                            <InputBox
                                customclass='number_align'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtTotalValue'].focus() };
                                }}
                                label='Pieces'
                                id='txtPieces'
                                maxLength='5'
                                placeholder="Pieces"
                                required
                                onFocus={() => {
                                    if (recipe.ActualWeight) {
                                        setRecipe({ ...recipe, TotalWeight: recipe.ActualWeight,  })
                                    }
                                }}
                                value={recipe.Pieces}
                                error={errors.Pieces}
                                onChange={(e) =>
                                    setRecipe({ ...recipe, Pieces: e.target.value.replace(/\D/g, "") })
                                }
                            />
                            <InputBox
                                customclass='number_align'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        inputrefs.current['txtCurrency'].focus();
                                    }
                                }}
                                label='Total Value'
                                id='txtTotalValue'
                                maxLength='8'
                                placeholder="Total Value"
                        
                                pattern="[0-9\.]*"
                                value={recipe.TotalValue}
                             
                                onChange={(e) => {
                                    setRecipe({
                                        ...recipe,
                                        TotalValue: e.target.value
                                    });
                                }}
                            />
                            <SelectInputBox
                                label='Currency'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtActualWeight'].focus() };
                                }}
                                id='txtCurrency'
                                nextinputid='txtcurrency'
                         
                                maxLength='50'
                                onFocus={() => {
                                    if (recipe.ActualWeight) {
                                        setRecipe({ ...recipe, TotalWeight: recipe.ActualWeight,  })
                                    }
                                }}
                                value={recipe.Currency}
                               
                                onChange={(e) => setRecipe({ ...recipe, Currency: e.target.value })}
                            >
                                <option value=''>-Please Select-</option>
                                {CurrencyMasterArr?.map((item, index) => {
                                    return (
                                        <option key={index} value={item.code}>{item.code}</option>
                                    )
                                })
                                }
                            </SelectInputBox>
                            <InputBox
                                customclass='number_align'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtWeightUnit'].focus() };
                                }}
                                label='Actual Weight'
                                id='txtActualWeight'
                                maxLength='50'
                                pattern="[0-9\.]*"
                                mendatory={true}
                                placeholder="Actual Weight"
                                value={recipe.ActualWeight}
                                error={errors.ActualWeight}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const isWithinLimit = (inputValue) <= 99999;
                                    if ( isWithinLimit) {
                                        setRecipe((prevRecipe) => ({
                                            ...prevRecipe,
                                            ActualWeight: inputValue,
                                            TotalWeight: inputValue,
                                        }));
                                    } else {
                                        console.error("Invalid input or exceeds maximum limit");                                    }
                                }}
                               
                            />
                            <SelectInputBox
                                label='Weight Unit'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtPerKgWeight'].focus() };
                                }}
                                id='txtWeightUnit'
                                nextinputid='txtWeightUnit'
                                required={true}
                                disabled={WeightDetails.length > 0}
                                maxLength='50'
                                
                                value={recipe.WeightUnit}
                                error={errors.WeightUnit}
                                onChange={(e) => setRecipe({ ...recipe, WeightUnit: e.target.value })}
                            >
                                <option value=''>-Please Select-</option>
                                {WeightDataLoading ? <option> Loading... </option>
                                    : WeightUnitArr?.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id}>{item.code}</option>
                                        )
                                    })
                                }
                            </SelectInputBox>
                            <SelectInputBox
                                label='Unit of Measurement'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {

                                }}
                                id='txtUnitofMeasurement'
                                nextinputid='txtWeightUnit'
                                required={true}
                                maxLength='50'
                                disabled
                                value={recipe.WeightUnit}
                                error={errors.WeightUnit}
                                onChange={(e) => setRecipe({ ...recipe, WeightUnit: e.target.value })}
                            >
                                {recipe.WeightUnit === '2' ? (
                                    <>

                                        <option value='Centimeters'>Centimeters</option>
                                    </>
                                ) : recipe.WeightUnit === '1' ? (
                                    <>

                                        <option value='Inches'>Inches</option>
                                    </>
                                ) : (
                                    <option value=''>-Please Select-</option>
                                )}
                            </SelectInputBox>
                            {recipe && recipe.IsVolWeight &&
                                <div className='border mt-2'>
                                    <div className='row p-3'>
               
                                        <div className='col-md-2 mb-1'>
                                            <div className="form-group text-start mb-3" >
                                                <label className="form-label mb-1">Length <span className='required'>*</span></label>
                                                <input
                                                    type="text"
                                                    ref={ref => (inputrefs.current['txtLength'] = ref)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            inputrefs.current['txtBreath'].focus()

                                                        };
                                                    }}
                                                    className="form-control number_align"
                                                    id='txtLength'
                                                    maxLength='3'
                                                    placeholder="Length"
                                                    required
                                                    value={Length}
                                                    onChange={(e) => {
                                                        setLength(e.target.value.replace(/\D/g, ''));
                                                      
                                                    }}
                                                />
                                                {errors.Length && <div className="error-msg">{errors.Length}</div>}
                                            </div>
                                        </div>
                                        <div className='col-md-2 mb-1'>
                                            <div className="form-group text-start mb-3" >
                                                <label className="form-label mb-1">Breadth <span className='required'>*</span></label>
                                                <input
                                                    type="text"
                                                    ref={ref => (inputrefs.current['txtBreath'] = ref)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') { inputrefs.current['txtHeight'].focus() };
                                                    }}
                                                    className="form-control number_align"
                                                    id='txtBreath'
                                                    maxLength='3'
                                                    placeholder="Breadth"
                                                    required
                                                    value={Breadth}
                                                    onChange={(e) => {
                                                        setBreadth(e.target.value.replace(/\D/g, ''));

                                                    }}
                                                />
                                                {errors.Breadth && <div className="error-msg">{errors.Breadth}</div>}
                                            </div>
                                        </div>
                                        <div className='col-md-2 mb-1'>
                                            <div className="form-group text-start mb-3" >
                                                <label className="form-label mb-1">Height <span className='required'>*</span></label>
                                                <input
                                                    ref={ref => (inputrefs.current['txtHeight'] = ref)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') { inputrefs.current['txtVolweight'].focus() };
                                                    }}
                                                    type="text"
                                                    className="form-control number_align"
                                                    id='txtHeight'
                                                    maxLength='3'
                                                    placeholder="Height"
                                                    required
                                                    value={Height}
                                                    onChange={(e) => {
                                                        setHeight(e.target.value.replace(/\D/g, ''));
                                                       
                                                    }}
                                                />
                                                {errors.Height && <div className="error-msg">{errors.Height}</div>}
                                            </div>
                                        </div>

                                        <div className='col-md-2 mb-1'>
                                            <div className="form-group text-start mb-3" >
                                                <label className="form-label mb-1">Vol. Weight</label>
                                                <input
                                                    type="text"
                                                    ref={ref => (inputrefs.current['txtVolweight'] = ref)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') { inputrefs.current['volWightBtn'].focus() };
                                                    }}
                                                    className="form-control number_align"
                                                    id='txtVolweight'
                                                    maxLength='3'
                                                    placeholder="Vol. Weight"
                                                    required
                                                    disabled
                                                    value={VolWeight}
                                                    
                                                />
                                                {errors.VolWeight && <div className="error-msg">{errors.VolWeight}</div>}
                                            </div>
                                        </div>
                                        <div className='col-md-2 mb-1 mt-2'>
                                            <div className='d-flex align-items-right'>
                                                <div className='mt-3 d-md-flex' >
                                                    <button type="button" ref={ref => (inputrefs.current['volWightBtn'] = ref)} className="btn btn-rounded btn-primary text-white" style={{ minWidth: '2.87rem' }} onClick={() => HandleWeightDetails(isEdit ? 'Edit' : 'Add', myEditIndex)}>Add</button>
                                                    <button type="button" className="btn btn-rounded btn-danger text-white" style={{ minWidth: '2.87rem' }} onClick={ResetWeightHandler}>Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {WeightDetails && WeightDetails.length > 0 &&
                                <div className='mt-2'>
                                    <div className='row p-3'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-striped">
                                                <thead>
                                                    <tr className='light-bg'>
                                                        <th style={{ width: '20px' }}>Action</th>
                                                        <th style={{ width: '20px' }}>Length</th>
                                                        <th style={{ width: '20px' }}>Breadth</th>
                                                        <th style={{ width: '20px' }}>Height</th>
                                                        <th style={{ width: '20px' }}>Vol. Weight</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {WeightDetails.map((item, index) => {
                                                        return <tr key={index}>
                                                            <td><i className="fa-regular fa-pen-to-square text-success me-2" onClick={() => HandleWeightEdit(item, index)} />  <i className="fa-regular fa-trash-can text-danger me-2" onClick={() => HandleWeightDelete(index)} /></td>
                                                            <td>{item.Length}</td>
                                                            <td>{item.Breadth}</td>
                                                            <td>{item.Height}</td>
                                                            <td>{item.VolWeight}</td>
                                                        </tr>
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>}
                            <div className='row p-3'>
                                <InputBox
                                    customclass='number_align'
                                    inputrefs={inputrefs}
                                    label='Total Weight'
                                    id='userGroup'
                                    maxLength='15'
                                    placeholder="Total Weight"
                                    disabled={true}
                                    value={recipe.TotalWeight}
                                />
                                <InputBox
                                    customclass='number_align'
                                    inputrefs={inputrefs}
                                    label='Total Vol Weight'
                                    id='userGroup'
                                    maxLength='15'
                                    placeholder="Total Vol Weight"
                                    disabled={true}
                                    value={recipe.TotalVolWeight}
                                />
                                <InputBox
                                    customclass='number_align'
                                    inputrefs={inputrefs}
                                    label='Total Charge Weight'
                                    id='userGroup'
                                    maxLength='15'
                                    placeholder="Total Charge Weight"
                                    disabled={true}
                                    value={recipe.TotalChargeWeight}
                                />
                                <InputBox
                                    customclass='number_align'
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtType'].focus() };
                                    }}
                                    label='Per KG Rate'
                                    id='txtPerKgWeight'
                                    maxLength='15'
                                    placeholder="Per Kg Rate"
                                    value={recipe.PerKgWeight}
                                    onChange={(e) => setRecipe({ ...recipe, PerKgWeight: e.target.value })}
                                />
                            </div>

                        </div>
                    </div>
                </div>
                <div className='main-card m-1'>
                    <div className='card-header mb-1'>
                        <h6 className="m-0">
                            Packet Details
                        </h6>
                    </div>
                    <div className='card-body border-1'>
                        <div className='row'>
                            <SelectInputBox
                                label='Type'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') { inputrefs.current['txtPaymentType'].focus() };
                                }}
                                id='txtType'
                                nextinputid='txtType'
                                required={true}
                                maxLength='50'
                                value={recipe.PacketType}
                                error={errors.PacketType}
                                onChange={(e) => setRecipe({ ...recipe, PacketType: e.target.value })}
                            >
                                <option value=''>-Please Select-</option>
                                {PacketDataLoading ? <option> Loading... </option>
                                    : PacketTypeArr?.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id}>{item.code}</option>
                                        )
                                    })
                                }
                            </SelectInputBox>
                            <SelectInputBox
                                label='Payment Type'
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        if (recipe.PaymentType === "CASH" || recipe.PaymentType === "COD" || recipe.PaymentType === "DDP") {
                                            inputrefs.current['txtAmount'].focus();
                                        } else {
                                            inputrefs.current['txtDescription'].children[0].children[0].focus();
                                        }
                                    }
                                }}
                                id='txtPaymentType'
                                nextinputid='txtPaymentType'
                                required={true}
                                maxLength='50'

                                value={recipe.PaymentType}
                                error={errors.PaymentType}
                                onChange={(e) => {
                                    //console.log(e.target.value, recipe)
                                    setRecipe({ ...recipe, PaymentType: e.target.value })
                                }}
                            >
                                <option value=''>-Please Select-</option>
                                {PaymentDataLoading ? <option> Loading... </option>
                                    : PaymentTypeArr?.map((item, index) => {
                                        return (
                                            <option key={index} value={item.name}>{item.name}</option>
                                        )
                                    })
                                }
                            </SelectInputBox>
                            {recipe && (recipe.PaymentType === "CASH" || recipe.PaymentType === "COD" || recipe.PaymentType === "DDP") &&
                                <>
                                    <InputBox
                                        customclass='number_align'
                                        inputrefs={inputrefs}
                                        label='Amount'
                                        id='txtAmount'
                                    maxLength='50'
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtcurrency'].focus() };
                                    }}
                                        placeholder="Amount"
                                        required
                                        value={recipe.Amount}
                                        error={errors.Amount}
                                        onChange={(e) => {
                                            setRecipe({ ...recipe, Amount: e.target.value.replace(/\D/g, "") })

                                        }}
                                    />
                                    <SelectInputBox
                                        label='Currency'
                                        inputrefs={inputrefs}
                                    id='txtcurrency'
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtDescription'].children[0].children[0].focus() };
                                    }}
                                        required={true}
                                        maxLength='50'
                                        value={recipe.CurrencyCode}
                                        error={errors.CurrencyCode}
                                        onChange={(e) => setRecipe({ ...recipe, CurrencyCode: e.target.value })}
                                    >
                                        <option value=''>-Please Select-</option>
                                        {CurrencyDataLoading ? <option> Loading... </option>
                                            : CurrencyMasterArr?.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.code}>{item.code}</option>
                                                )
                                            })
                                        }
                                    </SelectInputBox>
                                </>}

                            {/* <div className={`col-md-3 mb-3 cntrl-width `}>
                                <div className='d-flex'>
                                    <span><input type='checkbox' checked={recipe.IsExportDeclartionCharge} onChange={(e) => setRecipe({ ...recipe, IsExportDeclartionCharge: e.target.checked })} /></span>
                                    <span className='pr-3'>  IsExportDeclartionCharge</span>
                                </div>
                            </div>*/}

                        </div>
                    </div>
                    <div className='m-2 pb-2'>
                        <div className='card-body border'>
                            <div className='card-header'>
                                <h6 className="">Invoice Detail</h6>
                            </div>
                            <div className='row p-2'>
                                {/*<InputBox*/}
                                {/*        inputrefs={inputrefs}*/}
                                {/*        onKeyDown={(e) => {*/}
                                {/*            if (e.key === 'Enter') { inputrefs.current['txtHSNNO'].focus() };*/}
                                {/*        }}*/}
                                {/*        label='Description'*/}
                                {/*        id='txtDescription'*/}
                                {/*        maxLength='50'*/}
                                {/*        placeholder="Description"*/}
                                {/*        required*/}
                                {/*        value={packetdata.Description}*/}
                                {/*        error={errors.Description}*/}
                                {/*        onChange={(e) => setPaketdata({ ...packetdata, Description:e.target.value })}*/}
                                {/*/>*/}
                                <AutoCompleteBox
                                    loading={dataLoading}
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtHSNNO'].focus() };
                                    }}
                                    label='Description'
                                    placeholder="Description"
                                    error={errors.Description}
                                    maxLength='50'
                                    options={descriptionData}
                                    id='txtDescription'
                                    required={true}
                                    onFocus={() => GetDescription()}
                                    inputValue={packetdata.Description ? packetdata.Description : ''}
                                    onBlur={() => getHsnbyDescrition(packetdata.Description)}
                                    onInputChange={(event, newInputValue) => {
                                        setPaketdata({ ...packetdata, Description: newInputValue })
                                    }}
                                />
                                <InputBox
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtUnits'].focus() };
                                    }}
                                    label='HSN Number'
                                    id='txtHSNNO'
                                    maxLength='10'
                                    placeholder="HS Number"
                                    value={packetdata.HSNNO}
                                    onChange={(e) => setPaketdata({ ...packetdata, HSNNO: e.target.value })}
                                />

                                <InputBox
                                    customclass='number_align'
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtUnitValue'].focus() };
                                    }}
                                    label='Unit'
                                    id='txtUnits'
                                    maxLength='15'
                                    placeholder="Unit"
                                    value={packetdata.Units}
                                    error={errors.Units}
                                    onChange={(e) => {
                                        setPaketdata({
                                            ...packetdata, Units: e.target.value.replace(/\D/g, ""),
                                            Total: packetdata.UnitValue * e.target.value.replace(/\D/g, "")
                                        })
                                    }}
                                />

                                <InputBox
                                    customclass='number_align'
                                    inputrefs={inputrefs}
                                    label='Unit Value'
                                    id='txtUnitValue'
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtAddBtn'].focus() };
                                    }}
                                    maxLength='15'
                                    pattern="[0-9\.]*"
                                    placeholder="Unit Value"
                                    value={packetdata.UnitValue}
                                    error={errors.UnitValue}
                                    onChange={(e) => {
                                        setPaketdata({
                                            ...packetdata, UnitValue: (e.target.validity.valid) ? e.target.value : packetdata.UnitValue,
                                            Total: (e.target.validity.valid) && e.target.value * packetdata.Units
                                        })
                                    }}
                                />

                                <InputBox
                                    customclass='number_align'
                                    inputrefs={inputrefs}
                                    label='Total'
                                    id='txtTotal'
                                    maxLength='50'
                                    placeholder="Total"
                                    disabled={true}
                                    value={packetdata.Total ? packetdata.Total : ''}
                                    onChange={(e) => setPaketdata({ ...packetdata, Total: e.target.value })}
                                />
                                {/*<InputBox*/}
                                {/*    customclass='number_align'*/}
                                {/*    inputrefs={inputrefs}*/}
                                {/*    label='OverAll Total'*/}
                                {/*    id='userGroup'*/}
                                {/*    maxLength='50'*/}
                                {/*    placeholder="OverAll Total"*/}
                                {/*    disabled={true}*/}
                                {/*    value={recipe.OverAllTotal}*/}
                                {/*/>*/}
                                <InputBox
                                    customclass='number_align'
                                    inputrefs={inputrefs}
                                    label='OverAll Total'
                                    id='userGroup'
                                    maxLength='50'
                                    placeholder="OverAll Total"
                                    disabled={true}
                                    value={recipe.OverAllTotal}
                                />
                                <div className="col-md-3 mb-3 cntrl-width">
                                    <div className='d-flex align-items-right'>
                                        <div className='mt-4' >
                                            <button type="button" className="btn btn-rounded btn-primary text-white" ref={ref => (inputrefs.current['txtAddBtn'] = ref)} style={{ minWidth: '2.87rem' }} onClick={() => HandlePacketData(isEdit ? 'Edit' : 'Add', myEditIndex)} onKeyDown={(e) => {
                                                handleSubmit()
                                            }}>Add</button>
                                            <button type="button" className="btn btn-rounded btn-danger text-white" style={{ minWidth: '2.87rem' }} onClick={ResetPacketHandler}>Reset</button>
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-md-2 mb-3 `}>
                                    <label className="form-label">Upload Invoice</label>
                                    <FileUploader
                                        handleChange={(file) => {
                                            let extArr = (file.name).split(".");
                                            if (file) {
                                                const reader = new FileReader();
                                                reader.readAsDataURL(file);
                                                reader.onload = () => {
                                                    let logo = (reader.result).split(',')[1]
                                                    setRecipe({
                                                        ...recipe,
                                                        UploadInvoice: logo,
                                                        ImageExt: extArr[1],
                                                        ImageName: extArr[0]
                                                    });
                                                    let a = document.getElementsByClassName('sc-eqUAAy');
                                                    a[0].children[0].children[0].innerHTML = file.name;
                                                };
                                            }
                                        }}
                                        name="file" types={["PDF"]}
                                    />

                                    <div className='mt-1'>
                                        {recipe.UploadInvoice ?
                                            <>
                                                <div>
                                                    {/*<Link style={{ cursor: 'pointer' }} to={`/PdfView#data:application/pdf;base64,${encodeURI(recipe.UploadInvoice)}`} target="_blank">
                                                    <FaRegFilePdf />
                                                </Link>*/}
                                                    <span style={{ fontSize: "20px", cursor: 'pointer' }} className="text-warning ml-5"
                                                        onClick={() => downloadPDF(recipe.UploadInvoice, recipe.ImageName)}><i className="fa fa-download text-default"></i> </span>
                                                    <span style={{ fontSize: "10px" }}>{recipe.ImageName + '.' + recipe.ImageExt}</span>

                                                </div>
                                            </>
                                            : <></>}
                                    </div>
                                </div>
                            </div>

                            {PacketDetails && PacketDetails.length > 0 &&
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr className='light-bg'>
                                                <th style={{ width: '20px' }}>Action</th>
                                                <th style={{ width: '20px' }}>Description</th>
                                                <th style={{ width: '20px' }}>HSNNO</th>
                                                <th style={{ width: '20px' }}>Units</th>
                                                <th style={{ width: '20px' }}>UnitValue</th>
                                                <th style={{ width: '20px' }}>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {PacketDetails.map((item, index) => {
                                                return <tr key={index}>
                                                    <td><i className="fa-regular fa-pen-to-square text-success me-2" onClick={() => HandlePacketEdit(item, index)} />  <i className="fa-regular fa-trash-can text-danger me-2" onClick={() => HandlePacketDelete(index)} /></td>
                                                    <td>{item.Description}</td>
                                                    <td>{item.HSNNO}</td>
                                                    <td>{item.Units}</td>
                                                    <td>{item.UnitValue}</td>
                                                    <td>{item.Total}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>}
                        </div>
                    </div>
                </div>

                <div className='m-3'>
                    <FormButtonWithPrint
                        save={true}
                        print={true}
                        reset={true}
                        handleSubmit={handleSubmit}
                        handlePrint={handlePrint}
                        handleClear={handleClear}
                    />
                </div>
            </div>
            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>
    )
}

export default PickupEntry;