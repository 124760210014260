import React, { useEffect, useState, useRef } from 'react'
import { FormButton, Switch } from "../commoncomponents/InputBox";
import { confirmAlert } from 'react-confirm-alert';

import { useAuth } from '../../context/auth';
import { Table } from '../commoncomponents/Table';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, handleDownloadExcel, initializeFilterData } from '../../Utils';

//services
import { post } from '../../apiservices/service';

function ForwardingPartyMaster() {
    const inputrefs = useRef([]);
    const { user } = useAuth();

    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }

    //initial state
    const [recipe, setRecipe] = useState({ FPCode: '', FPName: '', ServiceTypeCode: '', ServiceTypeName: '', Active: true })
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false)
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [filterData, setFilterData] = useState(initializeFilterData)
    const [isUpdate, setIsUpdate] = useState(false)
    const [ForwarderService, setForwarderService] = useState([])
    const [isServiceEdit, setisServiceEdit] = useState(false)
    const [serviceEditIndex, setServiceEditIndex] = useState('')

    const getFormData = (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        setLoading(true)

        let temp = {
            PageIndex: PageIndex.toString(),
            SearchText: "",
            StartValue: "1",
            SearchFieldValue: "",
            Type: "s"
        }
        post(`${API_SERVER}api/Master/ShowForwardingPartyMaster`,{ ...TokenData, ...temp, ...sortConfig, ...filterData }, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    setResult({ ...res })
                } else {
                    setLoading(false)
                    setResult({ ...res })
                }
            }
        });
    }

    const handleChange1 = (event, value) => {
        getFormData(value);
    };

    useEffect(() => {
        inputrefs.current['txtFPCode'].focus()
        document.title = 'Nihao: Forwarding Party Master'
        getFormData()
    }, [sortConfig])

    useEffect(() => {
        getFormData()
    }, [isUpdate])

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //Submit Handler
    const handleSubmit = () => {
        let temp = {
            ...recipe,
            ForwarderService
        }

        setLoading(true)
        post(`${API_SERVER}api/MASTER/InsertUpdateForwardingPartyMaster`, { ...TokenData, ...temp }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        inputrefs.current[res.focus].focus();
                        toast.error(res.message)
                        setLoading(false)
                    } else if (res.status === "SUCCESS") {
                        setLoading(false)
                        toast.success(res.message)
                        getFormData()
                        handleClear()
                        HandleServiceReset()
                    }
                    else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message)
                        setLoading(false)
                    }
                }
        });
        setLoading(false)
    }

    //Handle Edit
    const handleEdit = (Id) => {
        setLoading(true)
        post(`${API_SERVER}api/MASTER/EditForwardingPartyMaster`, { ...TokenData, FPID: Id }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setForwarderService([])
                    setRecipe({ FPCode: '', FPName: '', ServiceTypeCode: '', ServiceTypeName: '', Active: true })
                    setLoading(false)
                } else if (res.status === "SUCCESS") {
                    setLoading(false)
                    let data = res.data
                    if (data && data.forwarderService) {
                        let service = data.forwarderService.map(item => {
                            return {
                                ...item,
                                ServiceTypeCode: item.serviceTypeCode,
                                ServiceTypeName: item.serviceTypeName
                            }
                        })
                        setForwarderService([...service])
                    }
                    setRecipe({ ...data, FPCode: data.fpCode, FPName: data.fpName, Active: data.active })
                }
                else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message)
                    setLoading(false)
                }
            }
        });

        setLoading(false)
    }

    //Handle Delete
    const handleDelete = (Id) => {
        confirmAlert({
            message: 'Do you want to Delete this Record ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setLoading(true)
                        await post(`${API_SERVER}api/Master/DeleteForwardingPartyMaster`, { ...TokenData, FPID: Id }, (res) => {
                            if (res && res.status) {
                                if (res.status === "ERROR") {
                                    toast.error(res.message)
                                    setLoading(false)
                                } else if (res.status === "SUCCESS") {
                                    toast.success(res.message)
                                    setLoading(false)
                                    getFormData()
                                    handleClear()
                                }
                                else if (res.status === "UNAUTHORIZED") {
                                    toast.error(res.message)
                                    setLoading(false)
                                }
                            }
                        });
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
        setLoading(false)
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({ ...recipe, FPID:'', FPCode: '', FPName: '', ServiceCode: '', ServiceName: '', Active: true }))
        setForwarderService([])
        setFilterData(filterData => ({ ...filterData, SearchText: '' }))
        setIsUpdate(!isUpdate)
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };


    //Start Export functionality
    let ExportRequest = {
        ...filterData,
        ...TokenData,
        ...sortConfig,
        Type: 'E',
        PageIndex: '1'
    }
    //End Export functionality

    //------------------------------------Service Details Handler start---------------------------------//

    //validation handler
    const ValidateServiceDetails = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "ServiceTypeCode", msg: "Please Enter Service Code." },
            { field: "ServiceTypeName", msg: "Please Enter Service Name." }
        ]

        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        return isValid;
    }

    //Handle Service Add
    //const HandleServiceAdd = (type, index) => {
    //    if (type === 'Edit') {
    //        if (ValidateServiceDetails()) {
    //            ForwarderService[index].ServiceTypeCode = recipe.ServiceTypeCode;
    //            ForwarderService[index].ServiceTypeName = recipe.ServiceTypeName;
    //            setForwarderService(ForwarderService => [...ForwarderService])
    //            HandleServiceReset()
    //        }
    //    } else {
    //        if (ValidateServiceDetails()) {
    //            setForwarderService(ForwarderService => [...ForwarderService, { ServiceTypeCode: recipe.ServiceTypeCode, ServiceTypeName: recipe.ServiceTypeName }])
    //            HandleServiceReset()
    //        }

    //    }
    //}

  
    const HandleServiceAdd = (type, index) => {
        const isDuplicate = ForwarderService.some(
            item =>
                item.ServiceTypeCode.toLowerCase() === recipe.ServiceTypeCode.toLowerCase() ||
                item.ServiceTypeName.toLowerCase() === recipe.ServiceTypeName.toLowerCase()
        );

        if (!isDuplicate) {
            if (type === 'Edit') {
                if (ValidateServiceDetails()) {
                    ForwarderService[index].ServiceTypeCode = recipe.ServiceTypeCode;
                    ForwarderService[index].ServiceTypeName = recipe.ServiceTypeName;
                    setForwarderService([...ForwarderService]);
                    HandleServiceReset();
                }
            } else {
                if (ValidateServiceDetails()) {
                    setForwarderService([...ForwarderService, { ServiceTypeCode: recipe.ServiceTypeCode, ServiceTypeName: recipe.ServiceTypeName }]);
                    HandleServiceReset();
                }
            }
        } else {
            toast.error(`Service [${recipe.ServiceTypeCode} : ${recipe.ServiceTypeName}] already exists!`);
        }
    };


   

   

    //Handle Service Reset
    const HandleServiceReset = () => {
        setisServiceEdit(false)
        setServiceEditIndex('')
        setRecipe(recipe => ({ ...recipe, ServiceTypeCode: '', ServiceTypeName: '' }))
        setErrors({})
    }

    //Handle Service Edit
    const HandlServiceEdit = (item, index) => {
        setServiceEditIndex(index)
        setisServiceEdit(true)
        setRecipe(recipe => ({ ...recipe, ServiceTypeCode: item.ServiceTypeCode, ServiceTypeName: item.ServiceTypeName }))
    }

    //Service delete
    const HandleServiceDelete = (index) => {
        ForwarderService.splice(index, 1)
        setForwarderService(ForwarderService => [...ForwarderService])
    }

    //------------------------------------Service Details Handler End---------------------------------//
    return (

        <>

            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0"> Forwarding Party Master  </h6>
                </div>
                <div className='card-body row'>
                    {/* Left Section Start*/ }
                    <div className='col-md-6 col-lg-6'>
                        <div className='row'>

                            <div className={`col-md-4 col-sm-6 mb-3`} >
                                <label className="form-label" htmlFor='txtPinCode'>Forwarding Party Code
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    ref={ref => (inputrefs.current['txtFPCode'] = ref)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtFPName'].focus() };
                                    }}
                                    type='text'
                                    id='txtFPCode'
                                    className={`form-control`}
                                    value={recipe.FPCode}
                                    placeholder="Forwarding Party Code"
                                    autoComplete="off"
                                    onChange={(e) => HandleChange('FPCode', e.target.value)}
                                    maxLength="10"
                                />
                            </div>

                            <div className={`col-md-4 col-sm-6 mb-3`}>
                                <label className="form-label" htmlFor='txtArea'>Forwarding Party Name
                                    <span style={{ color: "red" }}> *</span>
                                </label>
                                <input
                                    ref={ref => (inputrefs.current['txtFPName'] = ref)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtIsActive'].focus() };
                                    }}
                                    type='text'
                                    id='txtFPName'
                                    className={`form-control`}
                                    value={recipe.FPName}
                                    placeholder="Forwarding Party Name"
                                    autoComplete="off"
                                    onChange={(e) => HandleChange('FPName', e.target.value)}
                                    maxLength="50"
                                />
                            </div>

                            <div className={`col-md-4 col-sm-6 mb-3`}>
                                <Switch
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { handleSubmit() };
                                    }}
                                    label='Active'
                                    checked={recipe.Active}
                                    id='txtIsActive'
                                    nextinputid='txtFranchiseGroup'
                                    onChange={(e) => HandleChange('Active', e.target.checked)}
                                />
                               
                            </div> 
                        </div>

                        <div className='row'>
                            <FormButton
                                handleSubmit={handleSubmit}
                                handleExport={() => handleDownloadExcel(API_SERVER + 'api/Master/ShowForwardingPartyMaster', ExportRequest, 'Forwarding Party Master')}
                                handleClear={handleClear}
                            />
                        </div>
                    </div>
                    {/* Left Section End*/}

                    {/* Right Section Start*/}
                    <div className='d-flex col-md-6 col-lg-6 h-25'>
                        <div className='row'>

                            <div className={`col-md-4 col-sm-6`} >
                                <label className="form-label" htmlFor='txtPinCode'>Service Code
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    ref={ref => (inputrefs.current['txtServiceTypeCode'] = ref)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtServiceTypeName'].focus() };
                                    }}
                                    id='txtServiceTypeCode'
                                    type='text'
                                    className={`form-control`}
                                    value={recipe.ServiceTypeCode}
                                    placeholder="Service Code"
                                    autoComplete="off"
                                    onChange={(e) => HandleChange('ServiceTypeCode', e.target.value)}
                                    maxLength="10"
                                />
                                {errors.ServiceTypeCode && <div className="error-msg">{errors.ServiceTypeCode}</div>}
                            </div>

                            <div className={`col-md-4 col-sm-6`}>
                                <label className="form-label" htmlFor='txtArea'>Service Name
                                    <span style={{ color: "red" }}> *</span>
                                </label>
                                <input
                                    ref={ref => (inputrefs.current['txtServiceTypeName'] = ref)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { HandleServiceAdd(isServiceEdit ? 'Edit' : 'Add', serviceEditIndex) };
                                    }}
                                    type='text'
                                    className={`form-control`}
                                    value={recipe.ServiceTypeName}
                                    placeholder="Service Name"
                                    autoComplete="off"
                                    onChange={(e) => HandleChange('ServiceTypeName', e.target.value)}
                                    maxLength="50"
                                />
                                {errors.ServiceTypeName && <div className="error-msg">{errors.ServiceTypeName}</div>}
                            </div>

                            <div className={`col-md-4 col-sm-6`}>
                                <div className='d-flex align-items-right'>
                                    <div className='mt-4 d-md-flex' >
                                        <button type="button" className="btn btn-rounded btn-primary text-white" style={{ minWidth: '2.87rem' }} onClick={() => HandleServiceAdd(isServiceEdit ? 'Edit' : 'Add', serviceEditIndex)}>{isServiceEdit?'Update':'Add'}</button>
                                        <button type="button" className="btn btn-rounded btn-danger text-white" style={{ minWidth: '2.87rem' }} onClick={HandleServiceReset }>Reset</button>
                                    </div>
                                </div>
                            </div>

                            {ForwarderService && ForwarderService.length > 0 &&

                                <div className='mt-1'>
                                    <div className='row'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-striped">
                                                <thead>
                                                    <tr className='light-bg'>
                                                        <th style={{ width: '20px' }}>Action</th>
                                                        <th style={{ width: '20px' }}>Service Code</th>
                                                        <th style={{ width: '20px' }}>Service Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ForwarderService.map((item, index) => {
                                                        return <tr key={index}>
                                                            <td><i className="fa-regular fa-pen-to-square text-success me-2" onClick={() => HandlServiceEdit(item, index)} />  <i className="fa-regular fa-trash-can text-danger me-2" onClick={() => HandleServiceDelete(index)} /></td>
                                                            <td>{item.ServiceTypeCode}</td>
                                                            <td>{item.ServiceTypeName}</td>
                                                        </tr>
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                    {/* Left Section End*/}

                </div>
            </div>

            {/* Table Component start*/}
            <Table
                Token={TokenData}
                PageName='ForwardingPartyMaster'
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleFilter={() => {
                    getFormData();
                    setFilterData({
                        ...filterData,
                    });
                }}
                tableData={result}
                //  loading={loading}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {/* Table Component End*/}


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}

        </>
    )
}

export default ForwardingPartyMaster