import React, { useEffect, useState, useRef } from 'react'
import { InputBox, FormButtonWithPrint } from "../commoncomponents/InputBox";
import { FileUploader } from "react-drag-drop-files";
import { useLocation, Link } from 'react-router-dom';

import { useAuth } from '../../context/auth';

//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER, } from '../../Utils';

//services

import { post } from '../../apiservices/service';
import { Table } from '../commoncomponents/Table';

function AwbQuery() {
    const inputrefs = useRef([]);

    const location = useLocation();
    const { user } = useAuth();
    const params = new URLSearchParams(location.search);
    
    const query_OrderNo = params.get('OrderNo');

    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }

    //initial state
    const [recipe, setRecipe] = useState({ Type: 'OrderNo', AwbNo :''})
    const [model, setModel] = useState({ RemarksID:'', OrderNo: '', Remarks: '', File: '', FileExt: '', FileName: '' })
    const [tableData, setTableData] = useState({})
    const [loading, setLoading] = useState(false)
    const [remarks, setRemarks] = useState('')
    const [kycDetails, setkycDetails] = useState({});

  
    useEffect(() => {
        document.title = 'Nihao: Awb Query '
        setRecipe(recipe => ({ ...recipe, AwbNo: query_OrderNo }))
        if (query_OrderNo) GetAWBQuery(query_OrderNo)
        if (query_OrderNo) GetKYCData(query_OrderNo)
      inputrefs.current['txtAwbNo'].focus();
    }, [query_OrderNo])

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    const GetAWBQuery = (query_data) => {
        setLoading(true)
        post(`${API_SERVER}api/Query/AwbQueryReport`, { ...TokenData, ...recipe, AwbNo: query_data ? query_data : recipe.AwbNo }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    toast.error(res.message)
                    setModel({ ...model, OrderNo: '' })
                    setTableData([])
                } else {
                    setLoading(false)
                    setModel({ ...model, OrderNo: res.shipmentDetail.orderNo })
                    setTableData(res)
                   
                }
            }
        });
        setLoading(false)
    }

    const GetKYCData = (query_data) => {
        setLoading(true)
        setkycDetails({})
        post(`${API_SERVER}api/Query/GetKYCByPhoneNo`, {
            ...TokenData, ...recipe, MobileNo: '', Type: 's', PageIndex: "1", AwbNo: query_data ? query_data : recipe.AwbNo}, (res) => {
            if (res && res.Status) {
                if (res.Status === "ERROR") {
                    setLoading(false)
                    toast.error(res.message)
                    setkycDetails({ ...res })
                } else {
                    setLoading(false)
                    setkycDetails({...res})
                }
            }
        });
        setLoading(false)
    }

    //Submit Handler
    const handleSubmit = () => {
        GetAWBQuery()
        GetKYCData()
    }

    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({ ...recipe, Type:'OrderNo', AwbNo: '' }))
        setTableData({})
        setkycDetails({})
    }

    //Handle Remarks
    const HandleRemarks = () => {
        setLoading(true)
        post(`${API_SERVER}api/Query/SaveRemarks`, { ...TokenData, ...model }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    toast.error(res.message)
                } else if (res.status === "SUCCESS") {
                    toast.success(res.message)
                    setLoading(false)
                    ModalClear()
                    let a = document.querySelectorAll('.sc-eqUAAy');
                    if (a.length > 0) {
                        a[0].children[0].children[0].innerHTML = ''
                    }
                    GetAWBQuery()
                }
                else if (res.status === "UNAUTHORIZED") {
                    toast.error(res.message)
                    setLoading(false)
                }
            }
        });
        setLoading(false)
    }

    const ShowRemarks = (item) => {
        setLoading(true)
        setRemarks('')
        post(`${API_SERVER}api/Query/GetAwbNoQueryRemarksData`, { ...TokenData, RemarksID: item.remarksID }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setLoading(false)
                    setRemarks('')
                    toast.error(res.message)
                } else {
                    setRemarks(res.remarks)
                    setLoading(false)
                    
                }
            }
        });
        setLoading(false)
    }

    const ModalClear = () => {
        setModel(model=>({ ...model, RemarksID: '', Remarks: '', File: '', FileExt: '', FileName: '' }))
    }
    console.log('recipe', recipe)
    return (

        <>

            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0">Awb Query</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className="d-flex gap-2">
                            <div>
                                <input
                                    type="radio"
                                    id="OrderNo"
                                    value="OrderNo"
                                    checked={recipe.Type === 'OrderNo'}
                                    onChange={(e) => {
                                        setTableData([])
                                        setRecipe({ ...recipe, OrderNo: '' })
                                        HandleChange('Type', e.target.value)
                                    }}
                                />
                                <label htmlFor="OrderNo" style={{ marginLeft: "4px" }}>Awb No</label>
                            </div>

                            <div style={{ display: "none" }}>
                                <input
                                    type="radio"
                                    id="AwbNo"
                                    value="AwbNo"
                                    checked={recipe.Type === 'AwbNo'}
                                    onChange={(e) => {
                                        setTableData([])
                                        setRecipe({ ...recipe, AwbNo: '' })
                                        HandleChange('Type', e.target.value)
                                    }}
                                />
                                <label htmlFor="AwbNo" style={{ marginLeft: "4px" }}>Awb No</label>
                            </div>

                            <div>
                                <input
                                    type="radio"
                                    id="TrackNo"
                                    value="TrackNo"
                                    checked={recipe.Type === 'TrackNo'}
                                    onChange={(e) => {
                                        setTableData([])
                                        setRecipe({ ...recipe, AwbNo:'' })
                                        HandleChange('Type', e.target.value)
                                    }}
                                />
                                <label htmlFor="TrackNo" style={{ marginLeft: "4px" }}>Track No</label>
                            </div>

                        </div>

                        {recipe.Type === 'OrderNo' && (
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSubmit()
                                    };
                                }}
                                autoComplete='off'
                                label={'AwbNo'}
                                id='txtAwbNo'
                                maxLength='50'
                                placeholder={'AwbNo'}
                                value={recipe.AwbNo}
                                onChange={(e) => {
                                    HandleChange('AwbNo', e.target.value)
                                }}
                                required={true}
                            />
                        )}
                        {recipe.Type === 'TrackNo' && (
                            <InputBox
                                inputrefs={inputrefs}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSubmit()
                                    };
                                }}
                                autoComplete='off'
                                label={'TrackNo'}
                                id='txtAwbNo'
                                maxLength='50'
                                placeholder={'TrackNo'}
                                value={recipe.AwbNo}
                                onChange={(e) => {
                                    HandleChange('AwbNo', e.target.value)
                                }}
                                required={true}
                            />
                        )}
                        <FormButtonWithPrint
                            save={true}
                            print={false}
                            reset={true}
                            buttonName='Get'
                            handleSubmit={handleSubmit}
                            handleClear={handleClear}
                        />
                    </div>
                </div>
            </div>

            {tableData && tableData.shipmentDetail ?
                <>
                    <div className='row'>
                    <div className='col-md-7'>
                    <div className='main-card card border-0'>
                        <div className='card-body p-3'>
                    {/*        <div className="table_heading d-flex justify-content-between">*/}
                    {/*                    <div >Shipment Detail</div> */}
                    {/*                    */}{/*<td>{tableData.shipmentDetail.invoiceImage && <i className="fa fa-eye" onClick={() => tableData.shipmentDetail.imageName === 'download (34).pdf' ? window.open(`/PdfView/#data:application/pdf;base64,${tableData.shipmentDetail.invoiceImage}`, '_blank') : window.open(`/ImageView/#data:image/png;base64,${tableData.shipmentDetail.invoiceImage}`, '_blank')}*/}
                    {/*                    */}{/*    style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }} />}</td>*/}
                    {/*                    {tableData.shipmentDetail.invoiceImage ? (*/}
                    {/*                        <button*/}
                    {/*                            className='btn-primary btn w-40' style={{ marginBottom: '0px' }}*/}
                    {/*                            onClick={() =>window.open(`/PdfView/#data:application/pdf;base64,${tableData.shipmentDetail.invoiceImage}`, '_blank')}*/}
                    {/*                        >*/}
                    {/*                            <i className="fa fa-eye" />View Invoice*/}
                    {/*                        </button>*/}
                    {/*                    ) : null}*/}
                    {/*                    <div>*/}
                                    <div className="table_heading d-flex justify-content-between">
                                        <div style={{ flexGrow: 1 }}>Shipment Detail</div>
                                        <div className="d-flex justify-content-end">
                                            {tableData.shipmentDetail.invoiceImage ? (
                                                <button
                                                    className='btn-primary btn w-40'
                                                    style={{ marginLeft: '10px', marginBottom: '0px' }}
                                                    onClick={() => window.open(`/PdfView/#data:application/pdf;base64,${tableData.shipmentDetail.invoiceImage}`, '_blank')}
                                                >
                                                    <i className="fa fa-eye" /> View Invoice
                                                </button>
                                            ) : null}
                                            <button
                                                className='btn-primary btn w-40'
                                                style={{ marginBottom: '0px' }}
                                                data-bs-toggle="modal" data-bs-target="#addremarkModal"
                                                onClick={() => {
                                                    ModalClear();
                                                    let a = document.querySelectorAll('.sc-eqUAAy');
                                                    if (a.length > 0) {
                                                        a[0].children[0].children[0].innerHTML = '';
                                                    }
                                                }}
                                            >
                                                <i className="fa-solid fa-plus"></i> Add Remarks
                                            </button>
                                        </div>
                                    </div>

                    <div className="form-box service-content-area ms-3 me-3 " >
                        <div className="row  details-tableAwb" >
                            <table className='mb-0 table table-bordered table-responsive table-hover table-striped' style={{ whiteSpace: 'nowrap' }}>
                                        <tbody style={{ fontSize: '13px', padding: '1px' }}>
                                            <tr>
                                                <td className='fw-bold HistoryColor' > {tableData.shipmentDetail.hOrderNo}  </td>
                                                <td>{tableData.shipmentDetail.orderNo} </td>
                                                        <td className='fw-bold HistoryColor' > {tableData.shipmentDetail.hReferenceNo}  </td>
                                                        <td>{tableData.shipmentDetail.referenceNo} </td>
                                            </tr>

                                            <tr>
                                                <td className='fw-bold HistoryColor ' > {tableData.shipmentDetail.hBookingDate}  </td>
                                                <td>  {tableData.shipmentDetail.bookingDate} </td>
                                                
                                                <td className='fw-bold HistoryColor' > {tableData.shipmentDetail.hWeight}  </td>
                                                <td>  {tableData.shipmentDetail.weight} </td>
                                            </tr>

                                            <tr>
                                                <td className='fw-bold HistoryColor'> {tableData.shipmentDetail.hVolWeight}  </td>
                                                <td>  {tableData.shipmentDetail.volWeight} </td>
                                                <td className='fw-bold HistoryColor' > {tableData.shipmentDetail.hValue}  </td>
                                                <td>  {tableData.shipmentDetail.totalValue} </td>
                                            </tr>
                                            <tr>

                                                <td className='fw-bold HistoryColor' > {tableData.shipmentDetail.hConsignor}  </td>
                                                        <td>  {tableData.shipmentDetail.consignor}<br />
                                                    ConsignorCP : {tableData.shipmentDetail.consignorCP}  <br />
                                                    Address1 :{tableData.shipmentDetail.consignorAddress1}  <br />
                                                    Address2 :{tableData.shipmentDetail.consignorAddress2} <br />
                                                    Address3 :{tableData.shipmentDetail.consignorAddress3} <br />
                                                    City :{tableData.shipmentDetail.consignorCity} <br />
                                                    Zip :{tableData.shipmentDetail.consignorZip} <br />
                                                    Country :{tableData.shipmentDetail.consignorCountry} <br />
                                                    State :{tableData.shipmentDetail.consignorState} <br />
                                                    Phone No :{tableData.shipmentDetail.consignorPhoneNo}</td>

                                                <td className='fw-bold HistoryColor' > {tableData.shipmentDetail.hConsignee}  </td>
                                                <td>  {tableData.shipmentDetail.consignee}<br />
                                                    ConsigneeCP :{tableData.shipmentDetail.consigneeCP} <br />
                                                    Address1 :{tableData.shipmentDetail.consigneeAddress1}  <br />
                                                    Address2 :{tableData.shipmentDetail.consigneeAddress2} <br />
                                                    Address3 :{tableData.shipmentDetail.consigneeAddress3} <br />
                                                    City :{tableData.shipmentDetail.consigneeCity} <br />
                                                    Zip :{tableData.shipmentDetail.consigneeZip} <br />
                                                    Country :{tableData.shipmentDetail.consigneeCountry} <br />
                                                    State :{tableData.shipmentDetail.consigneeState} <br />
                                                    Phone No :{tableData.shipmentDetail.consigneePhoneNo}</td>

                                            </tr>

                                            <tr>
                                                <td className='fw-bold HistoryColor'> {tableData.shipmentDetail.hConsigneeCity}  </td>
                                                <td>  {tableData.shipmentDetail.consigneeCity} </td>
                                                        {/* <td className='fw-bold HistoryColor'> {tableData.shipmentDetail.hStatusDate}  </td>*/}
                                                        <td className='fw-bold HistoryColor' > {tableData.shipmentDetail.hPacketStatus}  </td>
                                                        <td> {tableData.shipmentDetail.packetStatus}</td>
                                            </tr>
                                            <tr>
                                                <td className='fw-bold HistoryColor' > {tableData.shipmentDetail.hPacketType}  </td>
                                                <td>  {tableData.shipmentDetail.packetType} </td>
                                                <td className='fw-bold HistoryColor' >{tableData.shipmentDetail.hPaymentType}</td>
                                                <td>{tableData.shipmentDetail.paymentType}</td>
                                            </tr>

                                            <tr>
                                               <td className='fw-bold HistoryColor' > {tableData.shipmentDetail.hForwordingParty}   </td>
                                               
                                                <td>  {tableData.shipmentDetail.forwordingParty}</td>
                                                <td className='fw-bold HistoryColor' > {tableData.shipmentDetail.hForwordingNo}  </td>
                                                <td> {tableData.shipmentDetail.forwordingNo} </td>
                                                    </tr>
                                                 
                                        </tbody>
                            </table>

                        </div>
                            </div>
                        </div>
                        </div>
                        </div>


                        <div className='col-md-5 d-flex flex-column'>
                        
            {tableData && tableData.remarkDetails && tableData.remarkDetails.length > 0 ?
                <section className="recoard-table">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <div className="heading-box d-flex">
                                    <div className="heading ms-2 mb-3">
                                        <h6 className="m-0 font-weight-bold ">
                                            Remark Details
                                        </h6>
                                    </div>
                                </div>
                                <div className='main-card card border-0' >
                                    <div className='card-header mb-1'>
                                        <div className='row justify-content-between align-items-center'>
                                                            <div className='card-body'>
                                                                <div className="table-responsive" style={{ height:"30vh" }}>

                                                    {tableData.remarkDetails !== null ? (
                                                        <table className="table table-bordered table-striped mb-0" id="dataTable" width="100%" cellSpacing="0">
                                                            <thead>
                                                                <tr className='light-bg'>
                                                                    <th>DOC</th>
                                                                    <th>Remarks</th>
                                                                    <th>Remark By</th>
                                                                    <th>Remark Date</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {tableData.remarkDetails !== null ?
                                                                    (
                                                                        (tableData.remarkDetails).map((item, index) => {
                                                                            
                                                                           return <tr className="TableTrhover" key={index}>
                                                                               <td>{item.doc && <i className="fa fa-eye" onClick={() => item.fileName === 'pdf' ? window.open(`/PdfView/#data:application/pdf;base64,${item.doc}`, '_blank') : window.open(`/ImageView/#data:image/png;base64,${item.doc}`, '_blank')}
                                                                                    style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }} />}</td>
                                                                                <td onClick={() => ShowRemarks(item)} data-bs-toggle="modal" data-bs-target="#remarks"><span className='show-remarks'>{item.remarks}</span></td>

                                                                                <td>{item.remarkBy}</td>

                                                                                <td>{item.remarkDate}</td>
                                                                            </tr>
                                                                        })
                                                                    ) : (<></>)}
                                                            </tbody>
                                                        </table>

                                                    ) : (<></>)}
                                                </div>

                                            </div>
                                        </div></div></div>
                            </div>
                        </div>
                    </div>

                </section>
                                : ''}

                            {kycDetails && kycDetails.Data && <section className="recoard-table">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="content-box">
                                            <div className="heading-box d-flex">
                                                <div className="heading mb-3">
                                                    <h6 className="m-0 font-weight-bold ">
                                                        KYC Details
                                                    </h6>
                                                </div>
                                            </div>

                                            {/* Table Component start*/}
                                            <Table
                                                Token={TokenData}
                                                PageName='AWBPhoneKyc'
                                                handleEdit={() => alert('Edit')}
                                                handleDelete={() => alert('delete')}
                                                tableData={kycDetails}
                                                handleFilter={() => {
                                                }}
                                                //  loading={loading}
                                                filterData={() => {
                                                }}
                                                setFilterData={() => {
                                                }}
                                                currentPageNo={() => {
                                                }}
                                                handleChange1={() => {
                                                }}
                                                handleSorting={() => {
                                                }}
                                                sortConfig={() => {
                                                }}
                                            />
                                            {/* Table Component End*/}

                                        </div>
                                    </div>
                                </div>

                            </section>}
                        </div>

                       
                         
                        </div>
                   
                </>
                : ''}

            {tableData && tableData.trackingDetails && tableData.trackingDetails.length > 0 ?
                <section className="recoard-table">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <div className="heading-box d-flex">
                                    <div className="heading ms-2 mb-3">
                                        <h6 className="m-0 font-weight-bold ">
                                            Tracking History
                                        </h6>
                                    </div>
                                </div>
                                <div className='main-card card border-0' >
                                    <div className='card-header mb-1'>
                                        <div className='row justify-content-between align-items-center'>
                                            <div className='card-body'>
                                                <div className="table-responsive">

                                                    {tableData.trackingDetails !== null ? (
                                                        <table className="table table-bordered table-striped mb-0" id="dataTable" width="100%" cellSpacing="0">
                                                            <thead>
                                                                <tr className='light-bg'>
                                                                   
                                                                    <th>Status Date</th>
                                                                    <th>Status Time</th>
                                                                    <th>Pod Location</th>
                                                                    <th>Status Detail</th>
                                                                    <th>Created Date</th>
                                                                    <th>Created By</th>
                                                                   
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {tableData.trackingDetails !== null ?
                                                                    (
                                                                        (tableData.trackingDetails).map((Docket, index) => (
                                                                            <tr className="TableTrhover" key={index}>

                                                                           
                                                                                <td>{Docket.statusDate}</td>
                                                                                <td>{Docket.statusTime}</td>
                                                                                <td>{Docket.podLocation}</td>
                                                                                <td>{Docket.statusDetail}</td>
                                                                                <td>{Docket.createdDate}</td>
                                                                                <td>{Docket.createdBy}</td>
                                                                         

                                                                            </tr>
                                                                        ))
                                                                    ) : (<></>)}
                                                            </tbody>
                                                        </table>

                                                    ) : (<></>)}
                                                </div>

                                            </div>
                                        </div></div></div>
                            </div>
                        </div>
                    </div>

                </section>
                : ''}
           


            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}

            <div className="modal fade" id="addremarkModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="aboutUsLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content" style={{ background: "mintcream" }}>
                        <div className="modal-header" style={{ padding: '7px 15px' }}>
                            <h5 className="modal-title" id="addremarkModalLabel">Remarks</h5>
                        </div>
                        <div className="modal-body table-responsive">
                            <div className="container-fluid">
                                <div className='row'>
                                    <div className='col-12'>
                                            <div className="form-group text-start mb-3" >
                                                <label className="form-label mb-1">Order No <span className='required'>*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    ref={ref => (inputrefs.current['OrderNo'] = ref)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') { inputrefs.current['txtRemarks'].focus() };
                                                    }}
                                                    id='OrderNo'
                                                    placeholder="Order No"
                                                    value={model.OrderNo}
                                                    disabled={true}
                                                    onChange={(e) => setModel({ ...model, OrderNo: e.target.value })}
                                                />
                                            </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="form-group text-start mb-3" >
                                            <label className="form-label mb-1">Remarks <span className='required'>*</span></label>
                                            <textarea 
                                                className="form-control"
                                                row='5'
                                                ref={ref => (inputrefs.current['txtRemarks'] = ref)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        inputrefs.current['btnSubmit'].focus()
                                                    };
                                                }}
                                                maxLength='250'
                                                placeholder="Remarks"
                                                value={model.Remarks}
                                                onChange={(e) => setModel({ ...model, Remarks: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-5'>
                                <label className="form-label">Attach File</label>
                                        <FileUploader
                                        handleChange={(file) => {
                                            let extArr = (file.name).split(".");
                                            if (file) {
                                                const reader = new FileReader();
                                                reader.readAsDataURL(file);
                                                reader.onload = () => {
                                                    let logo = (reader.result).split(',')[1]
                                                    setModel({
                                                        ...model,
                                                        File: logo,
                                                        FileExt: extArr[1],
                                                        FileName: extArr[0]
                                                    });
                                                    let a = document.getElementsByClassName('sc-eqUAAy');
                                                    a[0].children[0].children[0].innerHTML = file.name;
                                                };
                                            }
                                        }}
                                            name="file" types={["JPG", "PNG","PDF"]}
                                        />
                                    </div>
                            </div>
                            </div>
                        </div>
                        <div className="modal-footer" style={{ padding: '3px' }}>
                            <button type="button" ref={ref => (inputrefs.current['btnSubmit'] = ref)} className="btn btn-primary" data-bs-dismiss="modal" style={{ margin: '5px', minWidth: '30px' }}
                                onClick={() => HandleRemarks()}> <i className="fa">&#xf0c7;</i> Save</button>
                            <button type="button" className="btn btn-secondary" style={{ margin: '5px', minWidth: '30px' }} data-bs-dismiss="modal"
                                onClick={() => {
                                    ModalClear()
                                    let a = document.querySelectorAll('.sc-eqUAAy');
                                    if (a.length > 0) {
                                        a[0].children[0].children[0].innerHTML=''
                                    }
                                } }><i className='fa fa-close' /> Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="remarks"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="aboutUsLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content" style={{ background: "mintcream" }}>
                        <div className="modal-header" style={{ padding: '7px 15px' }}>
                            <h5 className="modal-title" id="remarksLabel">Remarks</h5>
                        </div>
                        <div className="modal-body table-responsive">
                            <div className="container-fluid">
                                <div className='row'>
                                    <div className='col-12'>
                                        <p>{remarks}</p>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        <div className="modal-footer" style={{ padding: '3px' }}>
                            <button type="button" className="btn btn-secondary" style={{ margin: '5px', minWidth: '30px' }} data-bs-dismiss="modal"
                                onClick={() => setRemarks('')}><i className='fa fa-close' /> Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AwbQuery
