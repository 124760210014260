import React, { useEffect, useState, useRef } from 'react'
import { InputBox, SelectInputBox, AutoCompleteBox, FormButtonWithPrint } from "../commoncomponents/InputBox";

import { useAuth } from '../../context/auth';
//loder
import BlurLoader from "../Loder/BlurLoader";

//react toast 
import { toast } from 'react-toastify';

//common Data
import { API_SERVER,} from '../../Utils';

//services

import { post } from '../../apiservices/service';
import { getCommonData, getMasterData } from '../../apiservices/CommonServices';
import PickUpOrderData from '../commoncomponents/PickUpOrderData';

function MakeConection() {
    const inputrefs = useRef([]);
    const { user } = useAuth();

    //token Data
    const TokenData = {
        AuthToken: "der324k343%@923041!",
        LoginToken: user.loginSessionID,
        UserCode: user.userCode
    }

    //initial state
    const [recipe, setRecipe] = useState({ OrderNo: '', AwbNo: '', ForwardingParty: '', ForwardingService: '', ForwardingWeight: '' })
    const [loading, setLoading] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [ForwarderParty, setForwarderParty] = useState([])
    const [ForwarderService, setForwarderService] = useState([])
    const [forwarderpre, setforwarderPre] = useState('')
    const [dataLoading, setDataLoading] = useState(false)
    const [fowarderdataLoading, setfowarderdataLoading] = useState(false)

    useEffect(() => {
        getForwardingParty()
        inputrefs.current['txtOrderNo'].focus();
        document.title = 'Nihao: Make Connection'
    }, [])

    const getForwardingParty = () => {
        setForwarderParty([])
        let temp = {
            Type: "GetForwardingParty",
        }
        setfowarderdataLoading(true)
        getMasterData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setForwarderParty([])
                    setfowarderdataLoading(false)
                } else {
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setForwarderParty(data)
                    setfowarderdataLoading(false)
                }
            }
        });
    }

    const getForwardingService = () => {
        setForwarderService([])
        let temp = {
            Type: "GetForwardingPartyWiseService",
            Prefix: "",
            ContextKey: forwarderpre ,
            ContextKey2: ""
        }
        setDataLoading(true)
        getCommonData({ ...TokenData, ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setForwarderService([])
                    setDataLoading(false)
                } else {
                    let data = res && res.dataList && res.dataList.length > 0 ? res.dataList : []
                    setForwarderService(data)
                    setDataLoading(false)
                }
            }
        });
    }

    const getFormData = (pickno) => {

        post(`${API_SERVER}api/Operation/GetUpdateForwardingPartyDetail`, { ...TokenData, ...recipe, PickupNo: pickno }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setRecipe(recipe => ({ ...recipe, bookingDate: '', AwbNo: '', TrackingNo: '', ForwardingParty: '', ForwardingService: '', ForwardingWeight:'' }))
                    getForwardingParty()
                    setforwarderPre('')
                   // toast.error(res.message)
                } else {
                    let data = res.data
                    getForwardingParty()
                    setforwarderPre(data.forwardingPartyCode)
                    setRecipe({ ...data, PickupNo: pickno, AwbNo: data.awbNo, TrackingNo: data.trackingNo, ForwardingParty: data.forwardingPartyCode, ForwardingService: data.forwardingService, ForwardingWeight: data.forwardingWeight })
                }
            }
        });

    }

    //Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }
  
    //Submit Handler
    const handleSubmit = () => {
            setLoading(true)
        post(`${API_SERVER}api/Operation/MakeConnection`, { ...TokenData, ...recipe }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {                      
                        toast.error(res.message)
                        if (res.focus === 'txtForwardingService') {
                            inputrefs.current[res.focus].children[0].children[0].focus()
                          
                        } else {
                            inputrefs.current[res.focus].focus()
                            //toast.error(res.message)
                            //console.log("erroring", res.message)
                            setLoading(false)
                        }
                       
                    } else if (res.status === "SUCCESS") {
                        setLoading(false)
                        toast.success(res.message)
                        handleClear()
                    }
                    else if (res.status === "UNAUTHORIZED") {
                        toast.error(res.message)
                        setLoading(false)
                    }
                }
        });
        setLoading(false)
    }


    //Reset Handler
    const handleClear = () => {
        setRecipe(recipe => ({ ...recipe, bookingDate: "", OrderNo: '', AwbNo: '', ForwardingParty: '', ForwardingService: '', ForwardingWeight: '' }))
        setIsUpdate(!isUpdate)
    }


    let ForwarderServiceArr = []
    for (let i = 0; i < ForwarderService.length; i++) {
        let mydata = ForwarderService[i]?.values
        ForwarderServiceArr.push(mydata)
    };

    return (
        <>
            <div className='main-card card border-0'>
                <div className='card-header mb-1'>
                    <h6 className="m-0"> Make Connection</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-6 col-lg-6 h-25'>
                            <div className='row'>
                                <div className='col-md-6 col-lg-6 h-25'>
                                    <InputBox
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtForwardingParty'].focus() };
                                        }}
                                        label='Awb No'
                                        id='txtOrderNo'
                                        maxLength='25'
                                        placeholder="Awb No"
                                        ignore_common={true}
                                        value={recipe.OrderNo}
                                        onBlur={() => getFormData(recipe.OrderNo)}
                                        onChange={(e) => HandleChange('OrderNo', e.target.value)}
                                        required
                                      />
                                </div>

                                <div className='col-md-6 col-lg-6 h-25' style={{ display: "none" }}>
                                        <InputBox
                                            inputrefs={inputrefs}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { inputrefs.current['txtForwardingParty'].focus() };
                                            }}
                                            label='Awb No'
                                            id='txtAwbNo'
                                            maxLength='25'
                                            ignore_common={true}
                                            placeholder="Awb No"
                                            value={recipe.AwbNo}
                                            onChange={(e) => HandleChange('AwbNo', e.target.value)}
                                            required={false}
                                            />
                                </div>
                                <div className='col-md-6 col-lg-6 h-25'>
                                    <SelectInputBox
                                        label='Forwarder'
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') { inputrefs.current['txtForwardingService'].children[0].children[0].focus() };
                                        }}
                                        id='txtForwardingParty'
                                        nextinputid='txtForwardingParty'
                                        required={true}
                                        maxLength='50'
                                        ignore_common={true}

                                        value={recipe.ForwardingParty}
                                        onChange={(e) => {
                                            HandleChange('ForwardingParty', e.target.value)
                                            setRecipe(recipe => ({ ...recipe, ForwardingService: '' }))
                                            setForwarderService([])
                                            setforwarderPre(e.target.value)
                                        }}
                                    >
                                        <option value=''>-Please Select-</option>
                                        {fowarderdataLoading ? <option> Loading... </option>
                                            : ForwarderParty?.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.code}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </SelectInputBox>
                                </div>

                            </div>

                            <div className='row'>
                               

                                <div className='col-md-6 col-lg-6 h-25'>
                                <AutoCompleteBox
                                    loading={dataLoading}
                                    inputrefs={inputrefs}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') { inputrefs.current['txtForwardingWeight'].focus() };
                                    }}
                                    label='Forwarder Service'
                                    placeholder="Forwarder Service"
                                    maxLength='50'
                                    options={ForwarderServiceArr}
                                    id='txtForwardingService'
                                    required={true}
                                    ignore_common={true}
                                    onFocus={() => forwarderpre && getForwardingService()}
                                    inputValue={recipe.ForwardingService ? recipe.ForwardingService : ''}
                                    onInputChange={(event, newInputValue) => {
                                        HandleChange('ForwardingService', newInputValue)
                                    }}
                                    />
                                </div>
                                <div className='col-md-6 col-lg-6 h-25'>
                                    <InputBox
                                        inputrefs={inputrefs}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleSubmit()
                                            };
                                        }}
                                        ignore_common={true}
                                        label='Forwarder Weight'
                                        id='txtForwardingWeight'
                                        maxLength='10'
                                        placeholder="Forwarder Weight"
                                        value={recipe.ForwardingWeight}
                                        onChange={(e) => HandleChange('ForwardingWeight', e.target.value)}
                                        required
                                    />
                                </div>
                            </div>

                            <div className='row'>
                              
                                <FormButtonWithPrint
                                    save={true}
                                    print={false}
                                    reset={true}
                                    handleSubmit={handleSubmit}
                                    handleClear={handleClear}
                                />
                            </div>

                        </div>
                        

                        <div className='col-md-6 col-lg-6 h-25'>
                            <PickUpOrderData recipe={recipe} />
                        </div>
                    </div>

                </div>
            </div>




            {/* Loder start*/}
            {loading && < BlurLoader />}
            {/* Loder End*/}
        </>
    )
}

export default MakeConection